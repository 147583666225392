import {useEffect} from 'react'
import {Navigate, Routes} from 'react-router-dom'
import {useAuth} from './core/Auth'
import FirebaseAuthService from '../../firebase/FirebaseAuthService'

export function Logout() {
  // const {logout} = useAuth()
  useEffect(() => {
    console.log('logout')
    FirebaseAuthService.logoutUser()
    // logout()
    // document.location.reload()
    window.location.href = '/auth/login'
  }, [])
  console.log('logout')

  return (
    <></>
    // <Routes>
    //   <Navigate to='/auth/login' />
    // </Routes>
  )
}
