/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useNavigate} from 'react-router-dom'

type Props = {
  color?: string
  avatar?: string
  online?: boolean
  name: string
  job: string
  avgEarnings: string
  totalEarnings: string
}

const CardAddMember: FC<Props> = ({
  color = '',
  avatar = '',
  online = false,
  name,
  job,
  avgEarnings,
  totalEarnings,
}) => {
  const navigate = useNavigate()

  const handleAddMember = () => {
    navigate(`/create-member`)
  }

  return (
    <div className='col-6 col-md-6 col-xxl-4'>
      <div className='card h-100'>
        <div className='card-body d-flex flex-center flex-column'>
          <a
            href='#'
            onClick={() => handleAddMember()}
            className='d-flex align-items-center justify-content-center h-100 w-100'
          >
            <div className='d-block text-center'>
              <KTIcon iconName='plus' className='fw-bolder fs-1' />
              <div className='fw-semibold fs-5'>Add Member</div>
            </div>
          </a>
        </div>
      </div>
    </div>
  )
}

export {CardAddMember}
