/* eslint-disable jsx-a11y/anchor-is-valid */

import { useUser } from '../../modules/newauth/UserContext'
import { useState, useEffect, useRef } from 'react'
import firebase from '../../firebase/FirebaseConfig'
import { useMember } from '../../modules/newauth/MemberContext'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import 'flatpickr/dist/themes/material_green.css'
import Flatpickr from 'react-flatpickr'
import { PulseLoader } from 'react-spinners'
import { useLocation } from 'react-router-dom'
import { KTIcon } from '../../../_metronic/helpers'
import { ScanTag } from '../device/widget/ScanTag'
import { useDevice } from '../../modules/newauth/DeviceContext'
import { formatDef } from '../../modules/utils/FormatDef'

import { DataFormat2Comp } from './tagComponent/DataFormat2Comp'
import { DataFormat1Comp } from './tagComponent/DataFormat1Comp'

const apiUrl = 'https://asia-southeast1-dygistechplatform.cloudfunctions.net/apiConnectMScanPro/v1/tags/log'

const config = {
  headers: {
    Authorization: 'Bearer E1ECE296153B9B65752E591911D44',
    'X-Api-Secret': 'BB5E88A38DB539372E795A249A5DD',
  },
}

export const TagWrapper = () => {
  const { user } = useUser()
  const { member, getMember } = useMember()
  const db = firebase.firestore()
  const navigate = useNavigate()
  const { deviceId, tagId } = useParams()
  const location = useLocation();
  const prevFlowStateRef = useRef();

  const [tag, setTag] = useState({})
  const [time, setTime] = useState();
  const [dueDate, setDueDate] = useState();
  const [flowState, setFlowState] = useState("");
  const [emptyList, setEmptyList] = useState(null);

  const [loading, setLoading] = useState(false);
  const [pageLoad, setPageLoad] = useState(true);

  const { device, setDevice, loadDevice } = useDevice()

  const [qrCodeData, setQrCodeData] = useState(location.state)
  const maxTask = 10;

  useEffect(() => {
    if (member === null || member === 'null') {
      navigate(`/select-member`)
    }
  }, [member])

  // handle navigate
  const handleNavigate = () => {
    navigate("/device")
  }

  // handle scan to fill form
  const handleScanToFilled = (data) => {

    // Handle case when dueDate.date may not exist in data
    if (data.dueDate && data.dueDate.date) {
      setDueDate(data.dueDate.date);
    }

    setQrCodeData((prevQrCodeData) => {
      const updatedQrCodeData = { ...prevQrCodeData };

      // Iterate through keys in data
      for (const key in data) {
        if (data.hasOwnProperty(key) && data[key] !== "") {
          // Update corresponding key in updatedQrCodeData if value is not empty
          updatedQrCodeData[key] = data[key];
        }
      }

      setQrCodeData(updatedQrCodeData)
    });
  }


  // time
  const datehandle = (date) => {
    setTime(date)
  }
  // flow
  const flowhandle = (flow) => {
    setFlowState(flow)
  }

  // handle data change in tag form
  const formData = (data) => {
    setQrCodeData((prevData) => ({
      ...prevData,
      ...data,
    }));
  }

  // handle submit button clicked
  const handleSubmit = async () => {

    const apiData = dataForApi(deviceId, tagId, tag, member, user, time, qrCodeData);
    const updateData = dataToAdd(apiData, user, member, tagId, time);

    try {
      setLoading(true);
      const tagDocRef = db.collection("tag").doc(tagId);
      const userDocRef = db.collection("user").where('userId', '==', user.userId)

      // transaction check 
      await userDocRef.get().then(async (querySnapshot) => {
        if (!querySnapshot.empty) {
          // const userData = querySnapshot.docs[0].data();
          // if (userData.transaction && userData.transaction.transactionCount >= userData.transaction.transactionMax) {
          // Swal.fire({
          //   title: "Maximum transaction reached.",
          //   icon: "error"
          // });
          // } else {

          // task remain check
          if (updateData.flow === "start") {
            const snapshot = await tagDocRef.collection("taskRemain").get();
            if (snapshot.size >= maxTask) {
              Swal.fire({
                title: `Task remain reached maximum limit (${maxTask} Tasks)`,
                icon: "error"
              })
              return;
            }
          }
          const response = await axios.post(apiUrl, apiData, config);

          if (response.data.status === "success") {


            await tagDocRef.update({ lastValue: updateData });

            await tagDocRef.collection("log").add(updateData);

            await userDocRef.get().then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                const docData = doc.data();
                const transactionDoc = docData.transaction;
                let transactionData = {};

                const startDay = docData?.transaction?.startDay?.toDate();
                const nowDate = new Date();
                const oneDay = 24 * 60 * 60 * 1000;

                const startOfDay = firebase.firestore.Timestamp.fromDate(
                  new Date(new Date().setHours(0, 0, 0, 0))
                );

                if (transactionDoc) {
                  if (nowDate - startDay >= oneDay) {
                    transactionData = {
                      transactionCount: 1,
                      transactionMax: transactionDoc.transactionMax || 200,
                      startDay: startOfDay
                    }
                  } else {
                    transactionData = {
                      transactionCount: transactionDoc.transactionCount + 1,
                      transactionMax: transactionDoc.transactionMax || 200,
                      startDay: docData.transaction.startDay
                    }
                  }
                } else {
                  transactionData = {
                    transactionCount: 1,
                    transactionMax: 200,
                    startDay: startOfDay
                  }
                }

                doc.ref.update({
                  transaction: transactionData
                })
                  .catch((error) => {
                    console.error("Error updating transaction: ", error);
                  });
              });
            }).catch((error) => {
              console.error("Error updating transaction: ", error);
            });

            // dataFormat check
            switch (updateData.dataFormat) {

              case "format1":
                Swal.fire({
                  title: "Data has been sent.",
                  icon: "success"
                }).then(() => {
                  handleNavigate();
                })
                break;

              case "format2":
                if (updateData.flow === "start") {
                  const createdTime = new Date().getTime();

                  const taskRemainDocRef = await tagDocRef.collection("taskRemain").add({
                    text1: updateData.text1,
                    text2: updateData.text2,
                    text3: updateData.text3,
                    num1: updateData.num1,
                    num2: updateData.num2,
                    num3: updateData.num3,
                    dueDate: updateData.dueDate,
                    created: createdTime
                  });
                  await taskRemainDocRef.update({ id: taskRemainDocRef.id });

                } else {
                  const querySnapshot = await tagDocRef.collection("taskRemain")
                    .where("text1", "==", updateData.text1)
                    .where("text2", "==", updateData.text2)
                    .where("text3", "==", updateData.text3)
                    .get();

                  querySnapshot.forEach(async (doc) => {
                    await doc.ref.delete();
                  });
                }

                Swal.fire({
                  title: "Data has been sent.",
                  icon: "success"
                }).then(() => {
                  handleNavigate();
                })
                break;

              default:
                console.error("Data format not found", updateData.dataFormat);
                break;
            }
          }
          // }
        }
      }).catch(error => {
        console.error("Error fetching user data: ", error);
      });
    } catch (error) {
      Swal.fire({
        title: "Data send failed.",
        icon: "error"
      })
      console.error('Error during submission:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    async function getTagData() {
      try {
        await db
          .collection("tag")
          .doc(tagId)
          .get()
          .then((doc) => {
            setTag(doc.data());
            setPageLoad(false)
          })

      } catch (error) {
        console.error(`Error getting tag data with ${tagId}`, error);
      }
    }
    getTagData()

  }, [qrCodeData])


  // handle condition 2,3
  useEffect(() => {
    // dataFormat check
    if (qrCodeData && time && prevFlowStateRef.current === flowState && !loading) {
      // console.log(qrCodeData);

      if (qrCodeData?.command === "condition2") {

        switch (qrCodeData.dataFormat) {

          case "format1":
            handleSubmit()
            break;

          case "format2":
            if (flowState !== "") { handleSubmit() }
            break;

          default:
            console.error("Data format not found", qrCodeData.dataFormat);
            break;
        }

      } else if (qrCodeData?.command === "condition3") {

        let emptyList = {}
        let checkList = [];

        switch (qrCodeData.dataFormat) {

          case "format1":
            checkList = ["text1"];
            emptyList = {};
            for (let i = 0; i < checkList.length; i++) {
              const field = checkList[i];
              if (qrCodeData[field] === '') {
                emptyList[field] = true;
              } else {
                handleSubmit();
              }
            }
            setEmptyList(emptyList);
            break;

          case "format2":
            checkList = ["text1", "text2", "text3", "num1", "num2", "num3", "dueDate"];
            emptyList = {};
            let isEmpty
            for (let i = 0; i < checkList.length; i++) {
              const field = checkList[i];
              if (field === "dueDate") {
                isEmpty = qrCodeData[field].date === '' || qrCodeData[field].date === null;
              } else {
                isEmpty = qrCodeData[field] === '';
              }
              emptyList[field] = isEmpty;
            }
            setEmptyList(emptyList);

            let noEmptyFound = true;

            for (const key in qrCodeData) {
              if (qrCodeData.hasOwnProperty(key)) {
                if (key === "dueDate") {
                  if (!qrCodeData[key].date) {
                    noEmptyFound = false;
                  }
                } else {
                  if (qrCodeData[key] === '') {
                    noEmptyFound = false;
                  }
                }
              }
            }
            if (noEmptyFound) {
              handleSubmit();
            } else {
              console.log("cannot send found empty data field");
            }
            break;

          default:
            console.error("Data format not found", qrCodeData.dataFormat);
            break;
        }
      }
    }
    prevFlowStateRef.current = flowState;
  }, [time, flowState, qrCodeData])

  return (
    <>
      <div className='d-flex justify-content-between align-items-center mb-6'>
        <div className='d-flex align-items-center' onClick={() => handleNavigate()}>
          <KTIcon iconName={'left-square'} className='fs-3x me-3' />
          <h3 className='fw-bolder my-2 ms-2'>Tag</h3>
        </div>
        <div className='text-muted fs-6 fw-bold'>
          {formatDef(tag.dataFormat)}
        </div>
      </div>


      {!pageLoad ? (
        <div className='card card-custom mb-10'>
          <div className='card-body'>

            {/* format1 */}
            {tag?.dataFormat === "format1" && (
              <DataFormat1Comp
                tag={tag}
                qrCodeData={qrCodeData}
                tagId={tagId}
                formData={formData}
                date={datehandle}
                emptyList={emptyList}
              />
            )}

            {/* format2 */}
            {tag?.dataFormat === "format2" && (
              <DataFormat2Comp
                tag={tag}
                qrCodeData={qrCodeData}
                tagId={tagId}
                formData={formData}
                date={datehandle}
                dueDate={dueDate}
                flow={flowhandle}
                emptyList={emptyList}
              />
            )}
          </div>
          <div className='card-footer'>
            {/* Submit Button */}
            <div className='d-flex gap-5 justify-content-center'>
              {!loading ? (
                <button
                  className='btn btn-primary'
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              ) : (
                <button className='btn btn-primary' >
                  <PulseLoader color='#fff' size={8} margin={2} />
                </button>
              )}
              <button
                className='btn btn-secondary'
                onClick={handleNavigate}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className='d-flex justify-content-center mt-10'>
          <span className='spinner-border' role="status"></span>
        </div>
      )}

      {/* Scan Button */}
      <div className='d-flex justify-content-center my-4'>
        <ScanTag
          device={device}
          toFilled={true}
          handleScanToFilled={handleScanToFilled} />
      </div>
      {/* Scan Button */}
    </>
  )
}

function dataForApi(deviceId, tagId, tag, member, user, dateState, qrCodeData) {

  let dateObject = new Date(dateState?.date)

  let timestampInSeconds = Math.floor(dateObject.getTime() / 1000)
  const transactionId = tagId + '-' + Math.floor(Date.now() / 1000)

  let data = {
    deviceId: deviceId,
    tagId: tagId,
    dataFormat: tag.dataFormat,
    updateTimestamp: timestampInSeconds,
    transactionId: transactionId,
    memberId: member,
    ownerId: user.userId,
  }


  switch (tag.dataFormat) {
    case "format1":
      data.text1 = qrCodeData.text1 || ""
      return data;

    case "format2":
      data.text1 = qrCodeData.text1 || ""
      data.text2 = qrCodeData.text2 || ""
      data.text3 = qrCodeData.text3 || ""
      data.num1 = qrCodeData.num1 || ""
      data.num2 = qrCodeData.num2 || ""
      data.num3 = qrCodeData.num3 || ""
      data.flow = qrCodeData.flow || ""
      data.dueDate = qrCodeData.dueDate || ""
      return data;

    default:
      break;
  }
}

function dataToAdd(apiData, user, member, tagId, dateState) {
  let dateObject = new Date(dateState.date)

  let timestampInSeconds = Math.floor(dateObject.getTime() / 1000)
  const transactionId = tagId + '-' + Math.floor(Date.now() / 1000)
  let data = {
    createTime: timestampInSeconds,
    dataFormat: apiData?.dataFormat,
    tagId: tagId,
    text1: apiData?.text1,
    transactionId: transactionId,
    updateTime: timestampInSeconds,
    memberId: member,
    ownerId: user.userId,
  }

  switch (apiData?.dataFormat) {
    case "format1":
      return data;

    case "format2":
      data.text1 = apiData.text1
      data.text2 = apiData.text2
      data.text3 = apiData.text3
      data.num1 = apiData.num1
      data.num2 = apiData.num2
      data.num3 = apiData.num3
      data.flow = apiData.flow
      data.dueDate = apiData.dueDate
      return data;

    default:
      break;
  }
}
