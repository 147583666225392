import React, { createContext, useContext, useState, useEffect } from 'react'
import firebase from '../../firebase/FirebaseConfig'
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth'
// import CryptoJS from 'crypto-js' // Import crypto-js for encryption

const firestore = firebase.firestore()
const UserContext = createContext()

export function useUser() {
  return useContext(UserContext)
}

export function UserProvider({ children }) {
  const [user, setUser] = useState(null)
  const auth = getAuth()
  const [loading, setLoading] = useState(true) // Prevent Log in page
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (userAuth) => {

      if (userAuth) {
        firestore
          .collection('user')
          .where('userId', '==', userAuth.uid)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              const data = doc.data()

              const userInfo = {
                email: data.email,
                firstname: data.firstname,
                lastname: data.lastname,
                userId: data.userId,
                docId: doc.id,
              }

              setUser(userInfo)
              setLoading(false)
            })
          })
      } else {
        setUser(null)
        setLoading(false)
      }
    })
    return () => unsubscribe() // Unsubscribe when the component unmounts
  }, [auth])

  const logout = async () => {
    try {
      await signOut(auth)
      setUser(null) // Clear the user data from context
    } catch (error) {
      console.error('Error signing out:', error)
    }
  }

  if (loading) {
    return null
  }

  return (
    <UserContext.Provider value={{ user, setUser, logout, loading }}>{children}</UserContext.Provider>
  )
}
