/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Languages } from './Languages'
import { toAbsoluteUrl } from '../../../helpers'
import { useUser } from '../../../../app/modules/newauth/UserContext'
import { useMember } from '../../../../app/modules/newauth/MemberContext'

const HeaderUserMenu = () => {
  const { user } = useUser()
  const { member, setMember, getMember } = useMember()

  const [memberData, setMemberData] = useState(null)
  useEffect(() => {
    async function fetchMember() {
      const memberData = await getMember()
      setMemberData(memberData)
    }
    fetchMember()
  }, [member])

  const handleSwitchAccount = () => {
    setMember(null)
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            {memberData &&
              (memberData.imageProfileType === 'avatar' ? (
                <img alt='Pic' src={toAbsoluteUrl(`/media/avatars/${memberData.imageAvatar}`)} />
              ) : (
                <img alt='Pic' src={memberData.imageProfile} />
              ))}
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {memberData?.name}

              {memberData?.role == 'admin' ? (
                <span className='badge badge-light-info fw-bolder fs-8 px-2 py-1 ms-2'>Admin</span>
              ) : (
                <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>Member</span>
              )}
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {user?.email}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      {member == null || member == 'null' ? (
        ''
      ) : (
        <div className='menu-item px-5'>
          <div className='menu-link px-5' onClick={() => handleSwitchAccount()}>
            Switch Account
          </div>
        </div>
      )}

      <div className='menu-item px-5'>
        <Link to='/setting'
          className='menu-link px-5'
        >
          Setting
        </Link>
      </div>

      <div className='menu-item px-5'>
        <Link to='/logout'
          className='menu-link px-5'
          onClick={() => handleSwitchAccount()}
        >
          Sign Out
        </Link>
      </div>
    </div>
  )
}

export { HeaderUserMenu }
