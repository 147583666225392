/* eslint-disable jsx-a11y/anchor-is-valid */

import { useUser } from '../../modules/newauth/UserContext'
import { useState, useEffect } from 'react'
import firebase from '../../firebase/FirebaseConfig'
import { useMember } from '../../modules/newauth/MemberContext'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useParams } from 'react-router-dom'
import 'flatpickr/dist/themes/material_green.css'
import { KTIcon } from '../../../_metronic/helpers'
import { CirclePicker } from 'react-color'
import { PulseLoader } from 'react-spinners'
import axios from 'axios'
import GETDATAFORMAT from './GETDATAFORMAT'
import clsx from 'clsx'
import EditTag from './EditTagFormat'

const EditTagPage = () => {
  const { user } = useUser()
  const { member } = useMember()
  const db = firebase.firestore()
  const navigate = useNavigate()
  const MySwal = withReactContent(Swal)
  const { deviceId, tagId } = useParams()

  const [background, setBackground] = useState('#f44336')
  const availableColors = ['#f44336', '#2196f3', '#4caf50', '#ffeb3b', '#ff9800']

  const [loading, setLoading] = useState(false)
  const [pageLoading, setPageLoading] = useState(true)
  const [tagData, setTagData] = useState({})

  const [isEdit, setIsEdit] = useState(false);

  const [tagIdData, setTagIdData] = useState('')
  const [tagDeviceId, setTagDeviceId] = useState('')
  const [tagName, setTagName] = useState('')
  const [tagDataFormat, setTagDataFormat] = useState('')
  const [tagDescription, setTagDescription] = useState('')
  const [tagColorTheme, setTagColorTheme] = useState('')
  const [flowSwitch, setFlowSwitch] = useState(false);
  const [flowSelect, setFlowSelect] = useState('');

  const [editFormatHeader, setEditFormatHeader] = useState(null)

  useEffect(() => {
    if (member === null || member === 'null') {
      navigate(`/select-member`)
    }
  }, [member])

  const handleNavigate = (path) => {
    navigate(path)
  }

  const handleEdit = (edit, submitData) => {
    setIsEdit(edit);
    setEditFormatHeader(submitData);
  }

  useEffect(() => {
    async function fetchTagData() {
      await db
        .collection('device')
        .doc(deviceId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            if (user.userId !== doc.data().owner) {
              MySwal.fire({
                title: 'You are not the owner of this device.',
                icon: 'error',
              }).then((result) => {
                navigate('/device')
              })
            }
          } else {
            MySwal.fire({
              title: 'Device not found.',
              icon: 'error',
            }).then((result) => {
              navigate('/device')
            })
          }
        })
        .catch((error) => {
          console.log('Error getting document:', error)
        })

      await db
        .collection('tag')
        .doc(tagId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            if (user.userId !== doc.data().owner) {
              MySwal.fire({
                title: 'You are not the owner of this tag.',
                icon: 'error',
              }).then((result) => {
                navigate('/device')
              })
            } else {
              setTagName(doc.data().tagName)
              setTagDataFormat(doc.data().dataFormat)
              setTagDescription(doc.data().description)
              setTagColorTheme(doc.data().colorTheme)
              setTagIdData(doc.id)
              setTagDeviceId(doc.data().deviceId)
              setTagData({ id: doc.id, ...doc.data() })
              setEditFormatHeader(doc.data().header)
              setFlowSwitch(doc.data().flowSwitch)
              setFlowSelect(doc.data().flowSelect)
            }
          } else {
            MySwal.fire({
              title: 'Tag not found.',
              icon: 'error',
            }).then((result) => {
              navigate('/device')
            })
          }
          setPageLoading(false);
        })
        .catch((error) => {
          setPageLoading(false);
          console.log('Error getting document:', error)
        })
    }
    fetchTagData()
  }, [tagId])

  const handleUpdateTag = async () => {
    if (loading) {
      return
    }

    setLoading(true)

    if (tagName === '') {
      MySwal.fire({
        title: 'Tag name cannot be empty.',
        icon: 'error',
      })
      setLoading(false)
      return
    }

    if (tagDescription === '') {
      MySwal.fire({
        title: 'Tag description cannot be empty.',
        icon: 'error',
      })
      setLoading(false)
      return
    }

    // const apiUrl = `http://127.0.0.1:5001/dygistechplatform/asia-southeast1/apiConnectMScanPro/v1/tags/edit`
    const apiUrl = `https://asia-southeast1-dygistechplatform.cloudfunctions.net/apiConnectMScanPro/v1/tags/edit`

    const config = {
      headers: {
        Authorization: 'Bearer E1ECE296153B9B65752E591911D44',
        'X-Api-Secret': 'BB5E88A38DB539372E795A249A5DD',
      },
    }

    const data = {
      tagId: tagIdData,
      deviceId: tagDeviceId,
      tagName: tagName,
      dataFormat: tagDataFormat,
      description: tagDescription,
      colorTheme: tagColorTheme,
      flowSwitch: flowSwitch,
      flowSelect: flowSelect,
    }

    axios
      .put(apiUrl, data, config)
      .then(async (response) => {
        if (response.data.status == 'success') {
          // Update mScanPro Database
          await db
            .collection('tag')
            .doc(tagIdData)
            .update({
              tagName: tagName,
              dataFormat: tagDataFormat,
              description: tagDescription,
              colorTheme: tagColorTheme,
              header: editFormatHeader,
              flowSwitch: flowSwitch,
              flowSelect: flowSelect || "",
            })
            .then(() => {
              setLoading(false)

              MySwal.fire({
                title: 'Tag updated successfully.',
                icon: 'success',
              }).then((result) => {
                navigate('/device')
              })
            })
            .catch((error) => {
              setLoading(false)

              MySwal.fire({
                title: 'Error update tag.',
                text: error,
                icon: 'error',
              })
            })
        } else {
          setLoading(false)

          MySwal.fire({
            title: 'Error update tag.',
            text: response.data.message,
            icon: 'error',
          })
        }
      })
      .catch(async (error) => {
        setLoading(false)

        MySwal.fire({
          title: 'Error update tag.',
          text: error,
          icon: 'error',
        })
      })
  }

  return (
    <>
      {/* <div className='d-flex justify-content-between align-items-center mb-6'>
        <div className='d-flex align-items-center' onClick={() => handleNavigate('/device')}>
          <KTIcon iconName={'left-square'} className='fs-3x me-3' />
          <h3 className='fw-bolder my-2 ms-2'>Edit detail</h3>
        </div>
        <ol className='breadcrumb text-muted fs-6 fw-bold'>
          <li className='breadcrumb-item' onClick={() => handleNavigate('/device')}>
            <span style={{ cursor: 'pointer' }}>Device</span>
          </li>
          <li className='breadcrumb-item text-muted'>Edit</li>
        </ol>
      </div> */}

      <div className='d-flex justify-content-between align-items-center mb-6'>
        {!isEdit ? (
          <div className='d-flex align-items-center' onClick={() => handleNavigate('/device')}>
            <KTIcon iconName={'left-square'} className='fs-3x me-3' />
            <h3 className='fw-bolder my-2 ms-2'>Edit detail</h3>
          </div>
        ) : (<div className='d-flex align-items-center' onClick={() => setIsEdit(false)}>
          <KTIcon iconName={'left-square'} className='fs-3x me-3' />
          <h3 className='fw-bolder my-2 ms-2'>Edit Format</h3>
        </div>)}
        <div
          className={clsx('btn btn-secondary d-flex align-items-center', { 'd-none': isEdit })}
          onClick={() => setIsEdit(true)}
        >
          <i className="fa-regular fa-pen-to-square me-1 fs-3"></i>
          Edit Format
        </div>
      </div>


      {isEdit && (
        <EditTag
          selectedFormat={tagDataFormat}
          handleEdit={handleEdit}
          editFormatHeader={editFormatHeader}
        />
      )}


      {pageLoading ? (
        <div className='d-flex justify-content-center mt-10'>
          <span className='spinner-border' role="status"></span>
        </div>
      ) : (
        <div className={clsx('card card-custom', {
          'd-none': isEdit
        })}>
          <div className='card-header'>
            <h3 className='card-title'>Detail</h3>
          </div>

          <div className='card-body'>
            <div className='mb-5'>
              <label htmlFor='tagName' className='form-label'>
                Tag name
              </label>
              <input
                id='tagName'
                type='text'
                className='form-control'
                placeholder='Tag name'
                value={tagName}
                onChange={(e) => setTagName(e.target.value)}
              />
            </div>

            <div className='mb-5'>
              <label htmlFor='dataFormat' className='form-label'>
                Data format
              </label>
              <select
                className='form-select'
                name='dataFormat'
                aria-label='Select example'
                value={tagDataFormat}
                onChange={(e) => setTagDataFormat(e.target.value)}
              >
                <GETDATAFORMAT />
              </select>
            </div>

            <div className='mb-5'>
              <label htmlFor='descriptionTag' className='form-label'>
                Description
              </label>
              <input
                id='descriptionTag'
                type='text'
                className='form-control'
                placeholder='Tag description'
                value={tagDescription}
                onChange={(e) => setTagDescription(e.target.value)}
              />
            </div>

            {tagDataFormat === "format2" && (
              <div className='mb-5'>
                <div className="form-check form-switch form-check-custom form-check-solid mb-2">
                  <input
                    className="form-check-input h-20px w-30px"
                    type="checkbox"
                    id="flowSwitch"
                    checked={flowSwitch}
                    onChange={() => setFlowSwitch(!flowSwitch)}
                  />
                  <label className="form-check-label text-black" htmlFor="flowSwitch">
                    Flow Status
                  </label>
                </div>
                {flowSwitch && (
                  <div className='mb-5'>
                    <select
                      className='form-select'
                      name='flow-select'
                      aria-label='Select example'
                      value={flowSelect}
                      onChange={(e) => setFlowSelect(e.target.value)}
                    >
                      <option value="start-finish">Start - Finish</option>
                    </select>
                  </div>
                )}
              </div>
            )}

            <div className='mb-5'>
              <label htmlFor='deviceName' className='form-label' style={{ color: tagColorTheme }}>
                Color theme
              </label>
              <CirclePicker
                color={tagColorTheme}
                onChangeComplete={(e) => setTagColorTheme(e.hex)}
                colors={availableColors}
              />
            </div>
          </div>
          <div
            className='card-footer'
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <button
              type='submit'
              className='btn btn-md btn-primary'
              onClick={() => handleUpdateTag()}
            >
              {loading ? (
                <PulseLoader color='#fff' size={8} margin={2} />
              ) : (
                <span className='indicator-label'>Update tag</span>
              )}
            </button>

            <button
              type='button'
              className='btn btn-md btn-light'
              onClick={() => navigate('/device')}
              style={{ marginLeft: '10px' }}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </>
  )
}

const EditTagWrapper = () => {
  return (
    <>
      <EditTagPage />
    </>
  )
}

export { EditTagWrapper }
