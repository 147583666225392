/* eslint-disable jsx-a11y/anchor-is-valid */

import {useUser} from '../../modules/newauth/UserContext'
import {useState, useEffect} from 'react'
import firebase from '../../firebase/FirebaseConfig'
import {useMember} from '../../modules/newauth/MemberContext'
import {useNavigate} from 'react-router-dom'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {useDevice} from '../../modules/newauth/DeviceContext'
import {TagComponent} from './widget/TagComponent'
import {MemberComponent} from './widget/MemberComponent'
import {DeviceComponent} from './widget/DeviceComponent'

const DevicePage = () => {
  const {user} = useUser()
  const {member, getMember} = useMember()
  const [memberObject, setMemberObject] = useState({})
  // const [device, setDevice] = useState({})
  const db = firebase.firestore()
  const navigate = useNavigate()

  const {device, setDevice, loadDevice} = useDevice()

  useEffect(() => {
    if (member === null || member === 'null') {
      navigate(`/select-member`)
    }
  }, [member])

  useEffect(() => {
    async function fetchMember() {
      const memberData = await getMember()
      setMemberObject(memberData)
    }
    fetchMember()
  }, [])

  useEffect(() => {
    async function fetchDevice() {
      const MySwal = withReactContent(Swal)
      await db
        .collection('device')
        .where('owner', '==', user.userId)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.empty) {
            console.log('No device.')
            MySwal.fire({
              title: "You still don't have a device, you need to create one before using.",
              showCancelButton: true,
              confirmButtonText: 'Create device',
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                navigate(`/create-device`)
              } else {
                navigate(`/select-member`)
              }
            })
          } else {
            querySnapshot.forEach((doc) => {
              // setDevice({deviceId: doc.id, ...doc.data()})
            })
          }
        })
        .catch((error) => {
          console.log('Error getting documents: ', error)
        })
    }
    fetchDevice()
  }, [user])

  useEffect(() => {
    loadDevice(user.userId)
  }, [])

  return (
    <>
      {/* <div className='d-flex flex-wrap flex-stack mb-2'>
        <h3 className='fw-bolder my-2'>Device</h3>
      </div> */}

      {/* <div className='card card-custom mb-10'>
        <div className='card-header'>
          <h3 className='card-title'>Device</h3>
        </div>
        <div className='card-body'>
          <h4 className='mb-4'>Member : {memberObject?.name}</h4>
          <h4 className='mb-8'>Member role : {memberObject?.role}</h4>

          <h4 className='mb-4'>Device name : {device?.deviceName}</h4>

          {device?.connectStatus ? (
            <>
              <h4 className='mb-4 text-success'>Device is connected to Malin1.</h4>
            </>
          ) : (
            <>
              <h4 className='mb-4 text-danger'>Device is not connect to Malin1.</h4>
              <a
                href='#'
                onClick={() =>
                  handleConnectMalin1(device?.deviceId, device?.owner, device?.deviceName)
                }
                className='btn btn-primary btn-sm'
              >
                Connect to Malin1
              </a>
            </>
          )}
        </div>
      </div> */}

      {/* <MemberComponent></MemberComponent> */}

      {/* <DeviceComponent device={device}></DeviceComponent> */}


      {/* <div className='row mb-10'> */}
        {/* <MemberComponent></MemberComponent> */}

        {/* <DeviceComponent device={device}></DeviceComponent> */}
      {/* </div> */}

      <TagComponent device={device}></TagComponent>
    </>
  )
}

const DeviceWrapper = () => {
  //   const intl = useIntl()
  return (
    <>
      {/* <PageTitle breadcrumbs={[]}>{'Select member'}</PageTitle> */}
      <DevicePage />
    </>
  )
}

export {DeviceWrapper}
