/* eslint-disable jsx-a11y/anchor-is-valid */

import { useUser } from '../../modules/newauth/UserContext'
import { useState, useEffect } from 'react'
import firebase from '../../firebase/FirebaseConfig'
import { useMember } from '../../modules/newauth/MemberContext'
import { useNavigate } from 'react-router-dom'
import { useDevice } from '../../modules/newauth/DeviceContext'
import { CirclePicker } from 'react-color'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import clsx from 'clsx';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { PulseLoader } from 'react-spinners'
import { KTIcon } from '../../../_metronic/helpers'

import GETDATAFORMAT from "./GETDATAFORMAT";
import EditTag from './EditTagFormat'

const CreateTagPage = () => {
  const { user } = useUser()
  const { member, getMember } = useMember()
  const [memberObject, setMemberObject] = useState({})
  const [loading, setLoading] = useState(false)
  const [selectedFormat, setSelectedFormat] = useState("format1");
  const [isEdit, setIsEdit] = useState(false);
  const [editFormatHeader, setEditFormatHeader] = useState(null)

  const db = firebase.firestore()
  const navigate = useNavigate()

  const { device, setDevice, loadDevice } = useDevice();


  const [background, setBackground] = useState('#f44336')
  const availableColors = ['#f44336', '#2196f3', '#4caf50', '#ffeb3b', '#ff9800']
  const handleChangeComplete = (color) => {
    setBackground(color.hex)
  }

  useEffect(() => {
    loadDevice(user.userId)
  }, [user])

  useEffect(() => {
    if (member === null || member === 'null') {
      navigate(`/select-member`)
    }
  }, [member])

  const validationSchema = Yup.object().shape({
    tagName: Yup.string().required('Tag name is required.'),
  })

  // เริ่มต้นใช้งาน Formik
  const formik = useFormik({
    initialValues: {
      tagName: '',
      descriptionTag: '',
      dataFormat: 'format1',
      flowSelect: 'start-finish',
      flowSwitch: true,
    },
    validationSchema,
    onSubmit: async (values) => {

      setLoading(true)

      const apiUrl =
        'https://asia-southeast1-dygistechplatform.cloudfunctions.net/apiConnectMScanPro/v1/tags/create-tag'

      const config = {
        headers: {
          Authorization: 'Bearer E1ECE296153B9B65752E591911D44',
          'X-Api-Secret': 'BB5E88A38DB539372E795A249A5DD',
        },
      }

      const MySwal = withReactContent(Swal)

      await db
        .collection('tag')
        .add(dataFormatDef(values.dataFormat, values, null, device, user, background, editFormatHeader))
        .then(async (docRef) => {
          console.log('Document written with ID: ', docRef.id)

          await db
            .collection('device')
            .doc(device.deviceId)
            .update({
              tag: firebase.firestore.FieldValue.arrayUnion(docRef.id),
            })
            .then(() => {

              axios
                .post(apiUrl, dataFormatDef(values.dataFormat, values, docRef.id, device, user, background, editFormatHeader), config)
                .then(async (response) => {
                  console.log('Data posted successfully:', response.data)
                  if (response.data.status === 'success') {
                    setLoading(false)

                    MySwal.fire({
                      title: 'Tag created successfully!',
                      icon: 'success',
                    }).then((result) => {
                      navigate('/device')
                    })
                  } else {
                    await db
                      .collection('tag')
                      .doc(docRef.id)
                      .delete()
                      .then(async () => {
                        console.log('Document successfully deleted!')

                        await db
                          .collection('device')
                          .doc(device.deviceId)
                          .update({
                            tag: firebase.firestore.FieldValue.arrayRemove(docRef.id),
                          })
                      })
                      .catch((error) => {
                        console.error('Error removing document: ', error)
                      })

                    MySwal.fire({
                      title: 'Create tag not complete please try again.',
                      icon: 'error',
                    })
                    setLoading(false)
                  }
                })
                .catch(async (error) => {
                  console.error('There was an error!', error)

                  await db
                    .collection('tag')
                    .doc(docRef.id)
                    .delete()
                    .then(async () => {
                      console.log('Document successfully deleted!')

                      await db
                        .collection('device')
                        .doc(device.deviceId)
                        .update({
                          tag: firebase.firestore.FieldValue.arrayRemove(docRef.id),
                        })
                    })
                    .catch((error) => {
                      console.error('Error removing document: ', error)
                    })

                  MySwal.fire({
                    title: 'Create tag not complete please try again.',
                    text: error,
                    icon: 'error',
                  })
                  setLoading(false)
                })
            })
        })
        .catch((error) => {
          console.error('Error adding document: ', error)
          setLoading(false)
        })
    },
  })

  const handleNavigate = (path) => {
    navigate(path)
  }

  const handleFormatChange = event => {
    formik.handleChange(event);
    setSelectedFormat(event.target.value)
  };

  const handleEdit = (edit, submitData) => {
    setIsEdit(edit);
    setEditFormatHeader(submitData);
  }

  return (
    <>
      <div className='d-flex justify-content-between align-items-center mb-6'>
        {!isEdit ? (
          <div className='d-flex align-items-center' onClick={() => handleNavigate('/device')}>
            <KTIcon iconName={'left-square'} className='fs-3x me-3' />
            <h3 className='fw-bolder my-2 ms-2'>Create Tag</h3>
          </div>
        ) : (<div className='d-flex align-items-center' onClick={() => setIsEdit(false)}>
          <KTIcon iconName={'left-square'} className='fs-3x me-3' />
          <h3 className='fw-bolder my-2 ms-2'>Edit Format</h3>
        </div>)}
        <div
          className={clsx('btn btn-secondary d-flex align-items-center', { 'd-none': isEdit })}
          onClick={() => setIsEdit(true)}
        >
          <i className="fa-regular fa-pen-to-square me-1 fs-3"></i>
          Edit Format
        </div>
      </div>

      {/* edit format */}
      {isEdit && (
        <EditTag
          selectedFormat={selectedFormat}
          handleEdit={handleEdit}
          editFormatHeader={editFormatHeader}
        />
      )}

      <div className={clsx('card card-custom', {
        'd-none': isEdit
      })}>
        {/* <div className='card-header'>
          <h3 className='card-title'>Create tag</h3>
        </div> */}

        <form onSubmit={formik.handleSubmit}>
          <div className='card-body'>
            {formik.touched.tagName && formik.errors.tagName ? (
              <div className='alert alert-danger d-flex align-items-center p-5 mb-5'>
                <div className='d-flex flex-column'>
                  <span>{formik.errors.tagName}</span>
                </div>
              </div>
            ) : null}

            {formik.touched.descriptionTag && formik.errors.descriptionTag ? (
              <div className='alert alert-danger d-flex align-items-center p-5 mb-5'>
                <div className='d-flex flex-column'>
                  <span>{formik.errors.descriptionTag}</span>
                </div>
              </div>
            ) : null}

            <div className='mb-5'>
              <label htmlFor='tagName' className='form-label'>
                Tag name
              </label>
              <input
                id='tagName'
                type='text'
                className='form-control'
                // placeholder='Tag name'
                {...formik.getFieldProps('tagName')}
              />
            </div>

            <div className='mb-5'>
              <label htmlFor='dataFormat' className='form-label'>
                Data format
              </label>
              <select
                className='form-select'
                name='dataFormat'
                {...formik.getFieldProps('dataFormat')}
                aria-label='Select example'
                value={selectedFormat}
                onChange={handleFormatChange}
              >
                <GETDATAFORMAT />
              </select>
            </div>

            <div className='mb-5'>
              <label htmlFor='descriptionTag' className='form-label'>
                Description
              </label>
              <input
                id='descriptionTag'
                type='text'
                className='form-control'
                // placeholder='Tag description'
                {...formik.getFieldProps('descriptionTag')}
              />
            </div>

            {selectedFormat == "format2" && (
              <>
                <div className="form-check form-switch form-check-custom form-check-solid mb-2">
                  <input
                    className="form-check-input h-20px w-30px"
                    type="checkbox"
                    id="flowSwitch"
                    checked={formik.values.flowSwitch}
                    onChange={formik.handleChange}
                  />
                  <label className="form-check-label text-black" htmlFor="flowSwitch">
                    Flow Status
                  </label>
                </div>
                {formik.values.flowSwitch && (
                  <div className='mb-5'>
                    <select
                      className='form-select'
                      name='flow-select'
                      {...formik.getFieldProps('flowSelect')}
                      aria-label='Select example'
                      value={formik.values.flowSelect}
                    >
                      <option value="start-finish">Start - Finish</option>
                    </select>
                  </div>
                )}
              </>
            )}

            <div className='mb-5'>
              <label htmlFor='deviceName' className='form-label' style={{ color: background }}>
                Color theme
              </label>
              <CirclePicker
                color={background}
                onChangeComplete={handleChangeComplete}
                colors={availableColors}
              />
            </div>
          </div>
          <div
            className='card-footer'
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <button type='submit' className='btn btn-md btn-primary'>
              {loading ? (
                <PulseLoader color='#fff' size={8} margin={2} />
              ) : (
                <span className='indicator-label'>Create tag</span>
              )}
            </button>

            <button
              type='button'
              className='btn btn-md btn-light'
              onClick={() => navigate('/device')}
              style={{ marginLeft: '10px' }}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

const CreateTagWrapper = () => {
  return (
    <>
      <CreateTagPage />
    </>
  )
}

export { CreateTagWrapper }

function dataFormatDef(formatType, values, tagId, device, user, background, header) {

  const timestampInSeconds = Math.floor(Date.now() / 1000);

  let data = {
    colorTheme: background,
    createDate: timestampInSeconds,
    dataFormat: values.dataFormat,
    description: values.descriptionTag || "-",
    deviceId: device.deviceId,
    owner: user.userId,
    tagName: values.tagName,
    header: header,
    lastValue: {
      createTime: timestampInSeconds,
      dataFormat: values.dataFormat,
      tagId: '',
      text1: '',
      transactionId: '',
      updateTime: timestampInSeconds,
    },
  }

  if (tagId) {
    data.tagId = tagId
  }

  switch (formatType) {
    case "format1":
      break;

    case "format2":
      data.flowSelect = values.flowSelect
      data.flowSwitch = values.flowSwitch
      data.lastValue.text2 = ""
      data.lastValue.text3 = ""
      data.lastValue.number1 = null
      data.lastValue.number2 = null
      data.lastValue.number3 = null
      data.lastValue.date = null
      data.lastValue.flow = ""
      break;

    default:
      console.error("Invalid format type", formatType);
      break;
  }
  return data;
}