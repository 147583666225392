/* eslint-disable jsx-a11y/anchor-is-valid */

import { useUser } from '../../modules/newauth/UserContext'
import { useState, useEffect } from 'react'
import firebase from '../../firebase/FirebaseConfig'
import { useMember } from '../../modules/newauth/MemberContext'
import { useNavigate } from 'react-router-dom'
import { CodeWidget } from './widget/CodeWidget'

const CodePage = () => {
  const { user } = useUser()
  const { member, getMember } = useMember()
  const [memberObject, setMemberObject] = useState({})
  const db = firebase.firestore()
  const navigate = useNavigate()

  const [codeArray, setCodeArray] = useState([])
  const [triggerCode, setTriggerCode] = useState(false)

  const [pageLoading, setPageLoading] = useState(true)

  useEffect(() => {
    if (member === null || member === 'null') {
      navigate(`/select-member`)
    }
  }, [member])

  useEffect(() => {
    async function fetchMember() {
      const memberData = await getMember()
      setMemberObject(memberData)
    }
    fetchMember()
  }, [])

  useEffect(() => {
    async function fetchTag() {
      await db
        .collection('code')
        .where('owner', '==', user.userId)
        .get()
        .then((querySnapshot) => {
          let code_array = []

          querySnapshot.forEach(async (doc) => {
            code_array.push({
              id: doc.id,
              ...doc.data(),
            })

            setCodeArray(code_array)
          })

          querySnapshot.size === 0 && setCodeArray([])
          setPageLoading(false);
        })
        .catch((error) => {
          setPageLoading(false);
          console.log('Error getting documents: ', error)
        })
    }
    fetchTag()
  }, [triggerCode])

  return (
    <>
      {pageLoading ? (
        <div className='d-flex justify-content-center mt-10'>
          <span className='spinner-border' role="status"></span>
        </div>
      ) : (
        <>
          <div className='d-flex justify-content-between align-items-center mb-6'>
            <h3 className='fw-bolder my-2'>All Code {`( ${codeArray.length} )`} </h3>
            <button className='btn btn-primary btn-sm' onClick={() => navigate(`/create-qr-code`)}>
              Create code
            </button>
          </div>

          <div className='row g-5 g-xl-8'>
            {codeArray.map((item) => (
              <div key={item.id} className='col-xl-3'>
                <CodeWidget obj={item} triggerCode={triggerCode} setTriggerCode={setTriggerCode} />
              </div>
            ))}
            <center>
              <div className='center'>{codeArray.length === 0 && 'No code found'}</div>
            </center>
          </div>
        </>
      )}
    </>
  )
}

const CodeWrapper = () => {
  return (
    <>
      <CodePage />
    </>
  )
}

export { CodeWrapper }
