/* eslint-disable jsx-a11y/anchor-is-valid */

import { useUser } from '../../modules/newauth/UserContext'
import { useState, useEffect } from 'react'
import firebase from '../../firebase/FirebaseConfig'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { DeviceQRCode } from './CreateDeviceQRCode'

import { useFormik } from 'formik'
import * as Yup from 'yup'

const CreateDevicePage = () => {
  const { user } = useUser()
  const db = firebase.firestore()
  const navigate = useNavigate()

  useEffect(() => {
    const headers = {
      Authorization: 'Bearer E1ECE296153B9B65752E591911D44',
      'X-API-Secret': 'BB5E88A38DB539372E795A249A5DD',
    }

    fetch(
      'https://asia-southeast1-dygistechplatform.cloudfunctions.net/apiConnectMScanPro/v1/devices/generate-device-id',
      { headers }
    )
      .then((response) => response.json())
      .then((data) => {
        formik.setFieldValue('deviceId', data.data.deviceId)
      })
      .catch((error) => console.error('Error fetching data:', error))
  }, [])

  const validationSchema = Yup.object().shape({
    deviceName: Yup.string().required('Device name is required'),
    deviceName: Yup.string().required('Please wait for generate device ID.'),
  })

  // เริ่มต้นใช้งาน Formik
  const formik = useFormik({
    initialValues: {
      deviceName: '',
      deviceId: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      const MySwal = withReactContent(Swal)
      const connectQRcode = await DeviceQRCode(values, user.userId, 300, 300, user.email)
      console.log(values)
      console.log(user.userId)

      await db.collection('device')
        .doc(values.deviceId)
        .set({
          deviceId: values.deviceId,
          deviceName: values.deviceName,
          owner: user.userId,
          connectStatus: false,
          ownerMalin1: '',
          connectQR: connectQRcode
        })
        .then(async () => {
          console.log('Document successfully written!')
          MySwal.fire({
            imageUrl: connectQRcode,
            imageWidth: 300,
            imageHeight: 300,
            imageAlt: "Device QR Code",
            title: 'Device creation complete',
            text: 'Please scan the QR code via malin1 to add device.',
          }).then((result) => {
            navigate(`/device`)
          })
        })
        .catch((error) => {
          console.error('Error writing document: ', error)
          MySwal.fire({
            title: 'Error',
            text: error,
            icon: 'error',
          })
        })
    },
  })
  return (
    <>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2'>Create device</h3>
        <div className='d-flex my-2'></div>
      </div>

      <div className='card card-custom'>
        <div className='card-header'>
          <h3 className='card-title'>Create device</h3>
        </div>

        <form onSubmit={formik.handleSubmit}>
          <div className='card-body'>
            {formik.touched.deviceName && formik.errors.deviceName ? (
              <div className='alert alert-danger d-flex align-items-center p-5 mb-5'>
                <div className='d-flex flex-column'>
                  <span>{formik.errors.deviceName}</span>
                </div>
              </div>
            ) : null}

            <div className='mb-5'>
              <label htmlFor='deviceName' className='form-label'>
                Device name
              </label>
              <input
                id='deviceName'
                type='text'
                className='form-control'
                placeholder='Device name'
                {...formik.getFieldProps('deviceName')}
              />
            </div>

            <div className='mb-2'>
              <label htmlFor='deviceId' className='form-label'>
                Device ID
              </label>
              <input
                id='deviceId'
                type='text'
                className='form-control'
                placeholder='Device ID'
                {...formik.getFieldProps('deviceId')}
                disabled={true}
              />
            </div>
          </div>
          <div
            className='card-footer'
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <button type='submit' className='btn btn-md btn-primary'>
              Create device
            </button>

            <button
              type='button'
              className='btn btn-md btn-light'
              onClick={() => navigate('/select-member')}
              style={{ marginLeft: '10px' }}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

const CreateDeviceWrapper = () => {
  //   const intl = useIntl()
  return (
    <>
      {/* <PageTitle breadcrumbs={[]}>{'Select member'}</PageTitle> */}
      <CreateDevicePage />
    </>
  )
}

export { CreateDeviceWrapper }
