import clsx from 'clsx'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {HeaderNotificationsMenu, HeaderUserMenu, Search, ThemeModeSwitcher} from '../../../partials'
import {useLayout} from '../../core'
import {useState, useEffect} from 'react'
import {useMember} from '../../../../app/modules/newauth/MemberContext'

const itemClass = 'ms-1 ms-md-4'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px'
const userAvatarClass = 'symbol-35px'
const btnIconClass = 'fs-2'
interface MemberData {
  imageProfileType: string
  imageAvatar: string
  imageProfile: string // Make sure to include all properties that will be accessed
}

const Navbar = () => {
  const {config} = useLayout()
  const {member, getMember} = useMember()
  const [memberData, setMemberData] = useState<MemberData | null>(null)
  useEffect(() => {
    async function fetchMember() {
      const memberData = await getMember()
      setMemberData(memberData)
    }
    fetchMember()
  }, [member])

  return (
    <div className='app-navbar flex-shrink-0'>

      <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          {memberData &&
            (memberData.imageProfileType === 'avatar' ? (
              <img alt='Pic' src={toAbsoluteUrl(`/media/avatars/${memberData.imageAvatar}`)} />
            ) : (
              <img alt='Pic' src={memberData.imageProfile} />
            ))}
        </div>
        <HeaderUserMenu />
      </div>
    </div>
  )
}

export {Navbar}
