/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
// import {QrReader} from 'react-qr-reader'
import CodeScanner from '../../scanner/CodeScanner'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { Link } from 'react-router-dom'
import { KTIcon } from '../../../../_metronic/helpers'
import { useState, useEffect } from 'react'

const ScanTag = ({ device, toFilled, handleScanToFilled }) => {
  const MySwal = withReactContent(Swal)

  const [isError, setIsError] = useState('')
  const [show, setShow] = useState(false)
  const navigate = useNavigate()
  const [cameras, setCameras] = useState([]);
  const [selectedCamera, setSelectedCamera] = useState(null);

  const storageCameraId = sessionStorage.getItem('cameraId');

  const handleScan = () => {
    setShow(true)
  }

  const handleClose = () => {
    setIsError('');
    setShow(false);
  }

  const onNewScanResult = (decodedText, decodedResult) => {
    let obj
    if (!toFilled) {
      try {
        obj = JSON.parse(decodedText)
        switch (obj.dataFormat) {
          case 'format1':
          case 'format2':
            navigate(`/device/${obj.deviceId}/tag/${obj.tagId}`, { state: obj })
            break
          default:
            setIsError("Invalid QR Code or Barcode");
        }
      } catch (e) {
        setIsError("Invalid QR Code or Barcode");
      }
    } else {
      handleScanToFilled(JSON.parse(decodedText))
      handleClose()
    }
  }


  useEffect(() => {
    // Fetch the list of available cameras
    const fetchCameras = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(device => device.kind === 'videoinput');
        setCameras(videoDevices);
        if (videoDevices.length > 0) {
          if (storageCameraId) {
            setSelectedCamera(storageCameraId);
          } else {
            setSelectedCamera(videoDevices[0].deviceId);
          }
        }
      } catch (error) {
        console.error('Error fetching cameras:', error);
      }
    };

    fetchCameras();

  }, []);
  // const handleFileSelect = (e) => {

  // };
  const handleCameraChange = event => {
    setSelectedCamera(event.target.value);
    sessionStorage.setItem('cameraId', event.target.value);
  };

  return (
    <>
      <button
        className='btn btn-primary rounded-pill shadow d-flex justify-content-center align-items-center'
        style={{
          width: '200px',
          position: 'fixed',
          bottom: '70px',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
        onClick={() => handleScan()}
        disabled={device?.connectStatus === false}
      >
        <KTIcon iconName={'scan-barcode'} className='fs-2x me-1' />
        <span className='fs-1'>Scan</span>
      </button>

      <Modal show={show} onHide={handleClose} centered animation={false}>
        {/* <Modal.Header closeButton>
          <Modal.Title>Scan</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <div className='input-group d-flex align-items-center mb-5'>
            <label htmlFor="camera-selection" className='input-group-text fw-medium fs-5'>Camera</label>
            <select className='form-select' id='camera-selection' value={selectedCamera} onChange={handleCameraChange}>
              {cameras.map(camera => (
                <option key={camera.deviceId} value={camera.deviceId}>
                  {camera.label || `Camera ${camera.deviceId}`}
                </option>
              ))}
            </select>
          </div>

          <div className='App'>
            <CodeScanner
              cameraId={selectedCamera}
              qrCodeSuccessCallback={onNewScanResult}
            />
          </div>

          <div className='text-danger fs-3 mt-2 text-center'>{isError}</div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  )
}

export { ScanTag }
