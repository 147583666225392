export function formatDef(format) {
    switch (format) {

        case 'format1':
            return 'Format 1';

        case 'format2':
            return 'Format 2';

        default:
            return 'Unknown format';
    }
}