import { useState, useEffect } from 'react'
import Flatpickr from 'react-flatpickr'
import clsx from 'clsx'
import firebase from '../../../firebase/FirebaseConfig'

export const DataFormat2Comp = ({ tag, qrCodeData, tagId, formData, date, dueDate, flow, emptyList }) => {
  const db = firebase.firestore()
  const [textOne, setTextOne] = useState(qrCodeData?.text1 || "")
  const [textTwo, setTextTwo] = useState(qrCodeData?.text2 || "")
  const [textThree, setTextThree] = useState(qrCodeData?.text3 || "")

  const [num1, setNum1] = useState(qrCodeData?.num1 || "")
  const [num2, setNum2] = useState(qrCodeData?.num2 || "")
  const [num3, setNum3] = useState(qrCodeData?.num3 || "")

  const [flowState, setFlowState] = useState(qrCodeData?.flow || "");


  const [dateState, setDateState] = useState({
    date: new Date(),
  })

  const [duedateState, setDueDateState] = useState({ date: null })

  useEffect(() => {
    if (qrCodeData?.dueDate.date) {
      const newDate = new Date(qrCodeData?.dueDate.date * 1000);
      setDueDateState({ date: newDate });
    }
  }, [dueDate])


  useEffect(() => {
    async function getTagTask() {
      let taskData = qrCodeData
      if (tag.flowSwitch) {
        try {
          const querySnapshot = await db
            .collection('tag')
            .doc(tagId)
            .collection('taskRemain')
            .where('text1', '==', taskData?.text1)
            .where('text2', '==', taskData?.text2)
            .where('text3', '==', taskData?.text3)
            .get();

          if (!querySnapshot.empty) {
            if (flowState !== "cancel") {
              const docData = querySnapshot.docs[0].data();
              setFlowState(flowCheck(docData, taskData))
              flow(flowCheck(docData, taskData))
            }
          } else {
            setFlowState("start")
            flow("start")
          }
        } catch (error) {
          console.error("Firestore query error:", error);
        }
      }
    }

    if (qrCodeData) {
      if (qrCodeData.text1 !== "" && qrCodeData.text2 !== "" && qrCodeData.text3 !== "") {
        getTagTask();
      }
      if (qrCodeData.text1 !== "") {
        setTextOne(qrCodeData.text1)
      }
      if (qrCodeData.text2 !== "") {
        setTextTwo(qrCodeData.text2)
      }
      if (qrCodeData.text3 !== "") {
        setTextThree(qrCodeData.text3)
      }

      if (qrCodeData.num1 !== "") {
        setNum1(qrCodeData.num1)
      }
      if (qrCodeData.num2 !== "") {
        setNum2(qrCodeData.num2)
      }
      if (qrCodeData.num3 !== "") {
        setNum3(qrCodeData.num3)
      }
    }
  }, [qrCodeData]);

  useEffect(() => {
    const data = {
      text1: textOne,
      text2: textTwo,
      text3: textThree,
      num1: num1,
      num2: num2,
      num3: num3,
      flow: flowState,
      dueDate: duedateState,
    }
    formData(data)
  }, [textOne, textTwo, textThree, num1, num2, num3, dateState, flowState, duedateState])

  useEffect(() => {
    date(dateState)
  }, [dateState])


  const toggleFlowState = (newState) => {
    setFlowState(prevState => (prevState === newState ? "" : newState));
  };

  const handleDateChange = ([selectedDate]) => {
    if (selectedDate) {
      selectedDate.setHours(0, 0, 0, 0);
      setDueDateState({ date: selectedDate });
    }
  };


  return (
    <>
      <div className='mb-5'>
        {/* <div className='form-label fw-bold text-gray-600'>Tag name</div> */}
        <span
          style={{
            width: '15px',
            height: '15px',
            backgroundColor: tag.colorTheme,
            display: 'inline-block',
          }}
          className='bullet bullet-dot me-3'
        ></span>
        <span className='fw-bold fs-2x'>{capitalizeFirstLetter(tag.tagName)}</span>
      </div>

      <div className='mb-5 row'>
        <div className='col-6'>
          {/* <label className='form-label fw-bold text-gray-600'>Description</label> */}
          <p className='fw-bold fs-3'>{capitalizeFirstLetter(tag.description)}</p>
        </div>
        {/* <div className='col-6'>
          <label className='form-label fw-bold text-gray-600'>Data format</label>
          <p className='fw-bold fs-3'>{capitalizeFirstLetter(tag.dataFormat)}</p>
        </div> */}
      </div>

      <div className='separator my-5'></div>

      <div className='mb-5'>
        <label htmlFor='time' className='form-label fw-bold text-gray-600'>
          Time
        </label>
        <div className='d-flex align-items-center'>
          <Flatpickr
            value={dateState.date}
            onChange={([date]) => setDateState({ date })}
            options={{
              enableTime: true,
              dateFormat: 'Y-m-dTH:i',
            }}
            className='form-control form-control-solid'
          // placeholder='Pick date'
          />
          <i className="ki-duotone ki-arrows-circle mx-2 fs-1"
            onClick={() => setDateState({ date: new Date() })}>
            <span className="path1"></span>
            <span className="path2"></span>
          </i>
        </div>
      </div>
      {/* text */}
      <div className='mb-5'>
        <label htmlFor='text1' className='form-label fw-bold text-gray-600'>
          {tag?.header?.text1 || "Text 1"}
        </label>
        <input
          id='text1'
          type='text'
          className={clsx('form-control border', {
            'border-danger': emptyList?.text1,
          })}
          disabled={qrCodeData?.taskRemain}
          // placeholder='Text 1'
          value={textOne}
          onChange={(e) => setTextOne(e.target.value)}
        />
      </div>
      <div className='mb-5'>
        <label htmlFor='textTwo' className='form-label fw-bold text-gray-600'>
          {tag?.header?.text2 || "Text 2"}
        </label>
        <input
          id='textTwo'
          type='text'
          className={clsx('form-control border', { 'border-danger': emptyList?.text2 })}
          disabled={qrCodeData?.taskRemain}
          // placeholder='Text 2'
          value={textTwo}
          onChange={(e) => setTextTwo(e.target.value)}
        />
      </div>
      <div className='mb-5'>
        <label htmlFor='textThree' className='form-label fw-bold text-gray-600'>
          {tag?.header?.text3 || "Text 3"}
        </label>
        <input
          id='textThree'
          type='text'
          className={clsx('form-control border', { 'border-danger': emptyList?.text3 })}
          disabled={qrCodeData?.taskRemain}
          // placeholder='Text 3'
          value={textThree}
          onChange={(e) => setTextThree(e.target.value)}
        />
      </div>

      {/* date */}
      <div className='mb-5'>
        <label htmlFor='time' className='form-label fw-bold text-gray-600'>
          {tag?.header?.dueDate || "Due Date"}
        </label>
        <Flatpickr
          value={duedateState.date}
          onChange={handleDateChange}
          options={{
            enableTime: false,
            dateFormat: 'Y-m-dTH:i',
          }}
          className={clsx('form-control', { 'border-danger-flatpickr': emptyList?.dueDate })}
        // placeholder='Pick date'
        />
      </div>

      {/* number */}
      <div className='mb-5'>
        <label htmlFor='num1' className='form-label fw-bold text-gray-600'>
          {tag?.header?.num1 || "Number 1"}
        </label>
        <input
          id='num1'
          type='number'
          className={clsx('form-control border', { 'border-danger': emptyList?.num1 })}
          // placeholder='Number 1'
          value={num1}
          onChange={(e) => setNum1(e.target.value)}
        />
      </div>
      <div className='mb-5'>
        <label htmlFor='num2' className='form-label fw-bold text-gray-600'>
          {tag?.header?.num2 || "Number 2"}
        </label>
        <input
          id='num2'
          type='number'
          className={clsx('form-control border', { 'border-danger': emptyList?.num2 })}
          // placeholder='Number 2'
          value={num2}
          onChange={(e) => setNum2(e.target.value)}
        />
      </div>
      <div className='mb-5'>
        <label htmlFor='num3' className='form-label fw-bold text-gray-600'>
          {tag?.header?.num3 || "Number 3"}
        </label>
        <input
          id='num3'
          type='number'
          className={clsx('form-control border', { 'border-danger': emptyList?.num3 })}
          // placeholder='Number 3'
          value={num3}
          onChange={(e) => setNum3(e.target.value)}
        />
      </div>

      {/* flow */}
      <div className='mb-10'>
        <label htmlFor='flow' className='form-label fw-bold text-gray-600'>
          {tag?.header?.flow || "Flow"}
        </label>
        <div id='flow' className='d-flex justify-content-between'>
          <div className='d-flex gap-4'>
            <button
              className={clsx('btn btn-secondary rounded rounded-pill', {
                "btn-info": flowState === "start"
              })}
              onClick={() => toggleFlowState("start")}
            >
              Start
            </button>
            <button
              className={clsx('btn btn-secondary rounded rounded-pill', {
                "btn-info": flowState === "finish"
              })}
              onClick={() => toggleFlowState("finish")}
            >
              Finish
            </button>
          </div>
          {/* flow cancel */}
          <button
            className={clsx('btn btn-secondary rounded rounded-pill', {
              "btn-danger": flowState === "cancel"
            })}
            onClick={() => toggleFlowState("cancel")}
          >
            <i className="fa-solid fa-ban"></i> Cancel
          </button>
        </div>
      </div>
    </>
  )
}

function capitalizeFirstLetter(string) {
  const str = String(string)

  if (str.trim().length === 0) {
    return ''
  }

  return str.charAt(0).toUpperCase() + str.slice(1)
}

function flowCheck(taskData, inputData) {
  if (taskData) {
    if (taskData?.text1 === inputData?.text1 && taskData?.text2 === inputData?.text2 && taskData?.text3 === inputData?.text3) {
      return "finish"
    } else {
      return "start"
    }
  }
}