/* eslint-disable react/jsx-no-target-blank */
import {useEffect, useState, useRef} from 'react'
import {ILayout, useLayout} from '../../core'
import clsx from 'clsx'
import firebase from '../../../../app/firebase/FirebaseConfig'
import {useUser} from '../../../../app/modules/newauth/UserContext'

const Footer = () => {
  const {config} = useLayout()
  const {user} = useUser()
  const db = firebase.firestore()

  const [progress, setProgress] = useState(0)

  const maxRef = useRef(200)

  useEffect(() => {
    const userCollectionRef = db.collection('user').where('userId', '==', user.userId)

    const unsubscribe = userCollectionRef.onSnapshot(
      (snapshot) => {
        if (snapshot.empty) {
          console.error('No matching documents.')
          return
        }
        const dataDoc = snapshot.docs[0].data()
        if (dataDoc) {
          const transactionDoc = dataDoc.transaction
          if (transactionDoc) {
            const startDay = transactionDoc?.startDay?.toDate()
            if (!startDay) {
              console.error('Start day is not defined.')
              return
            }
            const nowDate = new Date()
            const oneDay = 24 * 60 * 60 * 1000

            const startDayEpoch = startDay.getTime()
            const nowDateEpoch = nowDate.getTime()
            
            if (nowDateEpoch - startDayEpoch >= oneDay) {
              const startOfDay = firebase.firestore.Timestamp.fromDate(
                new Date(new Date().setHours(0, 0, 0, 0))
              )
              snapshot.docs[0].ref
                .update({
                  'transaction.transactionCount': 0,
                  'transaction.transactionMax': transactionDoc.transactionMax || 200,
                  'transaction.startDay': startOfDay,
                })
                .then(() => {
                  setProgress(0)
                  maxRef.current = transactionDoc.transactionMax || 200
                })
                .catch((error) => {
                  console.error('Error updating transaction: ', error)
                })
            } else {
              setProgress(transactionDoc.transactionCount)
              maxRef.current = transactionDoc.transactionMax
            }
          }
        }
      },
      (error) => {
        console.error('Error getting transaction: ', error)
      }
    )

    return () => unsubscribe()
  }, [user.userId])

  const percent = (progress / maxRef.current) * 100

  return (
    <>
      <div className='d-block fs-5 fw-medium'>
        {progress} / {maxRef.current}
      </div>
      <div className='w-100 d-flex justify-content-center'>
        <div className='progress w-75 w-xl-150px w-xxl-300px h-20px bg-secondary'>
          <div
            className={clsx('progress-bar rounded fs-7 fw-bold', {
              'bg-success': percent < 80,
              'bg-warning': percent >= 80 && percent <= 99,
              'bg-danger': percent > 99,
            })}
            role='progressbar'
            style={{width: `${percent}%`}}
            aria-valuenow={percent}
            aria-valuemin={0}
            aria-valuemax={100}
          >
            {percent.toFixed(0)}%
          </div>
        </div>
      </div>
    </>
  )
}

const updateDOM = (config: ILayout) => {
  if (config.app?.footer?.fixed?.desktop) {
    document.body.classList.add('data-kt-app-footer-fixed', 'true')
  }

  if (config.app?.footer?.fixed?.mobile) {
    document.body.classList.add('data-kt-app-footer-fixed-mobile', 'true')
  }
}

export {Footer}
