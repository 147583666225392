import React, { useRef, useEffect, useState } from 'react';
import { QRCodeWriter, BarcodeFormat, EncodeHintType } from '@zxing/library';

export async function DeviceQRCode(deviceData, userId, width, height, email) {
    if (deviceData && userId) {
        const data = {
            "type": "mScanPro",
            "deviceId": deviceData.deviceId,
            "deviceName": deviceData.deviceName,
            "owner": userId,
            "email": email
        }
        const qrCodeWriter = new QRCodeWriter();
        const hints = new Map();
        const bitMatrix = qrCodeWriter.encode(JSON.stringify(data), BarcodeFormat.QR_CODE, width, height, hints);

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = width;
        canvas.height = height;

        for (let y = 0; y < bitMatrix.getHeight(); y++) {
            for (let x = 0; x < bitMatrix.getWidth(); x++) {
                ctx.fillStyle = bitMatrix.get(x, y) ? '#000000' : '#ffffff';
                ctx.fillRect(x, y, 1, 1);
            }
        }
        const url = canvas.toDataURL();
        console.log(url);
        return url;
    }
}

