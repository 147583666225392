/* eslint-disable jsx-a11y/anchor-is-valid */
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'

import { Card1 } from '../member/widget/Card1'
import { CardAddMember } from '../member/widget/CardAddMember'
import { useUser } from '../../modules/newauth/UserContext'
import { useState, useEffect } from 'react'
import firebase from '../../firebase/FirebaseConfig'
import { useNavigate } from 'react-router-dom'
import { useMember } from '../../modules/newauth/MemberContext'

const SelectMemberPage = () => {
  const { user } = useUser()
  const db = firebase.firestore()
  const navigate = useNavigate()
  const [memberObject, setMemberObject] = useState([])

  const { member } = useMember()

  useEffect(() => {
    if (member !== null) {
      navigate(`/select-member`)
    }
  }, [member])

  useEffect(() => {
    const fetchmemberData = async () => {
      let array = []
      await db
        .collection('user')
        .doc(user.docId)
        .collection('member')
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            array.push({ id: doc.id, ...doc.data() })
          })
        })
      array.sort((a, b) => {
        if (a.role === 'admin') return -1
        if (b.role === 'admin') return 1
        return 0
      })
      setMemberObject(array)

      if (array.length === 0) {
        window.location.href = '/create-member'
      }
    }

    const fetchDeviceData = async () => {
      await db
        .collection('device')
        .where('owner', '==', user.userId)
        .get()
        .then((querySnapshot) => {

          if (!querySnapshot.empty) {
            fetchmemberData();
          } else {
            window.location.href = '/create-device'
          }

        })
        .catch(error => {
          console.error("Error fetching device data:", error);
        });
    };
    fetchDeviceData();
  }, [user]);


  return (
    <>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2'>
          Select member
          <span className='fs-6 text-gray-400 fw-bold ms-1'>({memberObject.length})</span>
        </h3>

        <div className='d-flex my-2'>
          {/* <select
          name='status'
          data-control='select2'
          data-hide-search='true'
          className='form-select form-select-white form-select-sm w-125px'
          defaultValue='Online'
        >
          <option value='Online'>Online</option>
          <option value='Pending'>Pending</option>
          <option value='Declined'>Declined</option>
          <option value='Accepted'>Accepted</option>
        </select> */}
        </div>
      </div>

      <div className='row g-6 g-xl-9 d-flex flex-wrap'>
        {memberObject.map((item) => (
          <Card1
            item={item}
            key={item.id}
            memberId={item.id}
            avatar='/media/avatars/300-1.jpg'
            name={item.name}
            job={item.description}
            role={item.role}
            isPasswordEnabled={item.isPasswordEnabled}
          />
        ))}

        <CardAddMember
          avatar='/media/avatars/300-12.jpg'
          name='Ana Crown'
          job='Customer Relationship'
          avgEarnings='$14,560'
          totalEarnings='$236,400'
        />
      </div>
    </>
  )
}

const SelectMemberWrapper = () => {
  //   const intl = useIntl()
  return (
    <>
      {/* <PageTitle breadcrumbs={[]}>{'Select member'}</PageTitle> */}
      <SelectMemberPage />
    </>
  )
}

export { SelectMemberWrapper }
