import { useState, useEffect } from "react"
import firebase from "../../firebase/FirebaseConfig"
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import { useMember } from "../../modules/newauth/MemberContext";
import { Modal } from "react-bootstrap";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

function MemberSetting(props) {
    const db = firebase.firestore();
    const { user } = props
    const [memberData, setMemberData] = useState([]);
    const { member, getMember } = useMember();
    const [showModal, setShowmodal] = useState(false);
    const [selectedMember, setSelectedMember] = useState("");
    const [thisMember, setThisMember] = useState("");
    const MySwal = withReactContent(Swal)

    const getUserMember = async () => {
        const memberList = [];
        await db
            .collection('user')
            .doc(user.docId)
            .collection('member')
            .get()
            .then((snapshot) => {
                snapshot.forEach(member => {
                    memberList.push(member.data());
                });
                setMemberData(memberList);
            });
    };

    useEffect(() => {
        getUserMember();
    }, []);

    useEffect(() => {
        getMember().then((data) => {
            setThisMember(data);
        });
    }, []);

    const handleDelete = async (memberId) => {

        const foundMember = memberData.find(items => items.memberId == memberId)

        MySwal.fire({
            title: "Do you want to delete this member",
            text: foundMember.name,
            showCancelButton: true,
            confirmButtonText: "Delete",
            denyButtonText: `No`
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await db
                        .collection('user')
                        .doc(user.docId)
                        .collection('member')
                        .doc(memberId)
                        .delete()
                        .then(() => {
                            MySwal.fire({
                                title: 'Deleted',
                                icon: 'success'
                            }).then(() => {
                                getUserMember();
                                setShowmodal(false);
                            });
                        });
                } catch (error) {
                    MySwal.fire({
                        title: 'Delete failed',
                        icon: 'error'
                    })
                    getUserMember();
                    setShowmodal(false);
                    console.error("Error deleting member: ", error);
                }
            }
        });
    };

    const handleCardClick = (memberId) => {
        if (memberId != member) {
            const foundMember = memberData.find(items => items.memberId == memberId)
            if (foundMember) {
                setShowmodal(true);
                setSelectedMember(foundMember)
            }

        }
    }

    return (
        <>
            <div className="row">
                {memberData.map((item) => (
                    <div className="p-3 col-6 col-xxl-4" key={item.memberId}>
                        <div className="card">
                            <div className="card-body text-center" onClick={() => handleCardClick(item.memberId)}>
                                <div className="mb-5">
                                    <div className='symbol symbol-75px symbol-circle'>
                                        {item.imageProfileType == 'avatar' ? (
                                            <img alt='Pic' src={toAbsoluteUrl(`/media/avatars/${item.imageAvatar}`)} />
                                        ) : (
                                            <img alt='Pic' src={item.imageProfile} style={{ objectFit: 'contain' }} />
                                        )}
                                    </div>
                                </div>
                                <a href='#' className='fs-4 text-gray-800 text-hover-primary fw-bolder mb-1'>
                                    {item.memberId == member ? ("Me") : (item.name)}
                                </a>

                                {item.role == 'admin' ? (
                                    <div>
                                        <span className='badge badge-light-info mb-2'>Admin</span>
                                        {item.isPasswordEnabled ? (
                                            <span className='badge badge-light-info mb-2'>
                                                <FontAwesomeIcon icon={faLock} />
                                            </span>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                ) : (
                                    <div >
                                        <span className='badge badge-light-success mb-2'>Member</span>
                                        {item.isPasswordEnabled ? (
                                            <span className='badge badge-light-success mb-2'>
                                                <FontAwesomeIcon icon={faLock} />
                                            </span>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <Modal show={showModal} onHide={() => setShowmodal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Manage Member ({selectedMember.name})</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">

                    <button className="btn btn-danger w-100"
                        onClick={() => handleDelete(selectedMember.memberId)}
                        disabled={thisMember.role === "member"}
                    >
                        Delete this member
                    </button>

                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default MemberSetting
