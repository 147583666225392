import { Html5QrcodeScanner } from 'html5-qrcode'
import { useEffect, useState, useRef } from 'react'
import {
  BrowserMultiFormatReader,
  NotFoundException,
  ChecksumException,
  FormatException,
  DecodeHintType,
  BarcodeFormat,
} from "@zxing/library";
import './scannerFrame.css'


const CodeScanner = (props) => {
  const videoRef = useRef(null);
  const reader = useRef(new BrowserMultiFormatReader());
  const [reScan, setRescan] = useState(false);
  const [stopScan, setStopScan] = useState(false);
  const cameraId = props.cameraId;

  const storageCameraId = sessionStorage.getItem('cameraId');

  useEffect(() => {
    if (!videoRef.current) return;
    let constraints = {
      audio: false,
      video: {
        facingMode: 'environment',
        frameRate: 30 //reduce resource usage
      },
    };

    if (cameraId) {
      if (storageCameraId) {
        constraints.video.deviceId = { exact: storageCameraId };
      } else {
        constraints.video.deviceId = { exact: cameraId };
      }
    }

    const startScan = () => {
      reader.current.decodeFromConstraints(
        constraints,
        videoRef.current,
        (result, error) => {
          if (result) {
            handleResult(result.text, result);
          };
        }
      );
    };

    let startTime = Date.now();

    // Start scanning
    startScan();

    // Terminate scanning after 3 minute
    const terminationInterval = setInterval(() => {
      if (Date.now() - startTime > 180000) {
        clearInterval(terminationInterval);
        reader.current.reset();
        setRescan(false);
        setStopScan(true);
      }
    }, 1000);

    return () => {
      clearInterval(terminationInterval); // Clear the termination interval
      reader.current.reset(); // Reset the scanner
    };
  }, [videoRef, cameraId, reScan]);

  const handleResult = (text, result) => {
    props.qrCodeSuccessCallback(result);
  };

  const handleRescan = () => {
    setStopScan(false);
    setRescan(true);
  };

  return (
    <>
      <div className='d-flex justify-content-center'>
        {!stopScan && (
          <div className='scanner-container position-relative'>
            <div className='inner-frame-scanner'></div>
            <video
              className='video-content'
              ref={videoRef}
            />
          </div>
        )}

        {stopScan && (
          <div className='text-center'>
            <div className='text-danger fs-2'>Scan Timeout</div>
            <button
              className='btn btn-primary'
              onClick={handleRescan}
            >
              Rescan
            </button>
          </div>
        )}
      </div>


      {/* <div className='scannerBtn-container mt-5'>
        <label htmlFor="file-upload" className="custom-file-upload d-flex justify-content-center btn btn-primary btn-md">
          Choose Image
        </label>
        <input id="file-upload" type="file" accept="image/*" onChange={handleFileSelect} style={{ display: 'none' }} />
      </div> */}
    </>
  );
}

export default CodeScanner
