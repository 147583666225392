/* eslint-disable jsx-a11y/anchor-is-valid */

import { TagWidget } from './TagWidget'
import { useEffect } from 'react'
import firebase from '../../../firebase/FirebaseConfig'
import { useState } from 'react'
// import {AddTag} from './AddTag'
import { useNavigate } from 'react-router-dom'
import { useUser } from '../../../modules/newauth/UserContext'
import { useMember } from '../../../modules/newauth/MemberContext'
import { ScanTag } from './ScanTag'
import { KTIcon } from '../../../../_metronic/helpers'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

const TagComponent = ({ device }) => {
  const db = firebase.firestore()
  const [tag, setTag] = useState([])
  const [showModalQR, setShowModalQR] = useState(false)
  const [updateTag, setUpdateTag] = useState(true)
  const navigate = useNavigate()
  const { user } = useUser()
  const [isLoading, setIsLoading] = useState(null);
  const [connQR, setConnQR] = useState("");
  const [memberData, setMemberData] = useState({});
  const { getMember } = useMember();

  useEffect(() => {
    async function fetchTag() {
      try {
        setIsLoading(true);

        const deviceSnapshot = await db
          .collection('device')
          .where('owner', '==', user.userId)
          .get();

        const tagPromises = [];
        deviceSnapshot.forEach((doc) => {
          if (doc.data().tag) {
            const array = doc.data().tag;
            array.forEach((id) => {
              const tagPromise = db.collection('tag').doc(id).get();
              tagPromises.push(tagPromise);
            });
          }
          setConnQR(doc.data().connectQR);
        });

        const tagSnapshots = await Promise.all(tagPromises);

        const tagDataPromises = tagSnapshots.map(async (snapshot) => {
          if (snapshot.exists) {
            const tagData = { id: snapshot.id, ...snapshot.data() };

            const subcollectionSnapshot = await db
              .collection('tag')
              .doc(snapshot.id)
              .collection('taskRemain')
              .get();

            const taskRemainData = subcollectionSnapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data()
            }));

            tagData.taskRemain = taskRemainData;

            return tagData;
          }
          return null;
        });

        const tagArray = (await Promise.all(tagDataPromises)).filter(data => data !== null);

        setTag(tagArray);
        setIsLoading(false);

      } catch (error) {
        setIsLoading(false);
        console.error('Error fetching tags:', error);
      }
    }

    fetchTag();
  }, [user.userId, updateTag]);

  useEffect(() => {
    getMember().then((data) => setMemberData(data))
  }, [])

  const initialConnectStatus = device?.connectStatus ?? false;
  const initialRole = memberData?.role ?? 'member';

  return (
    <>
      <div className='d-flex flex-wrap justify-content-between align-items-center mb-6'>
        <h3 className='fw-bolder my-2'>Tag {`( ${tag.length} )`}</h3>

        <div className='d-flex align-items-center my-2 gap-4'>
          {device?.connectStatus === false && (<button
            className='btn btn-primary btn-sm'
            onClick={() => setShowModalQR(true)}
          >Connect Device</button>
          )}
          <button
            className='btn btn-primary btn-sm'
            onClick={() => navigate(`/create-tag`)}
            disabled={initialConnectStatus === false || initialRole === 'member'}
          >
            <KTIcon iconName={'plus'} className='fs-2x' />
            Add tag
          </button>
        </div>
      </div>

      <div className='row g-5 g-xl-8'>
        {tag.map((item) => (
          <div key={item.id} className='col-xl-4'>
            <TagWidget
              deviceId={item.deviceId}
              tagId={item.id}
              tagName={item.tagName}
              description={item.description}
              colorTheme={item.colorTheme}
              dataFormat={item.dataFormat}
              updateTag={updateTag}
              setUpdateTag={setUpdateTag}
              lastValue={item.lastValue}
              taskRemain={item.taskRemain}
              memberData={memberData}
              tag={tag}
            />
          </div>
        ))}
      </div>

      {isLoading === true && (
        <>
          <div className='d-flex justify-content-center mt-10'>
            <span className='spinner-border' role="status"></span>
          </div>
        </>
      )}

      {tag.length === 0 && isLoading !== null && isLoading !== true && (
        <>
          <div className='d-flex justify-content-center mt-10'>
            <h4>No tag found</h4>
          </div>
        </>
      )}

      {/*           
        </div>
      </div> */}

      {/* <AddTag showModalAddTag={showModalAddTag} setShowModalAddTag={setShowModalAddTag} /> */}
      <div className='d-flex justify-content-center my-4'>
        <ScanTag device={device} toFilled={false} />
      </div>


      {/* Connect QR Modal */}
      <Modal show={showModalQR} onHide={() => setShowModalQR(false)}>
        <Modal.Header closeButton>
          <Modal.Title className='fs-2 fw-bold'>Connect to Malin1</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='text-center'>
            <img src={connQR} alt="Preview" />
            <p>Please scan the QR Code via Malin1 to add device</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setShowModalQR(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export { TagComponent }
