/* eslint-disable jsx-a11y/anchor-is-valid */

import {useUser} from '../../modules/newauth/UserContext'
import {useState, useEffect} from 'react'
import firebase from '../../firebase/FirebaseConfig'
import {useMember} from '../../modules/newauth/MemberContext'
import {useNavigate} from 'react-router-dom'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const MemberProfilePage = () => {
  const {user} = useUser()
  const {member, getMember} = useMember()
  const [memberObject, setMemberObject] = useState({})
  const db = firebase.firestore()
  const navigate = useNavigate()

  useEffect(() => {
    if (member === null || member === 'null') {
      navigate(`/select-member`)
    }
  }, [member])

  useEffect(() => {
    async function fetchMember() {
      const memberData = await getMember()
      setMemberObject(memberData)
    }
    fetchMember()
  }, [])

  // console.log(member)
  // console.log(memberObject)

  return (
    <>
      <div className='d-flex flex-wrap flex-stack mb-2'>
        <h3 className='fw-bolder my-2'>Profile</h3>
      </div>

      <div className='card card-custom'>
        <div className='card-header'>
          <h3 className='card-title'>Profile</h3>
          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-sm btn-light'
              onClick={() => navigate('/member-profile/edit')}
            >
              Edit
            </button>
          </div>
        </div>
        <div className='card-body'>
          <div className='mb-5 d-flex justify-content-center'>
            <div className='symbol symbol-100px'>
              {memberObject.imageProfileType == 'avatar' ? (
                <img alt='Pic' src={toAbsoluteUrl(`/media/avatars/${memberObject.imageAvatar}`)} />
              ) : (
                <img alt='Pic' src={memberObject.imageProfile} />
              )}
            </div>
          </div>

          <div className='mb-5 row'>
            <div>
              <label className='form-label fw-bold text-gray-600'>Name</label>
              <p className='fw-bold fs-3'>{memberObject.name}</p>
            </div>
          </div>

          <div className='mb-5 row'>
            <div>
              <label className='form-label fw-bold text-gray-600'>Description</label>
              <p className='fw-bold fs-3'>{memberObject.description}</p>
            </div>
          </div>

          <div className='mb-5 row'>
            <div className='col-6'>
              <label className='form-label fw-bold text-gray-600'>Role</label>
              <p className='fw-bold fs-3'>{capitalizeFirstLetter(memberObject.role)}</p>
            </div>
            <div className='col-6'>
              <label className='form-label fw-bold text-gray-600'> Password Enabled</label>
              <p className='fw-bold fs-3'>{memberObject.isPasswordEnabled ? 'Yes' : 'No'}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const MemberProfileWrapper = () => {
  return (
    <>
      <MemberProfilePage />
    </>
  )
}

export {MemberProfileWrapper}

function capitalizeFirstLetter(string) {
  const str = String(string)

  if (str.trim().length === 0) {
    return ''
  }

  return str.charAt(0).toUpperCase() + str.slice(1)
}
