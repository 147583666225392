
import { useState, useEffect } from "react";
import Flatpickr from 'react-flatpickr'
import clsx from "clsx";

function FormDataFormat2(props) {

    const { tagObject, onFormDataChange } = props

    const header = tagObject.header;

    const [command, setCommand] = useState('none')

    const [textOne, setTextOne] = useState('')
    const [textTwo, setTextTwo] = useState('')
    const [textThree, setTextThree] = useState('')

    const [numOne, setNumOne] = useState('')
    const [numTwo, setNumTwo] = useState('')
    const [numThree, setNumThree] = useState('')

    const initialDate = new Date();
    initialDate.setHours(0, 0, 0, 0);
    const [duedateState, setDueDateState] = useState({
        date: initialDate,
    })

    const [flowState, setFlowState] = useState("");

    const data = {
        text1: textOne,
        text2: textTwo,
        text3: textThree,
        num1: numOne,
        num2: numTwo,
        num3: numThree,
        dueDate: duedateState,
        flow: flowState,
        command: command,
    }

    useEffect(() => {
        onFormDataChange(data);
    }, [textOne, textTwo, textThree, numOne, numTwo, numThree, duedateState, flowState, command]);

    const handleDateChange = ([selectedDate]) => {
        if (selectedDate) {
            selectedDate.setHours(0, 0, 0, 0);
            setDueDateState({ date: selectedDate });
        }
    };
    const toggleFlowState = (newState) => {
        setFlowState(prevState => (prevState === newState ? "" : newState));
    };
    return (
        <>
            <div className='separator my-10'></div>
            {/*Text */}
            <div className='mb-10'>
                <label className='form-label'>{header?.text1 || "Text 1"}</label>
                <input
                    type='text'
                    className='form-control'
                    // placeholder='Text 1'
                    value={textOne}
                    onChange={(e) => setTextOne(e.target.value)}
                />
            </div>
            <div className='mb-10'>
                <label className='form-label'>{header?.text2 || "Text 2"}</label>
                <input
                    type='text'
                    className='form-control'
                    // placeholder='Text 2'
                    value={textTwo}
                    onChange={(e) => setTextTwo(e.target.value)}
                />
            </div>
            <div className='mb-10'>
                <label className='form-label'>{header?.text3 || "Text 3"}</label>
                <input
                    type='text'
                    className='form-control'
                    // placeholder='Text 3'
                    value={textThree}
                    onChange={(e) => setTextThree(e.target.value)}
                />
            </div>

            {/* due date */}
            <div className='mb-10'>
                <label className='form-label'>{header?.dueDate || "Due Date"}</label>
                <Flatpickr
                    value={duedateState.date}
                    onChange={handleDateChange}
                    options={{
                        enableTime: false,
                        dateFormat: 'Y-m-dTH:i',
                    }}
                    className='form-control form-control-solid'
                // placeholder='Pick date'
                />
            </div>

            {/* Number */}
            <div className='mb-10'>
                <label className='form-label'>{header?.num1 || "Number 1"}</label>
                <input
                    type='number'
                    className='form-control'
                    // placeholder='Number 1'
                    value={numOne}
                    onChange={(e) => setNumOne(e.target.value)}
                />
            </div>
            <div className='mb-10'>
                <label className='form-label'>{header?.num2 || "Number 2"}</label>
                <input
                    type='number'
                    className='form-control'
                    // placeholder='Number 2'

                    value={numTwo}
                    onChange={(e) => setNumTwo(e.target.value)}
                />
            </div>
            <div className='mb-10'>
                <label className='form-label'>{header?.num3 || "Number 3"}</label>
                <input
                    type='number'
                    className='form-control'
                    // placeholder='Number 3'
                    value={numThree}
                    onChange={(e) => setNumThree(e.target.value)}
                />
            </div>

            {/* flow */}
            <div className='mb-10'>
                <label htmlFor='flow' className='form-label fw-bold text-gray-600'>
                    {header?.flow || "Flow"}
                </label>
                <div id='flow' className='d-flex gap-4'>
                    <button
                        className={clsx('btn btn-secondary rounded rounded-pill', {
                            "btn-info": flowState === "start"
                        })}
                        onClick={() => toggleFlowState("start")}
                    >
                        Start
                    </button>
                    <button
                        className={clsx('btn btn-secondary rounded rounded-pill', {
                            "btn-info": flowState === "finish"
                        })}
                        onClick={() => toggleFlowState("finish")}
                    >
                        Finish
                    </button>
                </div>
            </div>

            {/* Command Section */}
            <div className='mb-10'>
                <label className='form-label'>Command</label>
                <div className='row'>
                    <button
                        className={`btn mb-3 ${command == 'none' ? 'btn-primary' : 'btn-outline btn-outline-primary'
                            } `}
                        onClick={() => setCommand('none')}
                    >
                        <span className='indicator-label'>None</span>
                    </button>

                    <button
                        className={`btn mb-3 ${command == 'condition2' ? 'btn-primary' : 'btn-outline btn-outline-primary'
                            } `}
                        onClick={() => setCommand('condition2')}
                    >
                        <span className='indicator-label'>Send with no condition.</span>
                    </button>
                    <button
                        className={`btn mb-3 ${command == 'condition3' ? 'btn-primary' : 'btn-outline btn-outline-primary'
                            } `}
                        onClick={() => setCommand('condition3')}
                    >
                        <span className='indicator-label'>Send data if all value completed</span>
                    </button>
                </div>
            </div>
        </>
    )
}

export default FormDataFormat2
