/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useMember} from '../../../modules/newauth/MemberContext'
import {useNavigate} from 'react-router-dom'
import firebase from '../../../firebase/FirebaseConfig'
import {useUser} from '../../../modules/newauth/UserContext'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faLock} from '@fortawesome/free-solid-svg-icons'

type Props = {
  color?: string
  avatar?: string
  online?: boolean
  name: string
  job: string
  role: string
  memberId: string
  isPasswordEnabled?: boolean
  item?: any
}

const Card1: FC<Props> = ({
  color = '',
  avatar = '',
  online = false,
  name,
  job,
  role,
  memberId,
  isPasswordEnabled,
  item,
}) => {
  const {user} = useUser()
  const {member, setMember} = useMember()
  const navigate = useNavigate()
  const db = firebase.firestore()
  const MySwal = withReactContent(Swal)

  const handleMember = async (memberId) => {
    const passMember = async () => {
      setMember(memberId)
      let hasDevice = false // ตั้งค่าเริ่มต้นว่าไม่มี device
      await db
        .collection('device')
        .where('owner', '==', user.userId)
        .get()
        .then((querySnapshot) => {
          if (!querySnapshot.empty) {
            hasDevice = true // ถ้าพบเอกสาร, แปลว่ามี device
          }
        })
        .catch((error) => {
          console.log('Error getting documents: ', error)
        })

      if (hasDevice) {
        // ถ้ามี device, ไปยังหน้า device
        navigate(`/device`)
      } else {
        // ถ้าไม่มี device, ไปยังหน้า create-device
        navigate(`/device`)
      }
    }

    await db
      .collection('user')
      .doc(user.docId)
      .collection('member')
      .doc(memberId)
      .get()
      .then(async (doc) => {
        if (doc.exists) {
          const data = doc.data()
          if (data) {
            if (data?.role == 'admin' && data?.isVerify == false) {
              Swal.fire({
                title: 'Please verify on owner email.',
                text: `Verify creation sent to ${user.email}`,
                icon: 'warning',
              })
              return
            }
            if (data.isPasswordEnabled) {
              console.log(data.password)

              const {value: password} = await MySwal.fire({
                title: 'Enter your password',
                input: 'password',
                inputLabel: 'Password',
                inputPlaceholder: 'Enter your password',
                inputAttributes: {
                  autocapitalize: 'off',
                  autocorrect: 'off',
                },
              })

              if (password == data.password) {
                passMember()
              } else {
                Swal.fire({
                  title: 'Wrong password.',
                  icon: 'error',
                })
              }
            } else {
              passMember()
            }
          } else {
            console.log('Data is undefined')
          }
        } else {
          console.log('No document found')
        }
      })
      .catch((error) => {
        console.log('Error getting document:', error)
      })
  }

  return (
    <div className='col-6 col-md-6 col-xxl-4' onClick={() => handleMember(memberId)}>
      <div className='card'>
        <div className='card-body d-flex flex-center flex-column p-3'>
          <div className='mb-5'>
            <div className='symbol symbol-75px symbol-circle'>
              {item.imageProfileType == 'avatar' ? (
                <img alt='Pic' src={toAbsoluteUrl(`/media/avatars/${item.imageAvatar}`)} />
              ) : (
                <img alt='Pic' src={item.imageProfile} style={{objectFit: 'contain'}} />
              )}

              {online && (
                <div className='symbol-badge bg-success start-100 top-100 border-4 h-15px w-15px ms-n3 mt-n3'></div>
              )}
            </div>
          </div>

          <a href='#' className='fs-4 text-gray-800 text-hover-primary fw-bolder mb-1'>
            {name}
          </a>

          {role == 'admin' ? (
            <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
              <span className='badge badge-light-info mb-2'>Admin</span>
              {isPasswordEnabled ? (
                <span className='badge badge-light-info mb-2'>
                  <FontAwesomeIcon icon={faLock} />
                </span>
              ) : (
                ''
              )}
            </div>
          ) : (
            <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
              <span className='badge badge-light-success mb-2'>Member</span>
              {isPasswordEnabled ? (
                <span className='badge badge-light-success mb-2'>
                  <FontAwesomeIcon icon={faLock} />
                </span>
              ) : (
                ''
              )}
            </div>
          )}

          <div className='fw-bold text-gray-400 mb-3'>{job.trim() === '' ? '-' : job}</div>

          {/* <div className='d-flex flex-center flex-wrap mb-5'>
            <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mx-3 mb-3'>
              <div className='fs-6 fw-bolder text-gray-700'>{avgEarnings}</div>
              <div className='fw-bold text-gray-400'>Avg. Earnings</div>
            </div>

            <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 mx-3 px-4 mb-3'>
              <div className='fs-6 fw-bolder text-gray-700'>{totalEarnings}</div>
              <div className='fw-bold text-gray-400'>Total Sales</div>
            </div>
          </div> */}

          {/* <a href='#' className='btn btn-sm btn-light'>
            <KTIcon iconName='plus' className='fs-3' />
            Connect
          </a> */}
        </div>
      </div>
    </div>
  )
}

export {Card1}
