/* eslint-disable jsx-a11y/anchor-is-valid */

import axios from 'axios'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { PulseLoader } from 'react-spinners'
import firebase from '../../../firebase/FirebaseConfig'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'

export function Dropdown({ obj, triggerCode, setTriggerCode, editClick }) {
  const db = firebase.firestore()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const MySwal = withReactContent(Swal)

  const handleDeleteCode = async () => {
    const codeId = obj.id
    setLoading(true)

    await db
      .collection('code')
      .doc(codeId)
      .delete()
      .then(() => {
        console.log('Document successfully deleted!')
        MySwal.fire({
          icon: 'success',
          title: 'Code deleted successfully.',
        }).then(() => {
          setLoading(false)
          setTriggerCode(!triggerCode)
        })
      })
      .catch((error) => {
        console.error('Error removing document: ', error)
        MySwal.fire({
          icon: 'error',
          title: 'Error deleting code.',
          text: error,
        })
      })
  }

  const handleDuplicate = async () => {
    const codeId = obj.id

    await db
      .collection('code')
      .doc(codeId)
      .get()
      .then(async (doc) => {
        if (doc.exists) {
          const timestampInSecond = Math.floor(Date.now() / 1000)
          let data = doc.data()
          data.created = timestampInSecond

          await db
            .collection('code')
            .add(data)
            .then((docRef) => {
              console.log('Document written with ID: ', docRef.id)
              MySwal.fire({
                icon: 'success',
                title: 'Code duplicated successfully.',
              }).then(() => {
                setTriggerCode(!triggerCode)
              })
            })
            .catch((error) => {
              console.error('Error adding document: ', error)
              MySwal.fire({
                icon: 'error',
                title: 'Error duplicating code.',
                text: error,
              })
            })
        } else {
          MySwal.fire({
            icon: 'error',
            title: 'Code not found.',
          })
        }
      })
      .catch((error) => {
        console.log('Error getting document:', error)
        MySwal.fire({
          icon: 'error',
          title: 'Error duplicating code.',
          text: error,
        })
      })
  }

  const handleEdit = () => {
    editClick(true)
  }

  return (
    <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bolder'>Code Options</div>
        <div className='fs-8 text-dark'>Code : {obj.text1}</div>
      </div>

      <div className='separator border-gray-200'></div>

      <div className='px-7 py-5'>
        <button className='btn btn-primary btn-sm my-1 w-100' onClick={() => handleEdit()}>
          Edit
        </button>

        <button className='btn btn-primary btn-sm my-1 w-100' onClick={() => handleDuplicate()}>
          Duplicate
        </button>

        <button className='btn btn-danger btn-sm my-1 col-12' onClick={() => handleDeleteCode()}>
          {loading ? (
            <PulseLoader color='#fff' size={8} margin={2} />
          ) : (
            <span className='indicator-label'>Delete</span>
          )}
        </button>
      </div>
    </div >
  )
}
