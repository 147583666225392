import { useState, useEffect } from 'react'
import Flatpickr from 'react-flatpickr'
import clsx from 'clsx'

export const DataFormat1Comp = ({ tag, qrCodeData, tagId, formData, date, emptyList }) => {
    const [textOne, setTextOne] = useState(qrCodeData?.text1 || "")

    const [dateState, setDateState] = useState({
        date: new Date(),
    })

    useEffect(() => {
        date(dateState)
    }, [dateState])

    useEffect(() => {
        const data = {
            text1: textOne
        }
        formData(data)
    }, [textOne])

    return (
        <>
            <div className='mb-5'>
                <span
                    style={{
                        width: '15px',
                        height: '15px',
                        backgroundColor: tag.colorTheme,
                        display: 'inline-block',
                    }}
                    className='bullet bullet-dot me-3'
                ></span>
                <span className='fw-bold fs-2x'>{capitalizeFirstLetter(tag.tagName)}</span>
            </div>

            <div className='mb-5 row'>
                <div className='col-6'>
                    <p className='fw-bold fs-3'>{capitalizeFirstLetter(tag.description)}</p>
                </div>
            </div>
            <div className='separator my-5'></div>

            <div className='mb-5'>
                <label htmlFor='time' className='form-label fw-bold text-gray-600'>
                    Time
                </label>
                <div className='d-flex align-items-center'>
                    <Flatpickr
                        value={dateState.date}
                        onChange={([date]) => setDateState({ date })}
                        options={{
                            enableTime: true,
                            dateFormat: 'Y-m-dTH:i',
                        }}
                        className='form-control form-control-solid'
                    // placeholder='Pick date'
                    />
                    <i className="ki-duotone ki-arrows-circle mx-2 fs-1"
                        onClick={() => setDateState({ date: new Date() })}>
                        <span className="path1"></span>
                        <span className="path2"></span>
                    </i>
                </div>
            </div>
            {/* text */}
            <div className='mb-5'>
                <label htmlFor='text1' className='form-label fw-bold text-gray-600'>
                    {tag?.header?.text1 || "Text 1"}
                </label>
                <input
                    id='text1'
                    type='text'
                    className={clsx('form-control border', { 'border-danger': emptyList?.text1 })}
                    // placeholder='Text 1'
                    value={textOne}
                    onChange={(e) => setTextOne(e.target.value)}
                />
            </div>
        </>
    )
}

function capitalizeFirstLetter(string) {
    const str = String(string)

    if (str.trim().length === 0) {
        return ''
    }

    return str.charAt(0).toUpperCase() + str.slice(1)
}