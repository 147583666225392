/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTIcon} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to='/device'
        icon='monitor-mobile'
        title='Device'
        fontIcon='bi-app-indicator'
      />

      <SidebarMenuItem
        to='/code'
        icon='scan-barcode'
        title='Code Generator'
        fontIcon='bi-app-indicator'
      />

      <SidebarMenuItem
        to='/code-preview'
        icon='element-10'
        title='Code Preview'
        fontIcon='bi-app-indicator'
      />

      <SidebarMenuItem
        to='/setting'
        icon='setting-2'
        title='Setting'
        fontIcon='bi-app-indicator'
      />

      <SidebarMenuItem
        to='/switch-account'
        icon='arrows-circle'
        title='Switch Account'
        fontIcon='bi-app-indicator'
      />

      <SidebarMenuItem
        to='/logout'
        icon='cross-circle'
        title='Sign Out'
        fontIcon='bi-app-indicator'
      />
    </>
  )
}

export {SidebarMenuMain}
