import { useEffect, useState } from 'react'
import { useMember } from '../../modules/newauth/MemberContext'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { useNavigate } from 'react-router-dom'
import firebase from '../../firebase/FirebaseConfig'
import { useUser } from '../../modules/newauth/UserContext'
import { PulseLoader } from 'react-spinners'
import clsx from 'clsx'
import MemberSetting from './MemberSetting'
import Swal from 'sweetalert2'

export default function AccountSetting() {

    const { member, getMember } = useMember();
    const { user } = useUser();

    const [selectedRadio, setSelectedRadio] = useState('avatar');
    const [selectedAvatar, setSelectedAvatar] = useState('300-1.jpg');
    const [name, setName] = useState(' ');
    const [description, setDescription] = useState('');
    const [role, setRole] = useState('');
    const [pass, setPass] = useState('');
    const [enablePass, setEnablePass] = useState(false);
    const [imageFile, setImageFile] = useState(null);

    const [loading, setLoading] = useState(false);
    const [iscomplete, setIsComplete] = useState(false);
    const [isError, setIserror] = useState(false);

    const navigate = useNavigate();

    const db = firebase.firestore();

    const handleRadioChange = (event) => {
        setSelectedRadio(event.target.value);
    };
    const avatars = [
        '300-1.jpg',
        '300-2.jpg',
        '300-3.jpg',
        '300-4.jpg',
        '300-5.jpg',
        '300-6.jpg',
        '300-7.jpg',
        '300-8.jpg',
        '300-9.jpg',
        '300-10.jpg',
        '300-11.jpg',
    ]

    useEffect(() => {
        if (member === null || member === 'null') {
            navigate(`/select-member`)
        }
    }, [member])

    useEffect(() => {
        async function getMemberData() {
            await getMember().then((data) => {
                setName(data?.name)
                setDescription(data?.description)
                setSelectedAvatar(data?.imageAvatar)
                setRole(data?.role)
                setPass(data?.password)
                setSelectedRadio(data?.imageProfileType)
                setEnablePass(data?.isPasswordEnabled)
            });
        }
        getMemberData()
    }, [member]);

    function handelError() {
        setLoading(false);
        setIserror(true);
        setIsComplete(false)
        setTimeout(() => {
            setIserror(false)
        }, 1000);
    }

    const handleSave = () => {
        setLoading(true)
        setIsComplete(false)
        setIserror(false)
        async function updateMember() {
            try {
                if (name && name.trim() !== '') {
                    setLoading(true);

                    let data = {
                        description: description,
                        imageAvatar: selectedAvatar,
                        imageProfileType: selectedRadio,
                        isPasswordEnabled: enablePass,
                        name: name,
                        password: pass,
                        role: role,
                        // isVerify: true
                    }

                    if (selectedRadio == 'upload') {
                        if (imageFile == null) {
                            Swal.fire({
                                title: 'Please select image file.',
                                icon: 'error',
                            })
                            setLoading(false)
                            return
                        }
                        let imageUploadResult = null
                        if (imageFile) {
                            imageUploadResult = await uploadImageToStorage(imageFile)
                        }
                        data.imageProfile = imageUploadResult.downloadURL
                        data.imageFileName = imageUploadResult.fileName
                    } else if (selectedRadio == 'avatar') {
                        data.imageAvatar = selectedAvatar
                    }

                    await db.collection('user')
                        .doc(user.docId)
                        .collection("member")
                        .doc(member)
                        .update(data)
                        .then(() => {
                            setLoading(false)
                            setIsComplete(true)
                            setTimeout(() => {
                                setIsComplete(false)
                            }, 1000);
                        })
                } else {
                    handelError();
                }
            } catch (error) {
                handelError();
                console.error("Unable to update member profile", error);
            }
        }
        updateMember()
    }

    const uploadImageToStorage = (file) => {
        const timestampInSecond = Math.floor(Date.now() / 1000)
        const storageRef = firebase.storage().ref()
        const fileName = `${timestampInSecond}-${file.name}`
        const uploadTask = storageRef.child(`images/${fileName}`).put(file)

        return new Promise((resolve, reject) => {
            uploadTask.on(
                'state_changed',
                (snapshot) => {
                    // Handle progress
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    console.log('Upload is ' + progress + '% done')
                },
                (error) => {
                    // Handle unsuccessful uploads
                    reject(error)
                },
                () => {
                    // Handle successful uploads on complete
                    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                        resolve({ downloadURL, fileName: fileName })
                    })
                }
            )
        })
    }

    return (

        <div className="card card-custom shadow">
            <div className="card-header card-header-stretch">
                <h3 className="card-title">Setting</h3>
                <div className="card-toolbar">
                    <ul className="nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0">
                        <li className="nav-item">
                            <a
                                className="nav-link active"
                                data-bs-toggle="tab"
                                href="#profile-tab"
                            >
                                Profile
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className="nav-link"
                                data-bs-toggle="tab"
                                href="#member-tab"
                            >
                                Member
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="card-body">
                <div className="tab-content" id="myTabContent">
                    <div
                        className="tab-pane fade show active"
                        id="profile-tab"
                        role="tabpanel"
                    >
                        <div className='mb-5'>
                            <label htmlFor="name">Name</label>
                            <input type="text"
                                className={clsx('form-control border', { 'border-danger': name ? name.trim() === '' : true })}
                                id='name'
                                value={name}
                                onChange={(e) => setName(e.target.value)} />
                        </div>
                        <div className='mb-5'>
                            <label htmlFor="description">Description</label>
                            <input type="text" className='form-control' id='description' value={description} onChange={(e) => setDescription(e.target.value)} />
                        </div>
                        <div className='mb-5'>
                            <label htmlFor="role">Role</label>
                            <select name="role-selection" className='form-select' id="role" value={role} onChange={(e) => setRole(e.target.value)} disabled>
                                <option value="admin">Admin</option>
                                <option value="member">Member</option>
                            </select>
                        </div>
                        <div className='mb-5'>
                            <div className="form-check form-switch form-check-custom form-check-solid">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="enb-password"
                                    checked={enablePass}
                                    onChange={() => setEnablePass(!enablePass)}
                                />
                                <label className="form-check-label" htmlFor="enb-password">
                                    Enable Password
                                </label>
                            </div>
                            {enablePass && (
                                <div>
                                    <label htmlFor="password">Password</label>
                                    <input
                                        type="password"
                                        className='form-control'
                                        id='password'
                                        value={pass}
                                        onChange={(e) => setPass(e.target.value)}
                                    />
                                </div>
                            )}
                        </div>

                        <div className='mb-5'>
                            <div>Icon</div>
                            <div className="form-check form-check-custom form-check-solid my-1">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    value="avatar"
                                    id="avatar"
                                    checked={selectedRadio === 'avatar'}
                                    onChange={handleRadioChange}
                                />
                                <label className="form-check-label" htmlFor="avatar">
                                    Avatar
                                </label>
                            </div>

                            <div className="form-check form-check-custom form-check-solid my-1">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    value="upload"
                                    id="upload"
                                    checked={selectedRadio === 'upload'}
                                    onChange={handleRadioChange}
                                />
                                <label className="form-check-label" htmlFor="upload">
                                    Upload
                                </label>
                            </div>
                        </div>
                        {selectedRadio === 'avatar' ? (
                            <div className='d-flex justify-content-center flex-wrap'>
                                {avatars.map((avatar, index) => (
                                    <div key={index} className='p-2'>
                                        <input
                                            type='radio'
                                            name='avatar'
                                            id={`avatar${index}`}
                                            value={avatar}
                                            onChange={() => setSelectedAvatar(avatar)}
                                            checked={selectedAvatar === avatar}
                                            className='d-none'
                                        />
                                        <label
                                            htmlFor={`avatar${index}`}
                                            className={`symbol symbol-55px symbol-circle ${selectedAvatar === avatar ? 'selected-avatar' : ''
                                                }`}
                                            style={{
                                                boxShadow:
                                                    selectedAvatar === avatar ? '0 0 0 5px rgba(50,150,250,0.8)' : 'none',
                                                cursor: 'pointer',
                                                transition: 'box-shadow 0.3s ease',
                                            }}
                                        >
                                            <img
                                                alt={`Avatar ${index}`}
                                                src={toAbsoluteUrl(`/media/avatars/${avatar}`)}
                                            />
                                        </label>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className='mb-10'>
                                <label htmlFor='imageFile' className='form-label'>
                                    Upload Image
                                </label>
                                <input
                                    id='imageFile'
                                    type='file'
                                    accept='image/*' // Accept only image files
                                    className='form-control'
                                    onChange={(e) => setImageFile(e.target.files[0])} // Set the selected file to state
                                />
                            </div>
                        )}
                        <div className='btn-container text-center mt-5'>
                            <button className={clsx('btn btn-primary', {
                                'btn-danger': isError,
                                'btn-success': iscomplete
                            })} onClick={handleSave}>
                                {loading ? (
                                    <PulseLoader color='#fff' size={8} margin={2} />) :
                                    (
                                        <>
                                            <span className={clsx("d-block", {
                                                'd-none': iscomplete || isError
                                            })}>Save</span>

                                            <span className={clsx("d-block", {
                                                'd-none': !iscomplete
                                            })}>Saved</span>

                                            {isError && !iscomplete && (
                                                <>
                                                    <div>
                                                        Save Failed
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    )}
                            </button>
                        </div>
                    </div>
                    <div
                        className="tab-pane fade"
                        id="member-tab"
                        role="tabpanel"
                    >
                        <MemberSetting
                            user={user}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
