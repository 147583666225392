/* eslint-disable jsx-a11y/anchor-is-valid */

import { useUser } from '../../modules/newauth/UserContext'
import { useState, useEffect } from 'react'
import firebase from '../../firebase/FirebaseConfig'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { PulseLoader } from 'react-spinners'

import AdminVerify from './verify/AdminVerify'


const CreateMemberPage = () => {
  const { user } = useUser()
  const db = firebase.firestore()
  const navigate = useNavigate()
  const [passwordEnabled, setPasswordEnabled] = useState(false)
  const [iconType, setIconType] = useState('avatar')
  const [selectedAvatar, setSelectedAvatar] = useState('300-1.jpg')
  const [imageFile, setImageFile] = useState(null)
  const [loading, setLoading] = useState(false)

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    description: Yup.string().required('Description is required'),
    role: Yup.string().required('Role is required'),
    password: passwordEnabled ? Yup.string().required('Password is required') : Yup.string(),
  })

  // เริ่มต้นใช้งาน Formik
  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      role: 'admin',
      password: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      const MySwal = withReactContent(Swal)
      setLoading(true)

      let data = {
        name: values.name,
        description: values.description,
        permission: {},
        role: values.role,
        isPasswordEnabled: passwordEnabled,
        password: values.password,
        imageProfileType: iconType,
      }

      if (iconType == 'upload') {
        if (imageFile == null) {
          MySwal.fire({
            title: 'Please select image file.',
            icon: 'error',
          })
          setLoading(false)
          return
        }
        let imageUploadResult = null
        if (imageFile) {
          imageUploadResult = await uploadImageToStorage(imageFile)
        }
        data.imageProfile = imageUploadResult.downloadURL
        data.imageFileName = imageUploadResult.fileName
      } else if (iconType == 'avatar') {
        data.imageAvatar = selectedAvatar
      }

      await db
        .collection('user')
        .doc(user.docId)
        .collection('member')
        .add(data)
        .then(async (docRef) => {
          const memberId = docRef.id;

          return db.collection('user')
            .doc(user.docId)
            .collection('member')
            .doc(memberId)
            .update({ memberId: memberId, isVerify: false })
            .then(async () => {
              if (values.role == 'admin') {
                await AdminVerify(values, user, memberId);
                MySwal.fire({
                  title: 'Please verify on owner email.',
                  text:`Verify creation sent to ${user.email}`,
                  icon: 'success',
                }).then((result) => {
                  window.location.href = '/select-member'
                });
              } else {
                MySwal.fire({
                  title: 'Create new member complete.',
                  icon: 'success',
                }).then((result) => {
                  window.location.href = '/select-member'
                });
              }
            })
        })
        .catch((error) => {
          console.error('Error adding document: ', error)
          MySwal.fire({
            title: 'Create new member not complete.',
            text: error,
            icon: 'error',
          })
          setLoading(false)
        })
    },
  })

  const avatars = [
    '300-1.jpg',
    '300-2.jpg',
    '300-3.jpg',
    '300-4.jpg',
    '300-5.jpg',
    '300-6.jpg',
    '300-7.jpg',
    '300-8.jpg',
    '300-9.jpg',
    '300-10.jpg',
    '300-11.jpg',
  ]

  const uploadImageToStorage = (file) => {
    const timestampInSecond = Math.floor(Date.now() / 1000)
    const storageRef = firebase.storage().ref()
    const fileName = `${timestampInSecond}-${file.name}`
    const uploadTask = storageRef.child(`images/${fileName}`).put(file)

    return new Promise((resolve, reject) => {
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Handle progress
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log('Upload is ' + progress + '% done')
        },
        (error) => {
          // Handle unsuccessful uploads
          reject(error)
        },
        () => {
          // Handle successful uploads on complete
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            resolve({ downloadURL, fileName: fileName })
          })
        }
      )
    })
  }

  return (
    <>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2'>Create member</h3>
      </div>

      <div className='card card-custom'>

        <form onSubmit={formik.handleSubmit}>
          {/* แก้ไขส่วนของฟอร์มเพื่อใช้ formik */}
          <div className='card-body'>
            {formik.touched.name && formik.errors.name ? (
              <div className='alert alert-danger d-flex align-items-center p-5 mb-3'>
                <div className='d-flex flex-column'>
                  <span>{formik.errors.name}</span>
                </div>
              </div>
            ) : null}

            {formik.touched.description && formik.errors.description ? (
              <div className='alert alert-danger d-flex align-items-center p-5 mb-3'>
                <div className='d-flex flex-column'>
                  <span>{formik.errors.description}</span>
                </div>
              </div>
            ) : null}

            {formik.touched.password && formik.errors.password ? (
              <div className='alert alert-danger d-flex align-items-center p-5 mb-3'>
                <div className='d-flex flex-column'>
                  <span>{formik.errors.password}</span>
                </div>
              </div>
            ) : null}

            <div className='mb-10'>
              <label htmlFor='name' className='form-label'>
                Name
              </label>
              <input
                id='name'
                type='text'
                className='form-control'
                // placeholder='Name'
                {...formik.getFieldProps('name')}
              />
            </div>

            <div className='mb-10'>
              <label htmlFor='description' className='form-label'>
                Description
              </label>
              <input
                id='description'
                type='text'
                className='form-control'
                // placeholder='Description'
                {...formik.getFieldProps('description')}
              />
            </div>

            <div className='mb-10'>
              <label htmlFor='role' className='form-label'>
                Roles
              </label>
              <select
                id='role'
                className='form-select'
                {...formik.getFieldProps('role')}
                aria-label='Select role'
              >
                <option value='member'>Member</option>
                <option value='admin'>Admin</option>
              </select>
            </div>

            <div className='form-check form-switch form-check-custom form-check-solid mb-5'>
              <input
                className='form-check-input'
                type='checkbox'
                id='passwordEnableSwitch'
                checked={passwordEnabled}
                onChange={(e) => setPasswordEnabled(e.target.checked)}
              />
              <label className='form-check-label' htmlFor='passwordEnableSwitch'>
                Enable password
              </label>
            </div>

            {passwordEnabled && (
              <div className='mb-10'>
                <label htmlFor='password' className='form-label'>
                  Password
                </label>
                <input
                  id='password'
                  type='password' // Changed to type password for security
                  className='form-control'
                  placeholder='Password'
                  {...formik.getFieldProps('password')}
                />
              </div>
            )}

            <div className='mb-10'>
              <label htmlFor='icon' className='form-label'>
                Icon
              </label>
              <div className='mb-2'>
                <div className='form-check form-check-custom form-check-solid'>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='icon'
                    value='avatar'
                    id='iconAvatar'
                    checked={iconType === 'avatar'}
                    onChange={() => setIconType('avatar')}
                  />
                  <label className='form-check-label text-gray-800' htmlFor='iconAvatar'>
                    Avatar
                  </label>
                </div>
              </div>
              <div className='mb-0'>
                <div className='form-check form-check-custom form-check-solid'>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='icon'
                    value='upload'
                    id='iconUpload'
                    checked={iconType === 'upload'}
                    onChange={() => setIconType('upload')}
                  />
                  <label className='form-check-label text-gray-800' htmlFor='iconUpload'>
                    Upload
                  </label>
                </div>
              </div>
            </div>

            {iconType === 'avatar' ? (
              <div className='mb-10'>
                <label htmlFor='icon' className='form-label'>
                  Avatar
                </label>
                <div className='d-flex justify-content-center flex-wrap'>
                  {avatars.map((avatar, index) => (
                    <div key={index} className='p-2'>
                      <input
                        type='radio'
                        name='avatar'
                        id={`avatar${index}`}
                        value={avatar}
                        onChange={() => setSelectedAvatar(avatar)}
                        checked={selectedAvatar === avatar}
                        className='d-none' // Hide the default radio button
                      />
                      <label
                        htmlFor={`avatar${index}`}
                        className={`symbol symbol-55px symbol-circle ${selectedAvatar === avatar ? 'selected-avatar' : ''
                          }`}
                        style={{
                          boxShadow:
                            selectedAvatar === avatar ? '0 0 0 5px rgba(50,150,250,0.8)' : 'none', // Apply a shadow if selected
                          cursor: 'pointer', // Change cursor to pointer to indicate it's selectable
                          transition: 'box-shadow 0.3s ease', // Smooth transition for the box shadow
                        }}
                      >
                        <img
                          alt={`Avatar ${index}`}
                          src={toAbsoluteUrl(`/media/avatars/${avatar}`)}
                        />
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              // Input select image file
              <div className='mb-10'>
                <label htmlFor='imageFile' className='form-label'>
                  Upload Image
                </label>
                <input
                  id='imageFile'
                  type='file'
                  accept='image/*' // Accept only image files
                  className='form-control'
                  onChange={(e) => setImageFile(e.target.files[0])} // Set the selected file to state
                />
              </div>
            )}
          </div>
          <div
            className='card-footer'
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <button type='submit' className='btn btn-md btn-primary'>
              {loading ? (
                <PulseLoader color='#fff' size={8} margin={2} />
              ) : (
                <span className='indicator-label'>Create member</span>
              )}
            </button>

            <button
              type='button'
              className='btn btn-md btn-light'
              onClick={() => navigate('/select-member')}
              style={{ marginLeft: '10px' }}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

const CreateMemberWrapper = () => {
  //   const intl = useIntl()
  return (
    <>
      {/* <PageTitle breadcrumbs={[]}>{'Select member'}</PageTitle> */}
      <CreateMemberPage />
    </>
  )
}

export { CreateMemberWrapper }
