import React, { createContext, useContext, useState, useEffect } from 'react'
import firebase from '../../firebase/FirebaseConfig'

const firestore = firebase.firestore()
const MemberContext = createContext()

export function useMember() {
    return useContext(MemberContext)
}

export function MemberProvider({ children }) {
    const [member, setMember] = useState(sessionStorage.getItem('member') || 'null');

    useEffect(() => {
        sessionStorage.setItem('member', member);
    }, [member]);

    const getMember = async () => {
        try {
            const user = await firebase.auth().currentUser;
            if (user) {
                const uid = user.uid;
                const userRef = firestore.collection("user").where("userId", "==", uid);
                const querySnapshot = await userRef.get();
                for (const doc of querySnapshot.docs) {
                    const doc2 = await firestore.collection("user").doc(doc.id).collection("member").doc(member).get();
                    if (doc2.exists) {
                        return doc2.data();
                    } else {
                        // console.log("No such document!");
                        return null; // or return some default value
                    }
                }
            } else {
                return null; // or return some default value
            }
        } catch (error) {
            // console.error('Error signing out:', error);
            return null; // or return some default value
        }
    };

    return (
        <MemberContext.Provider value={{ member, setMember, getMember }}>
            {children}
        </MemberContext.Provider>
    );
}
