/* eslint-disable jsx-a11y/anchor-is-valid */

import {useUser} from '../../modules/newauth/UserContext'
import {useState, useEffect} from 'react'
import firebase from '../../firebase/FirebaseConfig'
import {useMember} from '../../modules/newauth/MemberContext'
import {useNavigate} from 'react-router-dom'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {useParams} from 'react-router-dom'
import 'flatpickr/dist/themes/material_green.css'
import {KTIcon} from '../../../_metronic/helpers'
import {CirclePicker} from 'react-color'
import {PulseLoader} from 'react-spinners'

const TagDetailPage = () => {
  const {user} = useUser()
  const {member} = useMember()
  const db = firebase.firestore()
  const navigate = useNavigate()
  const MySwal = withReactContent(Swal)
  const {deviceId, tagId} = useParams()

  const [background, setBackground] = useState('#f44336')
  const availableColors = ['#f44336', '#2196f3', '#4caf50', '#ffeb3b', '#ff9800']

  const [loading, setLoading] = useState(false)
  const [tagData, setTagData] = useState({})

  const handleChangeComplete = (color) => {
    setBackground(color.hex)
  }

  useEffect(() => {
    if (member === null || member === 'null') {
      navigate(`/select-member`)
    }
  }, [member])

  const handleNavigate = (path) => {
    navigate(path)
  }

  useEffect(() => {
    async function fetchTagData() {
      await db
        .collection('device')
        .doc(deviceId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            if (user.userId !== doc.data().owner) {
              MySwal.fire({
                title: 'You are not the owner of this device.',
                icon: 'error',
              }).then((result) => {
                navigate('/device')
              })
            }
          } else {
            MySwal.fire({
              title: 'Device not found.',
              icon: 'error',
            }).then((result) => {
              navigate('/device')
            })
          }
        })
        .catch((error) => {
          console.log('Error getting document:', error)
        })

      await db
        .collection('tag')
        .doc(tagId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            if (user.userId !== doc.data().owner) {
              MySwal.fire({
                title: 'You are not the owner of this tag.',
                icon: 'error',
              }).then((result) => {
                navigate('/device')
              })
            } else {
              setTagData({id: doc.id, ...doc.data()})
            }
          } else {
            MySwal.fire({
              title: 'Tag not found.',
              icon: 'error',
            }).then((result) => {
              navigate('/device')
            })
          }
        })
        .catch((error) => {
          console.log('Error getting document:', error)
        })
    }
    fetchTagData()
  }, [tagId])

  const handleEditTag = () => {
    navigate(`/device/${deviceId}/tag/${tagId}/edit`)
  }

  return (
    <>
      <div className='d-flex justify-content-between align-items-center mb-6'>
        <div className='d-flex align-items-center' onClick={() => handleNavigate('/device')}>
          <KTIcon iconName={'left-square'} className='fs-3x me-3' />
          <h3 className='fw-bolder my-2 ms-2'>Tag detail</h3>
        </div>
        {/* <ol className='breadcrumb text-muted fs-6 fw-bold'>
          <li className='breadcrumb-item' onClick={() => handleNavigate('/device')}>
            <span style={{cursor: 'pointer'}}>Device</span>
          </li>
          <li className='breadcrumb-item text-muted'>Detail</li>
        </ol> */}
      </div>

      <div className='card card-custom'>
        <div className='card-header'>
          <h3 className='card-title'>Tag detail</h3>
        </div>

        <div className='card-body'>
          <div className='mb-10'>
            <div className='form-label fw-bold text-gray-600'>Tag name</div>
            <span className='fw-bold fs-2x'>{capitalizeFirstLetter(tagData.tagName)}</span>
          </div>

          <div className='row mb-10'>
            <div className='col-6'>
              <div className='form-label fw-bold text-gray-600'>Tag description</div>
              <span className='fw-bold fs-2x'>{capitalizeFirstLetter(tagData.description)}</span>
            </div>
            <div className='col-6'>
              <div className='form-label fw-bold text-gray-600'>Data format</div>
              <span className='fw-bold fs-2x'>{capitalizeFirstLetter(tagData.dataFormat)}</span>
            </div>
          </div>

          <div className='row mb-5'>
            <div className='col-6'>
              <div className='form-label fw-bold text-gray-600'>Color theme</div>
              <span
                style={{
                  width: '25px',
                  height: '25px',
                  backgroundColor: tagData.colorTheme,
                  display: 'inline-block',
                }}
                className='bullet bullet-dot me-3'
              ></span>
            </div>
            <div className='col-6'>
              <div className='form-label fw-bold text-gray-600'>Create date</div>
              <span className='fw-bold fs-2x'>
                {capitalizeFirstLetter(formatDate(tagData.createDate))}
              </span>
            </div>
          </div>
        </div>
        <div
          className='card-footer'
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <button type='button' className='btn btn-md btn-primary' onClick={() => handleEditTag()}>
            <span className='indicator-label'>Edit tag</span>
          </button>

          <button
            type='button'
            className='btn btn-md btn-light'
            onClick={() => navigate('/device')}
            style={{marginLeft: '10px'}}
          >
            Back
          </button>
        </div>
      </div>
    </>
  )
}

const TagDetailWrapper = () => {
  return (
    <>
      <TagDetailPage />
    </>
  )
}

export {TagDetailWrapper}

function capitalizeFirstLetter(string) {
  const str = String(string)
  if (str.trim().length === 0) {
    return ''
  }
  return str.charAt(0).toUpperCase() + str.slice(1)
}

function formatDate(timestamp) {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  const date = new Date(timestamp * 1000)

  const day = date.getDate()
  const month = months[date.getMonth()]
  const year = date.getFullYear()
  const hours = date.getHours()
  const minutes = date.getMinutes()

  const formattedDate = `${day}-${month}-${year} ${hours.toString().padStart(2, '0')}.${minutes
    .toString()
    .padStart(2, '0')}`
  return formattedDate
}
