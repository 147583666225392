import formatDate from "../../modules/utils/FormatDate";

export const downloadQRCode = (filename, codeData, tagData) => {
    const qrCanvas = document.getElementById('qrCodeEl');
    const qrCodeWidth = qrCanvas.width;
    const qrCodeHeight = qrCanvas.height;
    const borderWidth = 20;
    const textPadding = 10;
    const textFontSize = 16;
    const lineHeight = textFontSize + 4;

    const text = `Tag name : ${tagData.tagName}\nCreated : ${formatDate(codeData.created)}\nCommand : ${codeData.command}`
    const lines = text.split('\n');

    const canvasWithBorder = document.createElement('canvas');
    const ctx = canvasWithBorder.getContext('2d');

    const textHeight = lines.length * lineHeight + textPadding;

    canvasWithBorder.width = qrCodeWidth + borderWidth * 2;
    canvasWithBorder.height = qrCodeHeight + borderWidth * 2 + textHeight;

    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, canvasWithBorder.width, canvasWithBorder.height);

    ctx.drawImage(qrCanvas, borderWidth, borderWidth);

    if (text) {
        ctx.fillStyle = 'black';
        ctx.textAlign = 'center';
        ctx.font = `${textFontSize}px Arial`;
        lines.forEach((line, index) => {
            ctx.fillText(
                line,
                canvasWithBorder.width / 2,
                borderWidth + qrCodeHeight + textPadding + (index + 1) * lineHeight
            );
        });
    }

    const qrCodeURL = canvasWithBorder
        .toDataURL('image/png')
        .replace('image/png', 'image/octet-stream');

    const aEl = document.createElement('a');
    aEl.href = qrCodeURL;
    aEl.download = `${filename}.png`;
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
};
