import { useState, useEffect } from 'react'

function EditFormFormat1({ codeData, tagData, handleFormChange }) {
    // console.log(codeData);
    // console.log(tagData);

    const [command, setCommand] = useState(codeData.command || 'none')
    const [textOne, setTextOne] = useState(codeData.text1 || '')
    const [note, setNote] = useState('')


    useEffect(() => {
        const data = {
            command: command,
            text1: textOne,
        }
        handleFormChange(data)
    }, [command, textOne])


    return (
        <>
            <div className='mb-10'>
                <label className='form-label'>{tagData?.header?.text1 || "Text 1"}</label>
                <input
                    type='text'
                    className='form-control'
                    value={textOne}
                    onChange={(e) => setTextOne(e.target.value)}
                // placeholder='Text 1'
                />
            </div>

            {/* <div className='mb-10'>
                <label className='form-label'>Note</label>
                <input
                    type='text'
                    className='form-control'
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                    placeholder='Note'
                />
            </div> */}

            {/* Command Section */}
            <div className='mb-10'>
                <label className='form-label'>Command</label>
                <div className='row'>
                    <button
                        className={`btn mb-3 ${command == 'none' ? 'btn-primary' : 'btn-outline btn-outline-primary'
                            } `}
                        onClick={() => setCommand('none')}
                    >
                        <span className='indicator-label'>None</span>
                    </button>

                    <button
                        className={`btn mb-3 ${command == 'condition2' ? 'btn-primary' : 'btn-outline btn-outline-primary'
                            } `}
                        onClick={() => setCommand('condition2')}
                    >
                        <span className='indicator-label'>Send with no condition.</span>
                    </button>
                    <button
                        className={`btn mb-3 ${command == 'condition3' ? 'btn-primary' : 'btn-outline btn-outline-primary'
                            } `}
                        onClick={() => setCommand('condition3')}
                    >
                        <span className='indicator-label'>Send data if all value completed</span>
                    </button>
                </div>
            </div>
        </>
    )
}

export default EditFormFormat1
