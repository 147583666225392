import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import FirebaseAuthService from '../../firebase/FirebaseAuthService'
import firebase from '../../firebase/FirebaseConfig'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import { useState } from 'react'
// import {useUser} from './UserContext' // Import the useUser hook
import { Link } from 'react-router-dom'
// import CryptoJS from 'crypto-js' // Import crypto-js for encryption
import { PulseLoader } from 'react-spinners'
import { useEffect } from 'react'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import clsx from 'clsx'

const firestore = firebase.firestore()

export function Login() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(null)
  // const {user, setUser} = useUser() // Access user and setUser from context
  const [loading, setLoading] = useState(false)

  const auth = getAuth()

  const handleSubmit = (e) => {
    // e.preventDefault()
    e.preventDefault()
    setLoading(true)

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => { })
      .catch((error) => {
        const errorMessage = error.message
        console.log(errorMessage)
        setError(errorMessage)
      })
      .finally(() => {
        setLoading(false)
      })
  }
  return (
    <form onSubmit={handleSubmit} className='form w-100'>
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Sign In</h1>
      </div>

      {error ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{error}</div>
        </div>
      ) : (
        ''
      )}
      {/* <div className='mb-10 bg-light-info p-8 rounded'>
        <div className='text-info'>
          Use account <strong>admin@demo.com</strong> and password <strong>demo</strong> to
          continue.
        </div>
      </div> */}

      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          placeholder='Email'
          className={clsx('form-control bg-transparent')}
          type='email'
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        // name='email'
        // autoComplete='off'
        />
        {/* {formik.touched.email && formik.errors.email && ( */}
        {/* <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )} */}
      </div>

      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
        <input
          placeholder='Password'
          type='password'
          // autoComplete='off'
          className={clsx('form-control bg-transparent')}
          onChange={(e) => setPassword(e.target.value)}
          value={password}
        />
        {/* {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )} */}
      </div>

      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />
        {/* <Link to='/auth/forgot-password' className='link-primary'>
          Forgot Password ?
        </Link> */}
      </div>

      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          onClick={(e) => handleSubmit(e)}
          className='btn btn-primary'
        >
          {loading ? (
            <PulseLoader color='#fff' size={8} margin={2} />
          ) : (
            <span className='indicator-label'>Continue</span>
          )}
        </button>
      </div>

      {/* <div className='text-gray-500 text-center fw-semibold fs-6'>
        Not a Member yet?{' '}
        <Link to='/auth/registration' className='link-primary'>
          Sign up
        </Link>
      </div> */}
    </form>
  )
}
