import { useMember } from '../../modules/newauth/MemberContext'
import { useNavigate } from 'react-router-dom'
import { useEffect } from "react";

const SwitchAccountPage = () => {
  const { member, setMember } = useMember()
  const navigate = useNavigate()

  useEffect(() => {
    navigate(`/select-member`)
    setMember(null)
  }, [])

  return <></>
}

export { SwitchAccountPage }
