import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import firebase from '../../../firebase/FirebaseConfig';

const ConfirmVerification = () => {
    const { memberId, userId } = useParams();
    const db = firebase.firestore();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const verifyAdmin = async () => {
            try {
                await db
                    .collection('user')
                    .where("userId", "==", userId)
                    .get()
                    .then((querySnapshot) => {
                        querySnapshot.forEach((doc) => {
                            doc.ref
                                .collection('member')
                                .doc(memberId)
                                .update({
                                    isVerify: true
                                })
                                .then(() => {
                                    setIsLoading(false);
                                });
                        });
                    });
            } catch (error) {
                console.error('Error updating document: ', error);
            }
        };

        verifyAdmin();

    }, []);
    return (
        <>
            {isLoading && (
                <div className='d-block text-center m-auto'>
                    <div className="spinner-border spinner-primary mb-4" style={{width:'50px', height:'50px'}}></div>
                    <div className='fs-1 fw-bold mb-2'>Verifying</div>
                    <div className='fs-4 mb-2'>Please wait</div>
                </div>
            )}

            {!isLoading && (
                <div className='d-block text-center m-auto'>
                    <div className='mb-4'>
                        <i class="fa-regular fa-circle-check text-success"
                            style={{ fontSize: '50px' }}
                        ></i>
                    </div>
                    <div className='fs-1 fw-bold mb-2'>Verify Completed</div>
                    <div className='btn btn-primary'>
                        <a href="https://malin1-app-mscanpro.web.app/select-member" className='fs-4' style={{ color: 'inherit' }}>Go to mScanPro</a>
                        {/* <i class="bi bi-arrow-up-right-square position-absolute"></i> */}
                    </div>
                </div>
            )}
        </>
    );
};

export default ConfirmVerification;
