import firebase from '../../firebase/FirebaseConfig';
import { useEffect, useState } from 'react';

const GETDATAFORMAT = () => {
    const [data, setData] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const db = firebase.firestore();
                const snapshot = await db.collection('tagFormat').get();
                const fetchedData = [];
                snapshot.forEach((doc) => {
                    fetchedData.push({ id: doc.id, ...doc.data() });
                });
                setData(fetchedData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    return <>
        {data.length && (
            <>
                {data.map((item, index) => (
                    <option key={item.id} value={item.formatId}>{index + 1}. {item.name}</option>
                ))} ;
            </>
        )}
    </>
};

export default GETDATAFORMAT;
