/* eslint-disable jsx-a11y/anchor-is-valid */

import { useUser } from '../../modules/newauth/UserContext'
import { useState, useEffect } from 'react'
import firebase from '../../firebase/FirebaseConfig'
import { useMember } from '../../modules/newauth/MemberContext'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import 'flatpickr/dist/themes/material_green.css'
import { useLocation } from 'react-router-dom'
import formatDate from '../../modules/utils/FormatDate'
import { BarLoader } from 'react-spinners'

import DataTable from 'react-data-table-component'
import { KTIcon } from '../../../_metronic/helpers'

const TagHistoryPage = () => {
  const { user } = useUser()
  const { member, getMember } = useMember()
  const db = firebase.firestore()
  const navigate = useNavigate()
  const MySwal = withReactContent(Swal)
  const { deviceId, tagId } = useParams()

  const location = useLocation()

  const [logData, setLogData] = useState([]);
  const [tagData, setTagData] = useState(null)

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (member === null || member === 'null') {
      navigate(`/select-member`)
    }
  }, [member])

  const handleNavigate = (path) => {
    navigate(path)
  }

  useEffect(() => {
    const fetchTagData = async () => {
      setLoading(true);
      const deviceData = await fetchDocument('device', deviceId, user.userId, navigate);
      if (!deviceData) return;

      const tagData = await fetchDocument('tag', tagId, user.userId, navigate);
      if (!tagData) return;

      getHistory();
      setTagData(tagData);
    };

    fetchTagData();
  }, [tagId]);

  const fetchDocument = async (collection, docId, userId, navigate) => {
    try {
      const doc = await db.collection(collection).doc(docId).get();
      if (doc.exists) {
        if (userId !== doc.data().owner) {
          await MySwal.fire({
            title: `You are not the owner of this ${collection}.`,
            icon: 'error',
          });
          navigate('/device');
          return null;
        }
        return doc.data();
      } else {
        await MySwal.fire({
          title: `${collection.charAt(0).toUpperCase() + collection.slice(1)} not found.`,
          icon: 'error',
        });
        navigate('/device');
        return null;
      }
    } catch (error) {
      console.error(`Error getting document from ${collection}:`, error);
      return null;
    }
  };


  const getHistory = async () => {
    const apiUrl = `https://asia-southeast1-dygistechplatform.cloudfunctions.net/apiConnectMScanPro/v1/tags/history?tagId=${tagId}&deviceId=${deviceId}&limitCount=${10}`
    // const apiUrl = `http://127.0.0.1:5001/dygistechplatform/asia-southeast1/apiConnectMScanPro/v1/tags/history?tagId=${tagId}&deviceId=${deviceId}&limitCount=${10}`

    const config = {
      headers: {
        Authorization: 'Bearer E1ECE296153B9B65752E591911D44',
        'X-Api-Secret': 'BB5E88A38DB539372E795A249A5DD',
      },
    }

    axios
      .get(apiUrl, config)
      .then(async (response) => {
        if (response.data.status === 'success') {
          setLogData(response.data.data.log);
          setLoading(false);
        }
      })
      .catch(async (error) => {
        setLoading(false);
        MySwal.fire({
          title: 'Get history not complete.',
          text: error.message,
          icon: 'error',
        })
      })
  }
  let columns = []
  switch (tagData?.dataFormat) {
    case "format1":
      columns = [
        {
          name: 'Time',
          selector: (row) => row.createTime,
          sortable: true,
          cell: (row) => {
            return <>{formatDate(row.createTime)}</>
          },
        },
        {
          name: tagData?.header?.text1 || 'Text',
          selector: (row) => row.text1,
          sortable: true,
          cell: (row) => {
            return <>{row.text1}</>
          },
        },
      ]
      break;

    case "format2":
      columns = [
        {
          name: 'Time',
          selector: (row) => row.createTime,
          sortable: true,
          cell: (row) => {
            return <div style={{ width: '560px' }}>{formatDate(row.createTime)}</div>;
          },
        },
        {
          name: tagData?.header?.text1 || 'Text 1',
          selector: (row) => row.text1,
          sortable: true,
          cell: (row) => {
            return <>{row.text1}</>
          },
        },
        {
          name: tagData?.header?.text2 || 'Text 2',
          selector: (row) => row.text2,
          sortable: true,
          cell: (row) => {
            return <>{row.text2}</>
          },
        },
        {
          name: tagData?.header?.text3 || 'Text 3',
          selector: (row) => row.text3,
          sortable: true,
          cell: (row) => {
            return <>{row.text3}</>
          },
        },
        {
          name: tagData?.header?.dueDate || 'Due Date',
          selector: (row) => row.dueDate,
          sortable: true,
          cell: (row) => {
            const getDate = row?.dueDate?.date;
            const dueDate = getDate ? new Date(getDate) : null;
            return <>{dueDate ? formatDate(dueDate / 1000, true) : ''}</>;
          },
        },
        {
          name: tagData?.header?.num1 || 'Number 1',
          selector: (row) => row.num1,
          sortable: true,
          cell: (row) => {
            return <>{row.num1}</>
          },
        },
        {
          name: tagData?.header?.num2 || 'Number 2',
          selector: (row) => row.num2,
          sortable: true,
          cell: (row) => {
            return <>{row.num2}</>
          },
        },
        {
          name: tagData?.header?.num3 || 'Number 3',
          selector: (row) => row.num3,
          sortable: true,
          cell: (row) => {
            return <>{row.num3}</>
          },
        },
      ]
      break;

    default:
      break;
  }

  return (
    <>
      <div className='d-flex justify-content-between align-items-center mb-6'>
        <div className='d-flex align-items-center' onClick={() => handleNavigate('/device')}>
          <KTIcon iconName={'left-square'} className='fs-3x me-3' />
          <h3 className='fw-bolder my-2 ms-2'>History</h3>
        </div>
        {/* <ol className='breadcrumb text-muted fs-6 fw-bold'>
          <li className='breadcrumb-item' onClick={() => handleNavigate('/device')}>
            <span style={{ cursor: 'pointer' }}>Device</span>
          </li>
          <li className='breadcrumb-item text-muted'>History</li>
        </ol> */}
      </div>

      <div className='card card-custom'>
        <div className='card-body'>
          <DataTable
            columns={columns}
            data={logData}
            pagination
            paginationPerPage={25}
            paginationRowsPerPageOptions={[25, 50, 100]}
            progressPending={loading}
            progressComponent={
              <div className='d-block'>
                <BarLoader />
                <div className='text-center fs-4 fw-medium mt-2'>Loading...</div>
              </div>
            }
          />
        </div>
      </div>
    </>
  )
}

const TagHistoryWrapper = () => {
  //   const intl = useIntl()
  return (
    <>
      {/* <PageTitle breadcrumbs={[]}>{'Select member'}</PageTitle> */}
      <TagHistoryPage />
    </>
  )
}

export { TagHistoryWrapper }