/* eslint-disable jsx-a11y/anchor-is-valid */

import { useUser } from '../../modules/newauth/UserContext'
import { useState, useEffect } from 'react'
import firebase from '../../firebase/FirebaseConfig'
import { useMember } from '../../modules/newauth/MemberContext'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import QRCode from 'qrcode.react'
import { KTIcon } from '../../../_metronic/helpers'
import CodeDetailFormat2 from './codedetail/CodeDetailFormat2'
import { downloadQRCode } from './DownloadQR'

const CodeDetailPage = () => {
  const { user } = useUser()
  const { member, getMember } = useMember()
  const [memberObject, setMemberObject] = useState({})
  const db = firebase.firestore()
  const navigate = useNavigate()

  const { codeId } = useParams()

  const [codeData, setCodeData] = useState({})
  const [tagData, setTagData] = useState({})
  const [QRCodeData, setQRCodeData] = useState({
    deviceId: '',
    tagId: '',
    dataFormat: '',
    owner: '',
    appType: 'mScanPro',
    text1: '',
    command: '',
    codeId: '',
  })
  const [pageLoading, setPageLoading] = useState(true)

  useEffect(() => {
    if (member === null || member === 'null') {
      navigate(`/select-member`)
    }
  }, [member])

  useEffect(() => {
    async function fetchMember() {
      const memberData = await getMember()
      setMemberObject(memberData)
    }
    fetchMember()
  }, [])

  useEffect(() => {
    const MySwal = withReactContent(Swal)
    db.collection('code')
      .doc(codeId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          if (doc.data().owner !== user.userId) {
            MySwal.fire({
              title: 'You are not the owner of this code.',
              icon: 'error',
              confirmButtonText: 'Close',
            }).then(() => {
              navigate('/code')
            })
          } else {
            setQRCodeData((prevState) => ({
              ...prevState,
              tagId: doc.data().tagId,
              dataFormat: doc.data().dataFormat,
              owner: doc.data().owner,
              appType: 'mScanPro',
              text1: doc.data().text1,
              command: doc.data().command,
              codeId: doc.id,
            }))

            setCodeData({ ...doc.data(), id: doc.id })
          }
        } else {
          MySwal.fire({
            title: 'Code not found.',
            icon: 'error',
            confirmButtonText: 'Close',
          }).then(() => {
            navigate('/code')
          })
        }
      })
      .catch((error) => {
        setPageLoading(false);
        console.log('Error getting document:', error)
      })
  }, [])

  const handleNavigate = (path) => {
    navigate(path)
  }

  useEffect(() => {
    db.collection('tag')
      .doc(codeData.tagId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          setQRCodeData((prevState) => ({
            ...prevState,
            deviceId: doc.data().deviceId,
          }))
          setTagData({ ...doc.data(), id: doc.id })
          setPageLoading(false);
        }
      })
      .catch((error) => {
        setPageLoading(false);
        console.log('Error getting document:', error)
      })
  }, [codeData])

  // const downloadQRCode = (filename) => {
  //   const qrCodeURL = document
  //     .getElementById('qrCodeEl')
  //     .toDataURL('image/png')
  //     .replace('image/png', 'image/octet-stream')
  //   let aEl = document.createElement('a')
  //   aEl.href = qrCodeURL
  //   aEl.download = filename + '.png'
  //   document.body.appendChild(aEl)
  //   aEl.click()
  //   document.body.removeChild(aEl)
  // }


  return (
    <>
      <div className='d-flex justify-content-between align-items-center mb-6'>
        <div className='d-flex align-items-center' onClick={() => handleNavigate('/code')}>
          <KTIcon iconName={'left-square'} className='fs-3x me-3' />
          <h3 className='fw-bolder my-2 ms-2'>Code Detail</h3>
        </div>
        {/* <ol className='breadcrumb text-muted fs-6 fw-bold'>
          <li className='breadcrumb-item' onClick={() => handleNavigate('/code')}>
            <span style={{ cursor: 'pointer' }}>Code</span>
          </li>
          <li className='breadcrumb-item text-muted'>Code Detail</li>
        </ol> */}
      </div>


      {pageLoading ? (
        <div className='d-flex justify-content-center mt-10'>
          <span className='spinner-border' role="status"></span>
        </div>
      ) : (
        <div className='card card-custom'>
          <div className='card-body'>
            {codeData.dataFormat === 'format1' ? (
              <>
                <div className='row'>
                  <h2 className='mb-5 col-3'>{tagData?.header?.text1 || "Text 1"} :</h2>
                  <h2 className='col-auto'>{codeData.text1}</h2>
                </div>
                {/* <h2 className='mb-5'>Note : {codeData.note}</h2> */}
                <div className='separator my-10'></div>
                <h4 className='mb-5'>Tag name : {tagData.tagName}</h4>
                <h4 className='mb-5'>Created : {formatDate(codeData.created)}</h4>
                <h4 className='mb-5'>Command : {codeData.command}</h4>
                <div className='separator my-10'></div>
                <div
                  className='mb-10'
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <QRCode id='qrCodeEl' size={150} value={JSON.stringify(QRCodeData)} />
                </div>

                <div
                  className='mb-5'
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <button
                    className='btn btn-primary'
                    style={{ width: '100%' }}
                    onClick={() => downloadQRCode(`${tagData.tagName}-${codeData.text1}-${codeData.command}`, codeData, tagData)}
                  >
                    Download QR Code
                  </button>
                </div>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <button
                    className='btn btn-secondary'
                    style={{ width: '100%' }}
                    onClick={() => navigate('/code')}
                  >
                    Back
                  </button>
                </div>
              </>
            ) : (
              <></>
            )}
            {codeData.dataFormat === 'format2' && (
              <CodeDetailFormat2
                codeData={codeData}
                tagData={tagData}
                QRCodeData={QRCodeData}
              />
            )}
          </div>
        </div>
      )}
    </>
  )
}

const CodeDetail = () => {
  return (
    <>
      <CodeDetailPage />
    </>
  )
}

export { CodeDetail }

function formatDate(timestamp) {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  const date = new Date(timestamp * 1000)

  const day = date.getDate()
  const month = months[date.getMonth()]
  const year = date.getFullYear()
  const hours = date.getHours()
  const minutes = date.getMinutes()

  const formattedDate = `${day}-${month}-${year} ${hours.toString().padStart(2, '0')}.${minutes
    .toString()
    .padStart(2, '0')}`
  return formattedDate
}
