/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { KTIcon } from '../../../../_metronic/helpers'
import { useNavigate } from 'react-router-dom'
import { Dropdown } from './Dropdown'
import { formatDef } from '../../../modules/utils/FormatDef'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'

const TagWidget = ({
  tagName,
  description,
  colorTheme,
  dataFormat,
  deviceId,
  tagId,
  updateTag,
  setUpdateTag,
  lastValue,
  memberData,
  taskRemain,
  tag
}) => {
  const navigate = useNavigate()

  const handleNavigate = () => {
    navigate(`/device/${deviceId}/tag/${tagId}`)
  }

  return (
    <div
      className={`card hoverable card-xl-stretch mb-xl-8 border`}
      style={{
        cursor: 'pointer',
        // borderStyle: 'solid',
        // borderColor: colorTheme+'',
        // borderWidth: '2px',
        // boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px'
      }}
    >
      <div className='row card-body' style={{ padding: '1rem 3rem' }}>
        <div
          className='col-3'
          onClick={handleNavigate}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {iconDef(dataFormat)}
          {/* <KTIcon iconName={'text'} className='fs-5x mb-4' /> */}
          <div className={`fw-bold text-gray-900`}>{formatDef(dataFormat)}</div>
        </div>

        <div className='col-8' onClick={handleNavigate}>
          <span
            style={{
              width: '15px',
              height: '15px',
              backgroundColor: colorTheme,
              display: 'inline-block',
            }}
            className='bullet bullet-dot me-3'
          ></span>
          <span className={`text-gray-900 fw-bold fs-2 mb-1 mt-1`}>
            {capitalizeFirstLetter(tagName)}
          </span>
          <div className={`fw-semibold text-gray-600`}>{capitalizeFirstLetter(description)}</div>
          <div className='fw-semibold text-gray-600 rounded p-1 my-1'
            style={{ outline: 'solid 1px lightgrey' }}
          >{lastValue?.text1 ? (lastValue?.text1) : ('')}{lastValue?.text2 ? (', ' + lastValue?.text2) : ('')}{lastValue?.text3 ? (', ' + lastValue?.text3) : ('')}</div>
        </div>

        <div className='col-1'>
          <div className='me-0'>
            <button
              className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
            >
              <i className='bi bi-three-dots fs-3'></i>
            </button>
            <Dropdown
              taskRemain={taskRemain}
              memberData={memberData}
              tagName={tagName}
              deviceId={deviceId}
              tagId={tagId}
              updateTag={updateTag}
              setUpdateTag={setUpdateTag}
              dataFormat={dataFormat}
              tag={tag}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export { TagWidget }

function capitalizeFirstLetter(string) {
  const str = String(string)

  if (str.trim().length === 0) {
    return ''
  }

  return str.charAt(0).toUpperCase() + str.slice(1)
}

const iconDef = (dataFormat) => {
  switch (dataFormat) {
    case 'format1':
      return (
        <KTIcon iconName={'text'} className='fs-5x mb-4' />
      );

    case 'format2':
      return (
          <img src={toAbsoluteUrl('/media/logos/formatLogo/productionLineLogo.png')} alt='format2' style={{ width: '50px', height: '50px', objectFit: 'contain' }} />
      );

    default:
      return null;
  }
};
