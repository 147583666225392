import React, { createContext, useContext, useState, useEffect } from 'react'
import firebase from '../../firebase/FirebaseConfig'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const firestore = firebase.firestore()
const DeviceContext = createContext()

export function useDevice() {
    return useContext(DeviceContext)
}

export function DeviceProvider({ children }) {
    const [device, setDevice] = useState(null);
    
    const loadDevice = async (ownerId) => {
        try {
            const MySwal = withReactContent(Swal)
            await firestore
                .collection('device')
                .where('owner', '==', ownerId)
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        setDevice({ deviceId: doc.id, ...doc.data() })
                    })
                })
                .catch((error) => {
                    console.log('Error getting documents: ', error)
                })

        } catch (error) {
        }
    };

    return (
        <DeviceContext.Provider value={{ device, setDevice, loadDevice }}>
            {children}
        </DeviceContext.Provider>
    );
}
