/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { KTIcon } from '../../../../_metronic/helpers'
import { useNavigate } from 'react-router-dom'
// import {Dropdown} from './Dropdown'
import firebase from '../../../firebase/FirebaseConfig'
import clsx from 'clsx'
import { Dropdown } from './Dropdown'
import formatDate from '../../../modules/utils/FormatDate'

const CodeWidget = ({ obj, triggerCode, setTriggerCode, memberObject }) => {
  const navigate = useNavigate()
  const db = firebase.firestore()

  const [tagData, setTagData] = useState({})

  const handleNavigate = () => {
    navigate(`/code/${obj.id}`)
  }

  const handleEdit = (edit) => {
    if (edit) {
      navigate(`/code-edit/${obj.id}`)
    }
  }

  useEffect(() => {
    db.collection('tag')
      .doc(obj.tagId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          setTagData({ id: doc.id, ...doc.data() })
        } else {
          setTagData({ tagName: 'This tag has been removed.' })
        }
      })
      .catch((error) => {
        console.log('Error getting document:', error)
      })
  }, [obj])
  return (
    <div
      className={`card hoverable card-xl-stretch mb-xl-8`}
      style={{
        cursor: 'pointer', // Make it appear clickable
        borderStyle: 'solid',
        borderWidth: '1px',
      }}
    >
      <div className='row card-body' style={{ padding: '2rem 3rem' }}>
        <div
          className='col-3'
          onClick={handleNavigate}
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <KTIcon iconName={'scan-barcode'} className='fs-6x' />
        </div>

        <div className='col-8' onClick={handleNavigate}>
          <div className={`text-gray-900 fw-bold fs-2 mb-1 mt-1`}>
            {[obj?.text1, obj?.text2, obj?.text3, obj?.dueDate?.date ? formatDate(obj.dueDate.date.seconds, true) : '', obj?.num1, obj?.num2, obj?.num3]
              .filter(Boolean)
              .join(', ')}
          </div>

          {/* <div
            className='fw-bold'
            style={{ color: tagData.tagName === 'This tag has been removed.' ? 'red' : 'black' }}
          >
            {tagData.tagName}
          </div> */}
        </div>

        <div className='col-1'>
          <div className='me-0'>
            <button
              className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
            >
              <i className='bi bi-three-dots fs-3'></i>
            </button>
            <Dropdown obj={obj} triggerCode={triggerCode} setTriggerCode={setTriggerCode} editClick={handleEdit} />
          </div>
        </div>
      </div>
    </div>
  )
}

export { CodeWidget }
