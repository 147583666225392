/* eslint-disable jsx-a11y/anchor-is-valid */

import axios from 'axios'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { PulseLoader } from 'react-spinners'
import firebase from '../../../firebase/FirebaseConfig'
import { useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap'
import formatDate from '../../../modules/utils/FormatDate'
import clsx from 'clsx'

export function Dropdown({ tagName, deviceId, tagId, updateTag, setUpdateTag, memberData, taskRemain, dataFormat, tag }) {
  const db = firebase.firestore()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);
  const [showTask, setShowTask] = useState(false);

  const handleDelete = () => {
    const MySwal = withReactContent(Swal)
    setLoading(true)

    const apiUrl = `https://asia-southeast1-dygistechplatform.cloudfunctions.net/apiConnectMScanPro/v1/tags/delete?tagId=${tagId}&deviceId=${deviceId}`

    const config = {
      headers: {
        Authorization: 'Bearer E1ECE296153B9B65752E591911D44',
        'X-Api-Secret': 'BB5E88A38DB539372E795A249A5DD',
      },
    }

    axios
      .delete(apiUrl, config)
      .then(async (response) => {
        if (response.data.status === 'success') {
          await db
            .collection('tag')
            .doc(tagId)
            .delete()
            .then(async () => {
              await db
                .collection('device')
                .doc(deviceId)
                .update({
                  tag: firebase.firestore.FieldValue.arrayRemove(tagId),
                })
                .then(() => {
                  setLoading(false)
                  setUpdateTag(!updateTag)
                  MySwal.fire({
                    title: 'Tag deleted.',
                    icon: 'success',
                  })

                })
                .catch((error) => {
                  console.error('Error writing document: ', error)
                  setLoading(false)

                  MySwal.fire({
                    title: 'Tag delete not complete.',
                    text: error,
                    icon: 'error',
                  })
                })
            })
            .catch((error) => {
              console.error('Error removing document: ', error)
              setLoading(false)
              MySwal.fire({
                title: 'Tag delete not complete.',
                text: error,
                icon: 'error',
              })
            })
        } else {
          console.error('Error message from server:', response.data.message)
          setLoading(false)
          MySwal.fire({
            title: 'Error',
            text: response.data.message,
            icon: 'error',
          })
        }
      })
      .catch(async (error) => {
        setLoading(false)
        if (error.response) {
          MySwal.fire({
            title: 'Tag delete not complete.',
            text: error.response.data.message,
            icon: 'error',
          })
        } else if (error.request) {
          MySwal.fire({
            title: 'Tag delete not complete.',
            text: 'No response was received',
            icon: 'error',
          })
        } else {
          MySwal.fire({
            title: 'Tag delete not complete.',
            text: error.message,
            icon: 'error',
          })
        }
      })
  }

  const handleHistory = () => {
    navigate(`/device/${deviceId}/tag/${tagId}/history`)
  }

  const handleDetail = () => {
    navigate(`/device/${deviceId}/tag/${tagId}/detail`)
  }

  const handleEdit = () => {
    navigate(`/device/${deviceId}/tag/${tagId}/edit`)
  }


  const handleGenerateCode = () => {
    navigate(`/create-qr-code`, { state: { tagId: tagId } })
  }

  const handleTaskRemain = (item) => {
    if (item?.dueDate?.date?.seconds) {
      const newDate = new Date(item.dueDate.date.seconds);
      item.dueDate.date = newDate;
    }
    item.taskRemain = true;
    navigate(`/device/${deviceId}/tag/${tagId}`, { state: item })
  }

  useEffect(() => {
    taskRemain.sort((a, b) => b?.created - a?.created);
  })


  return (
    <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bolder'>Options</div>
        {dataFormat === "format2" && (
          <button
            className={clsx('btn btn-sm w-100 my-2 position-relative', {
              'btn-danger': taskRemain.length > 0
            })}
            onClick={() => setShowTask(true)}>
            {taskRemain.length > 0 ? (`${taskRemain.length} Task Remaining`) : ('No task remain')}
            {/* {taskRemain.length > 0 && (
              <i className="bi bi-bell-fill fs-4 position-absolute"
                style={{ color: 'red', left: '90%' }}
              ></i>
            )} */}
          </button>
        )}
      </div>

      <div className='separator border-gray-200'></div>

      <div className='px-7 py-5'>
        {/* <div className='mb-5'>
          <label className='form-label fw-bold'>Favorite:</label>

          <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
            <input
              className='form-check-input'
              type='checkbox'
              value=''
              name='notifications'
              defaultChecked={false}
              disabled
            />
            <label className='form-check-label'>Enabled</label>
          </div>
        </div> */}
        <div className=''>
          <button className='btn btn-primary btn-sm w-100 my-1' disabled={memberData.role === "member"} onClick={() => handleEdit()}>
            Edit
          </button>
          <button className='btn btn-primary btn-sm w-100 my-1' onClick={() => handleDetail()}>
            Detail
          </button>
          <button className='btn btn-primary btn-sm w-100 my-1' onClick={() => handleGenerateCode()}>
            Generate Code
          </button>

          <button className='btn btn-primary btn-sm w-100 my-1' onClick={() => handleHistory()}>
            History
          </button>
        </div>
        <div className='mb-5 px-3 row my-1'>
          <button className='btn btn-danger btn-sm' disabled={memberData.role === "member"} onClick={() => handleDelete()}>
            {loading ? (
              <PulseLoader color='#fff' size={8} margin={2} />
            ) : (
              <span className='indicator-label'>Delete</span>
            )}
          </button>
        </div>
      </div>
      {/* Task modal */}
      <Modal show={showTask} onHide={() => setShowTask(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title className='fs-2 fw-bold'>Task Remaining ({tagName})</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          {taskRemain.map(item => (
            <button
              key={item.id}
              className='btn btn-bg-secondary w-100 p-2 rounded mb-5'
              onClick={() => handleTaskRemain(item)}
            >
              <div className='data-container fs-5' id={item.id}>
                {item?.text1}, {item?.text2}, {item?.text3}, {formatDate(item?.dueDate?.date?.seconds, true)}, {item?.num1}, {item?.num2}, {item?.num3}
              </div>
            </button>
          ))}

        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-between'>
          <div className='length-container fs-4 text-muted'>
            {taskRemain.length}/10
          </div>
          <Button variant='secondary' onClick={() => setShowTask(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  )
}
