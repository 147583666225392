/* eslint-disable jsx-a11y/anchor-is-valid */

import { useUser } from '../../modules/newauth/UserContext'
import { useState, useEffect } from 'react'
import firebase from '../../firebase/FirebaseConfig'
import { useMember } from '../../modules/newauth/MemberContext'
import { useNavigate } from 'react-router-dom'
import CodeScanner from '../scanner/CodeScanner'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import Prism from 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-json' // เป็นตัวอย่างสำหรับ Python

import CodePreviewFormat2 from './codepreview/CodePreviewFormat2'

import formatDate from '../../modules/utils/FormatDate'

const CodePreviewPage = () => {
  const { user } = useUser()
  const { member, getMember } = useMember()
  const [memberObject, setMemberObject] = useState({})
  const db = firebase.firestore()
  const navigate = useNavigate()
  const MySwal = withReactContent(Swal)

  const [scanData, setScanData] = useState({})
  const [codeData, setCodeData] = useState({})
  const [tagData, setTagData] = useState({})
  const [formatName, setFormatName] = useState("")

  const [showScaner, setShowScaner] = useState(true)
  const [showRawData, setShowRawData] = useState(true)
  const [showCodeData, setShowCodeData] = useState(false)
  const [showTagData, setShowTagData] = useState(false)

  const [isError, setIsError] = useState('');

  const [cameras, setCameras] = useState([]);
  const [selectedCamera, setSelectedCamera] = useState(null);

  const storageCameraId = sessionStorage.getItem('cameraId');

  useEffect(() => {
    Prism.highlightAll()
  }, [showScaner, showRawData])

  useEffect(() => {
    if (member === null || member === 'null') {
      navigate(`/select-member`)
    }
  }, [member])

  useEffect(() => {
    async function fetchMember() {
      const memberData = await getMember()
      setMemberObject(memberData)
    }
    fetchMember()
  }, [])

  const onNewScanResult = (decodedText, decodedResult) => {
    let obj
    try {
      obj = JSON.parse(decodedText)
      console.log(obj)
      setScanData(obj)
      setShowScaner(false)
    } catch (e) {
      console.log(e)
      setIsError("Invalid QR Code or Barcode");
    }
  }

  useEffect(() => {
    const codeId = scanData.codeId
    console.log(codeId)
    db.collection('code')
      .doc(codeId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          setCodeData(doc.data())
        }
      })
      .catch((error) => { })
  }, [scanData])

  useEffect(() => {
    const tagId = scanData.tagId
    console.log(tagId)
    db.collection('tag')
      .doc(tagId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          setTagData(doc.data())
        }
      })
      .catch((error) => { })
  }, [scanData])

  useEffect(() => {
    const fetchFormatType = async () => {
      if (scanData && Object.keys(scanData).length > 0) {
        try {
          const querySnapshot = await db.collection('tagFormat')
            .where('formatId', '==', scanData.dataFormat)
            .get();

          if (!querySnapshot.empty) {
            const foundFormat = querySnapshot.docs[0].data();
            setFormatName(foundFormat.name);
          } else {
            console.log('No matching format found');
          }
        } catch (error) {
          console.error('Error fetching Format type document: ', error);
        }
      }
    };
    fetchFormatType();
  }, [scanData]);

  const handleReset = () => {
    setShowScaner(true)
    setScanData({})
    setIsError('');
  }

  useEffect(() => {
    // Fetch the list of available cameras
    const fetchCameras = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(device => device.kind === 'videoinput');
        setCameras(videoDevices);
        if (videoDevices.length > 0) {
          if (storageCameraId) {
            setSelectedCamera(storageCameraId);
          } else {
            setSelectedCamera(videoDevices[0].deviceId);
          }
        }
      } catch (error) {
        console.error('Error fetching cameras:', error);
      }
    };

    fetchCameras();
  }, []);
  // const handleFileSelect = (e) => {

  // };
  const handleCameraChange = event => {
    setSelectedCamera(event.target.value);
    sessionStorage.setItem('cameraId', event.target.value);
  };

  return (
    <>
      <div className='d-flex justify-content-between align-items-center mb-6'>
        <h3 className='fw-bolder my-2'>Scan QR Code</h3>
      </div>

      <div className='card card-custom'>
        <div className='card-body'>
          {showScaner == true ? (

            <div className='App mb-5'>
              <div className='input-group d-flex align-items-center mb-5'>
                <label htmlFor="camera-selection" className='input-group-text fw-medium fs-5'>Camera</label>
                <select className='form-select' id='camera-selection' value={selectedCamera} onChange={handleCameraChange}>
                  {cameras.map(camera => (
                    <option key={camera.deviceId} value={camera.deviceId}>
                      {camera.label || `Camera ${camera.deviceId}`}
                    </option>
                  ))}
                </select>
              </div>
              {member !== null && member !== 'null' && (
                <CodeScanner
                  cameraId={selectedCamera}
                  qrCodeSuccessCallback={onNewScanResult}
                />
              )}
              <div className='text-danger fs-3 mt-2 text-center'>{isError}</div>
            </div>
          ) : (
            <button
              className='btn btn-sm btn-danger mb-10'
              style={{ width: '100%' }}
              onClick={() => handleReset()}
            >
              Clear result
            </button>
          )}

          {/* Result Raw Data  */}
          {showScaner == true ? null : (
            <div>
              <div className='d-flex justify-content-between align-items-center mb-6'>
                <div className='d-flex align-items-center'>
                  <label className='form-label fw-bold text-gray-800 fs-2'>Raw data : </label>
                </div>

                <div>
                  <button
                    className='btn btn-sm btn-light-info'
                    onClick={() => setShowRawData(!showRawData)}
                  >
                    {showRawData ? 'Hide' : 'Show'}
                  </button>
                </div>
              </div>

              {showRawData ? (
                <>
                  <div>
                    <pre style={{ borderRadius: '10px' }}>
                      <code className={`language-json`}>{JSON.stringify(scanData, null, 2)}</code>
                    </pre>
                  </div>

                  <div className='separator my-5'></div>

                  {scanData.dataFormat == "format1" && (
                    <>
                      <div className='mb-5 row'>
                        <div className='col-6'>
                          <label className='form-label fw-bold text-gray-600'>Data format : </label>
                          <p className='fw-bold fs-3'>{formatName || scanData.dataFormat}</p>
                        </div>
                        <div className='col-6'>
                          <label className='form-label fw-bold text-gray-600'>Text 1 : </label>
                          <p className='fw-bold fs-3'>{scanData.text1}</p>
                        </div>
                      </div>

                      <div className='mb-5 row'>
                        <div className='col-6'>
                          <label className='form-label fw-bold text-gray-600'>Command : </label>
                          <p className='fw-bold fs-3'>{scanData.command}</p>
                        </div>
                        <div className='col-6'>
                          <label className='form-label fw-bold text-gray-600'>App type : </label>
                          <p className='fw-bold fs-3'>{scanData.appType}</p>
                        </div>
                      </div>

                      <div className='mb-5 row'>
                        <div className='col-6'>
                          <label className='form-label fw-bold text-gray-600'>Code id : </label>
                          <p className='fw-bold fs-3'>{scanData.codeId}</p>
                        </div>
                        <div className='col-6'>
                          <label className='form-label fw-bold text-gray-600'>Tag id : </label>
                          <p className='fw-bold fs-3'>{scanData.tagId}</p>
                        </div>
                      </div>

                      <div className='mb-5 row'>
                        <div className='col-6'>
                          <label className='form-label fw-bold text-gray-600'>Device id : </label>
                          <p className='fw-bold fs-3'>{scanData.deviceId}</p>
                        </div>
                        <div className='col-6'>
                          <label className='form-label fw-bold text-gray-600'>Owner : </label>
                          <p className='fw-bold fs-3'>{scanData.owner}</p>
                        </div>
                      </div>
                    </>
                  )}
                  {scanData.dataFormat == "format2" && (
                    <CodePreviewFormat2
                      scanData={scanData}
                      tagData={tagData}
                      formatName={formatName}
                    />
                  )}
                </>
              ) : (
                ''
              )}
            </div>
          )}

          {/* Result Code Data  */}
          {showScaner == true ? null : (
            <div>
              {/* Result Code Data */}
              <div className='separator my-5'></div>
              <div className='d-flex justify-content-between align-items-center mb-6'>
                <div className='d-flex align-items-center'>
                  <label className='form-label fw-bold text-gray-800 fs-2'>Code data : </label>
                </div>

                <div>
                  <button
                    className='btn btn-sm btn-light-info'
                    onClick={() => setShowCodeData(!showCodeData)}
                  >
                    {showCodeData ? 'Hide' : 'Show'}
                  </button>
                </div>
              </div>

              {showCodeData ? (
                isEmptyObject(codeData) ? (
                  <div className='d-flex justify-content-center'>
                    <p className='fw-bold fs-5 mb-5'>Code not found, it may have been deleted.</p>
                  </div>
                ) : (
                  <div className='mb-5 row'>
                    <div className='col-6'>
                      <label className='form-label fw-bold text-gray-600'>Create date:</label>
                      <p className='fw-bold fs-3'>{formatDate(codeData?.created)}</p>
                    </div>
                    <div className='col-6'>
                      <label className='form-label fw-bold text-gray-600'>Note:</label>
                      <p className='fw-bold fs-3'>{codeData?.note}</p>
                    </div>
                  </div>
                )
              ) : (
                ''
              )}
            </div>
          )}

          {/* Result Tag Data  */}
          {showScaner == true ? null : (
            <div>
              {/* Result Code Data */}
              <div className='separator my-5'></div>
              <div className='d-flex justify-content-between align-items-center mb-6'>
                <div className='d-flex align-items-center'>
                  <label className='form-label fw-bold text-gray-800 fs-2'>Tag data : </label>
                </div>

                <div>
                  <button
                    className='btn btn-sm btn-light-info'
                    onClick={() => setShowTagData(!showTagData)}
                  >
                    {showTagData ? 'Hide' : 'Show'}
                  </button>
                </div>
              </div>

              {showTagData ? (
                isEmptyObject(tagData) ? (
                  <div className='d-flex justify-content-center'>
                    <p className='fw-bold fs-5 mb-5'>Tag not found, it may have been deleted.</p>
                  </div>
                ) : (
                  <>
                    <div className='mb-5 row'>
                      <div className='col-6'>
                        <label className='form-label fw-bold text-gray-600'>Name : </label>
                        <p className='fw-bold fs-3'>{tagData?.tagName}</p>
                      </div>
                      <div className='col-6'>
                        <label className='form-label fw-bold text-gray-600'>Description : </label>
                        <p className='fw-bold fs-3'>{tagData?.description}</p>
                      </div>
                    </div>
                    <div className='mb-0 row'>
                      <div className='col-6'>
                        <label className='form-label fw-bold text-gray-600'>Create date : </label>
                        <p className='fw-bold fs-3'>{formatDate(tagData?.createDate)}</p>
                      </div>
                      <div className='col-6'>
                        <label className='form-label fw-bold text-gray-600'>Device id : </label>
                        <p className='fw-bold fs-3'>{tagData?.deviceId}</p>
                      </div>
                    </div>
                  </>
                )
              ) : (
                ''
              )}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

const CodePreviewWrapper = () => {
  return (
    <>
      <CodePreviewPage />
    </>
  )
}

export { CodePreviewWrapper }

function isEmptyObject(obj) {
  return Object.keys(obj).length === 0
}
