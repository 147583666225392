import { useState, useEffect } from 'react'

function EditTag(props) {
    const { selectedFormat, handleEdit, editFormatHeader } = props

    const [text1, setText1] = useState(editFormatHeader?.text1 || "Text 1");
    const [text2, setText2] = useState(editFormatHeader?.text2 || "Text 2");
    const [text3, setText3] = useState(editFormatHeader?.text3 || "Text 3");

    const [num1, setNum1] = useState(editFormatHeader?.num1 || "Number 1");
    const [num2, setNum2] = useState(editFormatHeader?.num2 || "Number 2");
    const [num3, setNum3] = useState(editFormatHeader?.num3 || "Number 3");

    const [flow, setFlow] = useState(editFormatHeader?.flow || "Flow");

    const [dueDate, setDueDate] = useState(editFormatHeader?.dueDate || "Due Date");

    const [headerName, setHeaderName] = useState("");

    useEffect(() => {
        switch (selectedFormat) {
            case "format1":
                setHeaderName("Text");
                break;

            case "format2":
                setHeaderName("Production Line");
                break;

            default:
                setHeaderName("");
                break;
        }
    }, [])

    const handleSubmit = (event) => {
        event.preventDefault();

        let formData = {
            text1: text1,
        }

        switch (selectedFormat) {
            case "format1":
                handleEdit(false, formData)
                break;

            case "format2":
                formData.text2 = text2
                formData.text3 = text3
                formData.num1 = num1
                formData.num2 = num2
                formData.num3 = num3
                formData.flow = flow
                formData.dueDate = dueDate
                handleEdit(false, formData)
                break;

            default:
                console.error("Format type not found", selectedFormat);
                break;
        }

    };

    return (
        <div className='card card-custom'
        >
            <div className='card-header'>
                <h3 className='card-title'>{headerName}</h3>
            </div>
            <div className='card-body'>
                <form onSubmit={handleSubmit}>
                    {/* text */}
                    {selectedFormat === "format1" && (
                        <div className='mb-5 d-flex align-items-center'>
                            <label htmlFor='text1' className='form-label col-3'>
                                Text 1
                            </label>
                            <input
                                id='text1'
                                type='text'
                                className='form-control'
                                // placeholder='Text 1'
                                value={text1}
                                onChange={(e) => setText1(e.target.value)}
                            />
                        </div>
                    )}

                    {/* production */}
                    {selectedFormat === "format2" && (
                        <>
                            <div className='mb-5 d-flex align-items-center'>
                                <label htmlFor='text1' className='form-label col-3'>
                                    Text 1
                                </label>
                                <input
                                    id='text1'
                                    type='text'
                                    className='form-control'
                                    // placeholder='Text 1'
                                    value={text1}
                                    onChange={(e) => setText1(e.target.value)}
                                />
                            </div>

                            <div className='mb-5 d-flex align-items-center'>
                                <label htmlFor='text2' className='form-label col-3'>
                                    Text 2
                                </label>
                                <input
                                    id='text2'
                                    type='text'
                                    className='form-control'
                                    // placeholder='Text 2'
                                    value={text2}
                                    onChange={(e) => setText2(e.target.value)}
                                />
                            </div>

                            <div className='mb-5 d-flex align-items-center'>
                                <label htmlFor='text3' className='form-label col-3'>
                                    Text 3
                                </label>
                                <input
                                    id='text3'
                                    type='text'
                                    className='form-control'
                                    // placeholder='Text 3'
                                    value={text3}
                                    onChange={(e) => setText3(e.target.value)}
                                />
                            </div>

                            <div className='mb-5 d-flex align-items-center'>
                                <label htmlFor='due-date' className='form-label col-3'>
                                    Due date
                                </label>
                                <input
                                    id='due-date'
                                    type='text'
                                    className='form-control'
                                    // placeholder='Due date'
                                    value={dueDate}
                                    onChange={(e) => setDueDate(e.target.value)}
                                />
                            </div>



                            <div className='mb-5 d-flex align-items-center'>
                                <label htmlFor='num1' className='form-label col-3'>
                                    Number 1
                                </label>
                                <input
                                    id='num1'
                                    type='text'
                                    className='form-control'
                                    // placeholder='Number 1'
                                    value={num1}
                                    onChange={(e) => setNum1(e.target.value)}
                                />
                            </div>
                            <div className='mb-5 d-flex align-items-center'>
                                <label htmlFor='num2' className='form-label col-3'>
                                    Number 2
                                </label>
                                <input
                                    id='num2'
                                    type='text'
                                    className='form-control'
                                    // placeholder='Number 2'
                                    value={num2}
                                    onChange={(e) => setNum2(e.target.value)}
                                />
                            </div>
                            <div className='mb-5 d-flex align-items-center'>
                                <label htmlFor='num3' className='form-label col-3'>
                                    Number 3
                                </label>
                                <input
                                    id='num3'
                                    type='text'
                                    className='form-control'
                                    // placeholder='Number 3'
                                    value={num3}
                                    onChange={(e) => setNum3(e.target.value)}
                                />
                            </div>
                            <div className='mb-5 d-flex align-items-center'>
                                <label htmlFor='flow' className='form-label col-3'>
                                    Flow
                                </label>
                                <input
                                    id='flow'
                                    type='text'
                                    className='form-control'
                                    // placeholder='Flow'
                                    value={flow}
                                    onChange={(e) => setFlow(e.target.value)}
                                />
                            </div>
                        </>
                    )}
                    <div className='text-center'>
                        <button type='submit' className='btn btn-md btn-primary'>
                            <span className='indicator-label'>Submit</span>
                        </button>

                        <button
                            type='button'
                            className='btn btn-md btn-light'
                            style={{ marginLeft: '10px' }}
                            onClick={() => handleEdit(false, editFormatHeader || null)}
                        >
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default EditTag
