import QRCode from 'qrcode.react'
import formatDate from '../../../modules/utils/FormatDate';
import { useNavigate } from 'react-router-dom';
import { downloadQRCode } from '../DownloadQR';

function CodeDetailFormat2(props) {

    const { codeData, tagData, QRCodeData } = props;

    const navigate = useNavigate();

    QRCodeData.text2 = codeData.text2
    QRCodeData.text3 = codeData.text3
    QRCodeData.num1 = codeData.num1
    QRCodeData.num2 = codeData.num2
    QRCodeData.num3 = codeData.num3
    QRCodeData.flow = codeData.flow

    if (!QRCodeData.dueDate) {
        QRCodeData.dueDate = {};
    }
    if (!QRCodeData.dueDate.date) {
        QRCodeData.dueDate.date = {};
    }
    QRCodeData.dueDate.date = codeData?.dueDate?.date?.seconds || null;

    const header = tagData.header;
    return (
        <>
            <div className='row'>
                <h2 className='mb-5 col-3'>{header?.text1 || "Text 1"} :</h2>
                <h2 className='col-auto'>{codeData.text1}</h2>
            </div>
            <div className='row'>
                <h2 className='mb-5 col-3'>{header?.text2 || "Text 2"} :</h2>
                <h2 className='col-auto'>{codeData.text2}</h2>
            </div>
            <div className='row'>
                <h2 className='mb-5 col-3'>{header?.text3 || "Text 3"} :</h2>
                <h2 className='col-auto'>{codeData.text3}</h2>
            </div>

            <div className='row'>
                <h2 className='mb-5 col-3'>{header?.dueDate || "Due Date"} :</h2>
                <h2 className='col-auto'>{formatDate(codeData?.dueDate?.date?.seconds || null, true)}</h2>
            </div>

            <div className='row'>
                <h2 className='mb-5 col-3'>{header?.num1 || "Number 1"} :</h2>
                <h2 className='col-auto'>{codeData.num1}</h2>
            </div>
            <div className='row'>
                <h2 className='mb-5 col-3'>{header?.num2 || "Number 2"} :</h2>
                <h2 className='col-auto'>{codeData.num2}</h2>
            </div>
            <div className='row'>
                <h2 className='mb-5 col-3'>{header?.num3 || "Number 3"} :</h2>
                <h2 className='col-auto'>{codeData.num3}</h2>
            </div>

            <div className='row'>
                <h2 className='mb-5 col-3'>{header?.flow || "Flow"} :</h2>
                <h2 className='col-auto'>{codeData.flow}</h2>
            </div>

            <div className='separator my-10'></div>

            <h4 className='mb-5'>Tag name : {tagData.tagName}</h4>
            <h4 className='mb-5'>Created : {formatDate(codeData.created)}</h4>
            <h4 className='mb-5'>Command : {codeData.command}</h4>
            <div className='separator my-10'></div>
            <div
                className='mb-10'
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <QRCode id='qrCodeEl' size={150} value={JSON.stringify(QRCodeData)} />
            </div>
            <div
                className='mb-5'
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <button
                    className='btn btn-primary'
                    style={{ width: '100%' }}
                    onClick={() => downloadQRCode(`${tagData.tagName}-${codeData.text1}-${codeData.text2}-${codeData.text3}-${codeData.command}`, codeData, tagData)}
                >
                    Download QR Code
                </button>
            </div>

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <button
                    className='btn btn-secondary'
                    style={{ width: '100%' }}
                    onClick={() => navigate('/code')}
                >
                    Back
                </button>
            </div>
        </>
    )
}

export default CodeDetailFormat2