/* eslint-disable jsx-a11y/anchor-is-valid */

import { useUser } from '../../modules/newauth/UserContext'
import { useState, useEffect } from 'react'
import firebase from '../../firebase/FirebaseConfig'
import { useMember } from '../../modules/newauth/MemberContext'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { PulseLoader } from 'react-spinners'
import { KTIcon } from '../../../_metronic/helpers'
import { useLocation } from 'react-router-dom'
import FormDataFormat2 from './codegenForm/FormDataFormat2'

const CodeGeneratorPage = () => {
  const { user } = useUser()
  const { member, getMember } = useMember()
  const [memberObject, setMemberObject] = useState({})
  const db = firebase.firestore()
  const navigate = useNavigate()
  const location = useLocation()

  const [allTag, setAllTag] = useState([])
  const [selectTagId, setSelectTagId] = useState('')
  const [objectById, setObjectById] = useState(null)

  const [command, setCommand] = useState('none')
  const [textOne, setTextOne] = useState('')
  const [note, setNote] = useState('')
  const [codeData, setCodeData] = useState({});

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (member === null || member === 'null') {
      navigate(`/select-member`)
    }
  }, [member])

  useEffect(() => {
    async function fetchMember() {
      const memberData = await getMember()
      setMemberObject(memberData)
    }
    fetchMember()
  }, [])

  const handleNavigate = (path) => {
    navigate(path)
  }

  useEffect(() => {
    async function fetchTag() {
      await db
        .collection('device')
        .where('owner', '==', user.userId)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(async (doc) => {
            const array = doc.data().tag
            let tag_array = []
            for (let index = 0; index < array.length; index++) {
              const id = array[index]
              await db
                .collection('tag')
                .doc(id)
                .get()
                .then((doc) => {
                  if (doc.exists) {
                    tag_array.push({
                      id: doc.id,
                      ...doc.data(),
                    })
                  } else {
                    console.log('No such document!')
                  }
                })
                .catch((error) => {
                  console.log('Error getting document:', error)
                })
            }

            setAllTag(tag_array)
          })
        })
        .catch((error) => {
          console.log('Error getting documents: ', error)
        })
    }
    fetchTag()
  }, [])

  useEffect(() => {
    async function runQrCode() {
      if (location.state !== null) {
        const obj = location.state
        console.log('runQrCode: Setting selectTagId to', obj.tagId)
        setSelectTagId(obj.tagId)
      }
    }
    runQrCode()
  }, [location.state])

  useEffect(() => {
    console.log('fetchTag: Current selectTagId is', selectTagId)
    async function fetchTag() {
      const selectedObject = allTag.find((item) => item.id === selectTagId)
      setObjectById(selectedObject)
    }
    if (selectTagId !== '' && selectTagId !== 'Select Input') {
      fetchTag()
    } else {
      setObjectById(null)
    }
  }, [selectTagId, allTag])

  const handleGenerateCode = async () => {
    setLoading(true)
    const MySwal = withReactContent(Swal)

    if (selectTagId === 'Select Input') {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please select tag input!',
      })
      setLoading(false)
      return
    }

    await db
      .collection('code')
      .add(dataToAdd(command, user, objectById, selectTagId, textOne, codeData))
      .then((docRef) => {
        console.log('Document written with ID: ', docRef.id)
        setLoading(false)

        MySwal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Create code successfully.',
        }).then(() => {
          navigate('/code/' + docRef.id)
        })
      })
      .catch((error) => {
        console.error('Error adding document: ', error)
        setLoading(false)

        MySwal.fire({
          icon: 'error',
          title: 'Error',
          text: error,
        })
      })
  }

  const handleFormData = (data) => {
    setCodeData(data);
  };

  return (
    <>
      <div className='d-flex justify-content-between align-items-center mb-6'>
        <div className='d-flex align-items-center' onClick={() => handleNavigate('/code')}>
          <KTIcon iconName={'left-square'} className='fs-3x me-3' />
          <h3 className='fw-bolder my-2 ms-2'>Code Generator</h3>
        </div>
        {/* <ol className='breadcrumb text-muted fs-6 fw-bold'>
          <li className='breadcrumb-item' onClick={() => handleNavigate('/code')}>
            <span style={{ cursor: 'pointer' }}>Code</span>
          </li>
          <li className='breadcrumb-item text-muted'>Code Generator</li>
        </ol> */}
      </div>

      <div className='card card-custom'>
        <div className='card-body'>
          <div className='mb-10'>
            <label className='form-label'>Tag input</label>
            <select
              className='form-select'
              aria-label='Select example'
              onChange={(e) => setSelectTagId(e.target.value)}
              value={selectTagId}
            >
              <option>Select Input</option>
              {allTag.map((tag) => (
                <option key={tag.id} value={tag.id}>
                  {tag.tagName}
                </option>
              ))}
            </select>
          </div>
          {objectById?.dataFormat === 'format1' ? (
            <>
              <div className='separator my-10'></div>
              <div className='mb-10'>
                <label className='form-label'>Text 1</label>
                <input
                  type='text'
                  className='form-control'
                  value={textOne}
                  onChange={(e) => setTextOne(e.target.value)}
                // placeholder='Text 1'
                />
              </div>
              {/* <div className='mb-10'>
                <label className='form-label'>Note</label>
                <input
                  type='text'
                  className='form-control'
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                  placeholder='Note'
                />
              </div> */}

              {/* Command Section */}
              <div className='mb-10'>
                <label className='form-label'>Command</label>
                <div className='row'>
                  <button
                    className={`btn mb-3 ${command == 'none' ? 'btn-primary' : 'btn-outline btn-outline-primary'
                      } `}
                    onClick={() => setCommand('none')}
                  >
                    <span className='indicator-label'>None</span>
                  </button>

                  <button
                    className={`btn mb-3 ${command == 'condition2' ? 'btn-primary' : 'btn-outline btn-outline-primary'
                      } `}
                    onClick={() => setCommand('condition2')}
                  >
                    <span className='indicator-label'>Send with no condition.</span>
                  </button>
                  <button
                    className={`btn mb-3 ${command == 'condition3' ? 'btn-primary' : 'btn-outline btn-outline-primary'
                      } `}
                    onClick={() => setCommand('condition3')}
                  >
                    <span className='indicator-label'>Send data if all value completed</span>
                  </button>
                </div>
              </div>
            </>
          ) : (
            ''
          )}

          {objectById?.dataFormat === "format2" && (
            <FormDataFormat2
              tagObject={objectById}
              onFormDataChange={handleFormData}
            />
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <button
              type='submit'
              className='btn btn-md btn-primary'
              onClick={() => handleGenerateCode()}
            >
              {loading ? (
                <PulseLoader color='#fff' size={8} margin={2} />
              ) : (
                <span className='indicator-label'>Generate code</span>
              )}
            </button>

            <button
              type='button'
              className='btn btn-md btn-light'
              onClick={() => navigate('/code')}
              style={{ marginLeft: '10px' }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

const CodeGeneratorWrapper = () => {
  return (
    <>
      {/* <PageTitle breadcrumbs={[]}>{'Select member'}</PageTitle> */}
      <CodeGeneratorPage />
    </>
  )
}

export { CodeGeneratorWrapper }

function dataToAdd(command, user, objectById, selectTagId, textOne, codeData) {

  const nowTimeStampInSeconds = Math.floor(Date.now() / 1000)

  let data = {
    command: command,
    dataFormat: objectById?.dataFormat,
    owner: user.userId,
    tagId: selectTagId,
    text1: textOne,
    created: nowTimeStampInSeconds,
  }

  switch (objectById?.dataFormat) {
    case "format1":
      return data;

    case "format2":
      data.text1 = codeData.text1
      data.text2 = codeData.text2
      data.text3 = codeData.text3
      data.num1 = codeData.num1
      data.num2 = codeData.num2
      data.num3 = codeData.num3
      data.dueDate = codeData.dueDate
      data.flow = codeData.flow
      data.command = codeData.command
      return data;

    default:
      break;
  }


}