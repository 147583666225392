import { useState, useEffect, useRef } from 'react'
import Flatpickr from 'react-flatpickr'
import clsx from 'clsx';


function EditFormFormat2({ codeData, tagData, handleFormChange, handleDateChange }) {
  // console.log(codeData);
  // console.log(tagData);
  const dueDateRef = useRef()

  const [command, setCommand] = useState(codeData.command || 'none')
  const [textOne, setTextOne] = useState(codeData.text1 || '')
  const [textTwo, setTextTwo] = useState(codeData.text2 || '')
  const [textThree, setTextThree] = useState(codeData.text3 || '')

  const [num1, setNum1] = useState(codeData.num1 || '')
  const [num2, setNum2] = useState(codeData.num2 || '')
  const [num3, setNum3] = useState(codeData.num3 || '')

  const [flowState, setFlowState] = useState(codeData.flow || '');


  const [note, setNote] = useState('')

  const [dueDateState, setDueDateState] = useState({
    date: null,
  })

  const [isDateChange, setIsDateChange] = useState(false);

  useEffect(() => {
    if (codeData.dueDate.date) {
      const date = new Date(codeData.dueDate.date.seconds * 1000);
      setDueDateState({ date: date });
      dueDateRef.current = date;
    }
  }, [codeData]);

  // Handle date change //
  useEffect(() => {
    if (isDateChange) {
      setDueDateState({ date: dueDateRef.current });
      handleDateChange({ date: dueDateRef.current })
      setIsDateChange(false);
    }
  }, [isDateChange]);


  const handleDateChangeClear = (selectedDates, dateStr, instance) => {
    const selectedDate = selectedDates.length > 0 ? selectedDates[0] : null;
    if (dueDateRef.current !== selectedDate) {
      dueDateRef.current = selectedDate;
      setIsDateChange(true);
    }
  };
  // Handle date change //


  useEffect(() => {
    const newData = {
      text1: textOne,
      text2: textTwo,
      text3: textThree,
      num1: num1,
      num2: num2,
      num3: num3,
      dueDate: dueDateState,
      flow: flowState,
      command: command,
    };
    handleFormChange(newData);
  }, [command, textOne, textTwo, textThree, num1, num2, num3, flowState]);

  const toggleFlowState = (newState) => {
    setFlowState(prevState => (prevState === newState ? "" : newState));
  };

  return (
    <>
      <div className='mb-10'>
        <label className='form-label'>{tagData?.header?.text1 || "Text 1"}</label>
        <input
          type='text'
          className='form-control'
          value={textOne}
          onChange={(e) => setTextOne(e.target.value)}
        // placeholder='Text 1'
        />
      </div>
      <div className='mb-10'>
        <label className='form-label'>{tagData?.header?.text2 || "Text 2"}</label>
        <input
          type='text'
          className='form-control'
          value={textTwo}
          onChange={(e) => setTextTwo(e.target.value)}
        // placeholder='Text 2'
        />
      </div>
      <div className='mb-10'>
        <label className='form-label'>{tagData?.header?.text3 || "Text 3"}</label>
        <input
          type='text'
          className='form-control'
          value={textThree}
          onChange={(e) => setTextThree(e.target.value)}
        // placeholder='Text 3'
        />
      </div>

      <div className='mb-10'>
        <label className='form-label'>{tagData?.header?.dueDate || "Due Date"}</label>
        <Flatpickr
          value={dueDateState.date}
          // onChange={handleDateChange}
          onChange={handleDateChangeClear}
          onValueUpdate={handleDateChangeClear}
          options={{
            enableTime: false,
            dateFormat: 'Y-m-dTH:i',
          }}
          className='form-control form-control-solid'
        // placeholder='Pick date'
        />
      </div>

      <div className='mb-10'>
        <label className='form-label'>{tagData?.header?.num1 || "Number 1"}</label>
        <input
          type='number'
          className='form-control'
          value={num1}
          onChange={(e) => setNum1(e.target.value)}
        // placeholder='Number 1'
        />
      </div>
      <div className='mb-10'>
        <label className='form-label'>{tagData?.header?.num2 || "Number 2"}</label>
        <input
          type='number'
          className='form-control'
          value={num2}
          onChange={(e) => setNum2(e.target.value)}
        // placeholder='Number 2'
        />
      </div>
      <div className='mb-10'>
        <label className='form-label'>{tagData?.header?.num3 || "Number 3"}</label>
        <input
          type='number'
          className='form-control'
          value={num3}
          onChange={(e) => setNum3(e.target.value)}
        // placeholder='Number 3'
        />
      </div>

      {/* flow */}
      <div className='mb-10'>
        <label htmlFor='flow' className='form-label fw-bold text-gray-600'>
          {tagData?.header?.flow || "Flow"}
        </label>
        <div id='flow' className='d-flex gap-4'>
          <button
            className={clsx('btn btn-secondary rounded rounded-pill', {
              "btn-info": flowState === "start"
            })}
            onClick={() => toggleFlowState("start")}
          >
            Start
          </button>
          <button
            className={clsx('btn btn-secondary rounded rounded-pill', {
              "btn-info": flowState === "finish"
            })}
            onClick={() => toggleFlowState("finish")}
          >
            Finish
          </button>
        </div>
      </div>

      {/* Command Section */}
      <div className='mb-10'>
        <label className='form-label'>Command</label>
        <div className='row'>
          <button
            className={`btn mb-3 ${command == 'none' ? 'btn-primary' : 'btn-outline btn-outline-primary'
              } `}
            onClick={() => setCommand('none')}
          >
            <span className='indicator-label'>None</span>
          </button>

          <button
            className={`btn mb-3 ${command == 'condition2' ? 'btn-primary' : 'btn-outline btn-outline-primary'
              } `}
            onClick={() => setCommand('condition2')}
          >
            <span className='indicator-label'>Send with no condition.</span>
          </button>
          <button
            className={`btn mb-3 ${command == 'condition3' ? 'btn-primary' : 'btn-outline btn-outline-primary'
              } `}
            onClick={() => setCommand('condition3')}
          >
            <span className='indicator-label'>Send data if all value completed</span>
          </button>
        </div>
      </div>
    </>
  )
}

export default EditFormFormat2
