import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import firebase from '../../../firebase/FirebaseConfig';
import EditFormFormat1 from './EditFormFormat1';
import EditFormFormat2 from './EditFormFormat2';
import { useNavigate } from 'react-router-dom';
import { KTIcon } from '../../../../_metronic/helpers';
import { PulseLoader } from 'react-spinners';
import Swal from 'sweetalert2';

function EditCodeWrapper() {
    const { codeId } = useParams();
    const db = firebase.firestore();
    const navigate = useNavigate();

    const [codeData, setCodeData] = useState({});
    const [editData, setEditData] = useState({});
    const [tagData, setTagData] = useState({});
    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);
    const [dueDate, setDueDate] = useState({ date: null })

    const handleNavigate = (path) => {
        navigate(path)
    }

    useEffect(() => {
        async function fetchTagData(tagId) {
            await db.collection('tag')
                .doc(tagId)
                .get()
                .then((doc) => {
                    setTagData(doc.data())
                })
        }
        async function fetchCodeData() {
            setPageLoading(true)
            await db.collection('code')
                .doc(codeId)
                .get()
                .then(async (doc) => {
                    setCodeData(doc.data())
                    await fetchTagData(doc.data().tagId)
                    setPageLoading(false)
                })
        }
        fetchCodeData()
    }, [])

    const handleFormChange = (data) => {
        setEditData(data)
    }
    const handleDateChange = (date) => {
        setEditData((prevData) => ({
            ...prevData,
            dueDate: date
        }));
    };

    const handleSubmit = async () => {
        try {
            setLoading(true)
            await db
                .collection("code")
                .doc(codeId)
                .update(editData)
                .then(() => {
                    setLoading(false);
                    Swal.fire({
                        title: "Code is updated.",
                        icon: 'success'
                    }).then(() => {
                        handleNavigate('/code')
                    })
                })
        } catch (error) {
            console.error("Cannot update code", error);
            setLoading(false);
            Swal.fire({
                title: "Code update failed.",
                icon: 'error'
            })
        }

    }

    return (
        <>
            <div className='d-flex justify-content-between align-items-center mb-6'>
                <div className='d-flex align-items-center' onClick={() => handleNavigate('/code')}>
                    <KTIcon iconName={'left-square'} className='fs-3x me-3' />
                    <h3 className='fw-bolder my-2 ms-2'>Edit Code</h3>
                </div>
                {/* <ol className='breadcrumb text-muted fs-6 fw-bold'>
                    <li className='breadcrumb-item' onClick={() => handleNavigate('/code')}>
                        <span style={{ cursor: 'pointer' }}>Code</span>
                    </li>
                    <li className='breadcrumb-item text-muted'>Edit Code</li>
                </ol> */}
            </div>

            {!pageLoading ? (
                <>
                    <div className='card card-custom'>
                        <div className='card-body'>
                            {codeData.dataFormat == "format1" && (
                                <EditFormFormat1
                                    codeData={codeData}
                                    tagData={tagData}
                                    handleFormChange={handleFormChange} />
                            )}
                            {codeData.dataFormat == "format2" && (
                                <EditFormFormat2
                                    codeData={codeData}
                                    tagData={tagData}
                                    handleFormChange={handleFormChange}
                                    handleDateChange={handleDateChange}
                                />
                            )}
                            <div className='card-footer d-flex justify-content-center gap-4'>
                                <button className='btn btn-primary' onClick={handleSubmit}>
                                    {!loading ? ("Submit") : (<PulseLoader color='#fff' size={8} margin={2} />)}
                                </button>
                                <button className='btn btn-secondary' onClick={() => handleNavigate('/code')}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <div className='text-center'>
                    <div className='spinner-border'></div>
                </div>
            )}
        </>
    )
}

export default EditCodeWrapper
