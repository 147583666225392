/* eslint-disable jsx-a11y/anchor-is-valid */

import {useUser} from '../../modules/newauth/UserContext'
import {useState, useEffect} from 'react'
import firebase from '../../firebase/FirebaseConfig'
import {useMember} from '../../modules/newauth/MemberContext'
import {useNavigate} from 'react-router-dom'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PulseLoader} from 'react-spinners'

const MemberProfileEditPage = () => {
  const {user} = useUser()
  const {member, getMember} = useMember()
  const [memberObject, setMemberObject] = useState({})
  const db = firebase.firestore()
  const navigate = useNavigate()

  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [passwordEnabled, setPasswordEnabled] = useState(false)
  const [password, setPassword] = useState('')
  const [iconType, setIconType] = useState('')
  const [selectedAvatar, setSelectedAvatar] = useState('')
  const [imageFile, setImageFile] = useState(null)

  useEffect(() => {
    if (member === null || member === 'null') {
      navigate(`/select-member`)
    }
  }, [member])

  useEffect(() => {
    async function fetchMember() {
      const memberData = await getMember()
      setMemberObject(memberData)
      console.log(memberData)
      setName(memberData.name)
      setDescription(memberData.description)
      setPasswordEnabled(memberData.isPasswordEnabled)
      setPassword(memberData.password)
      setIconType(memberData.imageProfileType)
      setSelectedAvatar(memberData.imageAvatar)
    }
    fetchMember()
  }, [])

  // console.log(member)
  // console.log(memberObject)

  const avatars = [
    '300-1.jpg',
    '300-2.jpg',
    '300-3.jpg',
    '300-4.jpg',
    '300-5.jpg',
    '300-6.jpg',
    '300-7.jpg',
    '300-8.jpg',
    '300-9.jpg',
    '300-10.jpg',
    '300-11.jpg',
  ]

  return (
    <>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2'>Edit profile</h3>
      </div>

      <div className='card card-custom'>
        <div className='card-header'>
          <h3 className='card-title'>Edit profile</h3>
        </div>
        <div className='card-body'>
          <div className='mb-10'>
            <label htmlFor='name' className='form-label'>
              Name
            </label>
            <input
              id='name'
              type='text'
              className='form-control'
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder='Name'
            />
          </div>

          <div className='mb-10'>
            <label htmlFor='description' className='form-label'>
              Description
            </label>
            <input
              id='description'
              type='text'
              className='form-control'
              placeholder='Description'
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>

          {/* <div className='mb-10'>
            <label htmlFor='role' className='form-label'>
              Roles
            </label>
            <select
              id='role'
              className='form-select'
              {...formik.getFieldProps('role')}
              aria-label='Select role'
            >
              <option value='member'>Member</option>
              <option value='admin'>Admin</option>
            </select>
          </div> */}

          <div className='form-check form-switch form-check-custom form-check-solid mb-5'>
            <input
              className='form-check-input'
              type='checkbox'
              id='passwordEnableSwitch'
              checked={passwordEnabled}
              onChange={(e) => setPasswordEnabled(e.target.checked)}
            />
            <label className='form-check-label' htmlFor='passwordEnableSwitch'>
              Enable password
            </label>
          </div>

          {passwordEnabled && (
            <div className='mb-10'>
              <label htmlFor='password' className='form-label'>
                Password
              </label>
              <input
                id='password'
                type='text'
                className='form-control'
                placeholder='Password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          )}

          <div className='mb-10'>
            <label htmlFor='icon' className='form-label'>
              Icon
            </label>
            <div className='mb-2'>
              <div className='form-check form-check-custom form-check-solid'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='icon'
                  value='avatar'
                  id='iconAvatar'
                  checked={iconType === 'avatar'}
                  onChange={() => setIconType('avatar')}
                />
                <label className='form-check-label text-gray-800' htmlFor='iconAvatar'>
                  Avatar
                </label>
              </div>
            </div>
            <div className='mb-0'>
              <div className='form-check form-check-custom form-check-solid'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='icon'
                  value='upload'
                  id='iconUpload'
                  checked={iconType === 'upload'}
                  onChange={() => setIconType('upload')}
                />
                <label className='form-check-label text-gray-800' htmlFor='iconUpload'>
                  Upload
                </label>
              </div>
            </div>
          </div>

          {iconType === 'avatar' ? (
            <div className='mb-2'>
              <label htmlFor='icon' className='form-label'>
                Avatar
              </label>
              <div className='d-flex justify-content-center flex-wrap'>
                {avatars.map((avatar, index) => (
                  <div key={index} className='p-2'>
                    <input
                      type='radio'
                      name='avatar'
                      id={`avatar${index}`}
                      value={avatar}
                      onChange={() => setSelectedAvatar(avatar)}
                      checked={selectedAvatar === avatar}
                      className='d-none'
                    />
                    <label
                      htmlFor={`avatar${index}`}
                      className={`symbol symbol-55px symbol-circle ${
                        selectedAvatar === avatar ? 'selected-avatar' : ''
                      }`}
                      style={{
                        boxShadow:
                          selectedAvatar === avatar ? '0 0 0 5px rgba(50,150,250,0.8)' : 'none',
                        cursor: 'pointer',
                        transition: 'box-shadow 0.3s ease',
                      }}
                    >
                      <img
                        alt={`Avatar ${index}`}
                        src={toAbsoluteUrl(`/media/avatars/${avatar}`)}
                      />
                    </label>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className='mb-10'>
              <label htmlFor='imageFile' className='form-label'>
                Upload Image
              </label>
              <input
                id='imageFile'
                type='file'
                accept='image/*'
                className='form-control'
                onChange={(e) => setImageFile(e.target.files[0])}
              />
            </div>
          )}
        </div>
        <div
          className='card-footer'
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <button type='submit' className='btn btn-md btn-primary'>
            {/* {loading ? (
              <PulseLoader color='#fff' size={8} margin={2} />
            ) : ( */}
            <span className='indicator-label'>Update profile</span>
            {/* )} */}
          </button>

          <button
            type='button'
            className='btn btn-md btn-light'
            onClick={() => navigate('/member-profile')}
            style={{marginLeft: '10px'}}
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  )
}

const MemberProfileEditWrapper = () => {
  return (
    <>
      <MemberProfileEditPage />
    </>
  )
}

export {MemberProfileEditWrapper}

function capitalizeFirstLetter(string) {
  const str = String(string)

  if (str.trim().length === 0) {
    return ''
  }

  return str.charAt(0).toUpperCase() + str.slice(1)
}
