import { useUser } from '../../../modules/newauth/UserContext'
import { useState, useEffect } from 'react'
import firebase from '../../../firebase/FirebaseConfig'

async function AdminVerify(data, userData, memberId) {
    const db = firebase.firestore();

    await db
        .collection('adminVerify').add({
            to: userData.email,
            message: {
                subject: "Verify mScanPro admin creation",
                html: `
                <body style="font-family: Arial, sans-serif; background-color: #f2f2f2; padding: 20px;">
                    <table align="center" border="0" cellpadding="0" cellspacing="0" width="100%">
                        <tr>
                            <td align="center" bgcolor="#ffffff" style="padding: 40px; border-radius: 10px; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);">
                                <img src="https://firebasestorage.googleapis.com/v0/b/malin1-app-mscanpro.appspot.com/o/emailVerifyIcon.png?alt=media&token=c069e822-022e-4e30-a68f-2c371b4c458b" alt="email-icon" height="60" width="60" style="margin-bottom: 20px;">
                                <h2 style="margin-bottom: 10px;">Verify mScanPro Admin Creation</h2>
                                <p style="font-size: 16px;">Please click the button below to verify admin creation for <strong>${data.name}</strong>.</p>
                                <table border="0" cellpadding="0" cellspacing="0">
                                    <tr>
                                        <td bgcolor="#3d4860" align="center" style="border-radius: 5px;">
                                            <a href="https://malin1-app-mscanpro.web.app/admin-verify/${memberId}/${userData.userId}" style="font-size: 16px; font-weight: bold; color: #ffffff; text-decoration: none; display: inline-block; padding: 15px 30px;">Verify</a>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                </body>
                `,
            },
        })
        .then(() => console.log("Queued email for delivery!"));
}

export default AdminVerify
