import React, { useEffect } from 'react'
import formatDate from '../../../modules/utils/FormatDate';

function CodePreviewFormat2(props) {

    const { scanData, tagData, formatName } = props

    const header = tagData?.header;

    return (
        <>
            <div className='col-12'>
                <label className='form-label fw-bold text-gray-600'>Data format : </label>
                <p className='fw-bold fs-3'>{formatName || scanData.dataFormat}</p>
            </div>

            <div className='mb-5 row'>
                <div className='col-6'>
                    <label className='form-label fw-bold text-gray-600'>{header?.text1 || "Text 1"} : </label>
                    <p className='fw-bold fs-3'>{scanData.text1}</p>
                </div>
                <div className='col-6'>
                    <label className='form-label fw-bold text-gray-600'>{header?.text2 || "Text 2"} : </label>
                    <p className='fw-bold fs-3'>{scanData.text2}</p>
                </div>
                <div className='col-6'>
                    <label className='form-label fw-bold text-gray-600'>{header?.text3 || "Text 3"} : </label>
                    <p className='fw-bold fs-3'>{scanData.text3}</p>
                </div>
            </div>

            <div className='mb-5 row'>
                <div className='col-6'>
                    <label className='form-label fw-bold text-gray-600'>{header?.dueDate || "Due Date"} : </label>
                    <p className='fw-bold fs-3'>{formatDate(scanData.dueDate.date, true)}</p>
                </div>
                <div className='col-6'>
                    <label className='form-label fw-bold text-gray-600'>{header?.flow || "Flow"} : </label>
                    <p className='fw-bold fs-3'>{scanData.flow}</p>
                </div>
            </div>

            <div className='mb-5 row'>
                <div className='col-6'>
                    <label className='form-label fw-bold text-gray-600'>{header?.num1 || "Number 1"} : </label>
                    <p className='fw-bold fs-3'>{scanData.num1}</p>
                </div>
                <div className='col-6'>
                    <label className='form-label fw-bold text-gray-600'>{header?.num2 || "Number 2"} : </label>
                    <p className='fw-bold fs-3'>{scanData.num2}</p>
                </div>
                <div className='col-6'>
                    <label className='form-label fw-bold text-gray-600'>{header?.num3 || "Number 3"} : </label>
                    <p className='fw-bold fs-3'>{scanData.num3}</p>
                </div>
            </div>

            <div className='mb-5 row'>
                <div className='col-6'>
                    <label className='form-label fw-bold text-gray-600'>Command : </label>
                    <p className='fw-bold fs-3'>{scanData.command}</p>
                </div>
                <div className='col-6'>
                    <label className='form-label fw-bold text-gray-600'>App type : </label>
                    <p className='fw-bold fs-3'>{scanData.appType}</p>
                </div>
            </div>

            <div className='mb-5 row'>
                <div className='col-6'>
                    <label className='form-label fw-bold text-gray-600'>Code id : </label>
                    <p className='fw-bold fs-3'>{scanData.codeId}</p>
                </div>
                <div className='col-6'>
                    <label className='form-label fw-bold text-gray-600'>Tag id : </label>
                    <p className='fw-bold fs-3'>{scanData.tagId}</p>
                </div>
            </div>

            <div className='mb-5 row'>
                <div className='col-6'>
                    <label className='form-label fw-bold text-gray-600'>Device id : </label>
                    <p className='fw-bold fs-3'>{scanData.deviceId}</p>
                </div>
                <div className='col-6'>
                    <label className='form-label fw-bold text-gray-600'>Owner : </label>
                    <p className='fw-bold fs-3'>{scanData.owner}</p>
                </div>
            </div>
        </>
    )
}

export default CodePreviewFormat2
