export default function formatDate(timestamp, dateOnly) {
    const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ]
    if (!timestamp) {
        return "-"
    }
    const date = new Date(timestamp * 1000)

    const day = date.getDate()
    const month = months[date.getMonth()]
    const year = date.getFullYear()
    const hours = date.getHours()
    const minutes = date.getMinutes()

    if (dateOnly) {
        const formattedDate = `${day}-${month}-${year}`
        return formattedDate
    }
    const formattedDate = `${day}-${month}-${year} ${hours.toString().padStart(2, '0')}.${minutes
        .toString()
        .padStart(2, '0')}`
    return formattedDate
}